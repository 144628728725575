import React, { useEffect } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader, MediaQuery, Modal } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import { getHardwareStatusUpdateQueryStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import {
  getOfferList,
  getOfferListQueryStatus,
  getProductBatteryList,
} from '+app/+lead/+overview/store/+overview.selectors';
import { PATHS, ROUTES } from '+app/router';
import { TestId } from '+config/testIds';
import { HardwarePageActions } from '+lead/+hardware/store';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { getChangeLeadStatusQueryStatus } from '+lead/store/+lead.selectors';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { SelectButton } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import ModalLayout from '+shared/components/ModalLayout/ModalLayout';
import { Lead } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { getUserCompanyName } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';

import { LeadEditStatusHardware } from './LeadEditStatusHardware/LeadEditStatusHardware.component';
import {
  canMoveLeadToSetup,
  findProductsStatusesToUpdate,
  getLeadStatusListToUpdate,
  LeadEditStatusForm,
  leadEditStatusSchema,
  mapUserStatusToInitialValues,
} from './LeadEditStatusModal.helper';
import { LeadEditStatusSonnenFlat } from './LeadEditStatusSonnenFlat';

import './LeadEditStatusModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userCompanyName: getUserCompanyName(state),
  offers: getOfferList(state),
  productBatteryList: getProductBatteryList(state),
  changeLeadQueryStatus: getChangeLeadStatusQueryStatus(state),
  offerListQueryStatus: getOfferListQueryStatus(state),
  hardwareProductStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goToCreateHardwareOffer: (leadId: string) => push(PATHS.LEAD_HARDWARE_NEW({ leadId })),
  getOfferList: LeadOverviewPageActions.getOfferList,
  clearOffers: LeadOverviewPageActions.clearOffers,
  changeLeadStatus: LeadPageActions.changeLeadStatus,
  changeHardwareProductStatus: HardwarePageActions.updateHardwareStatus,
  displayMaxOffersWarning: LeadOverviewPageActions.displayMaxOffersWarning,
});

interface ComponentProps {
  isModalOpen: boolean;
  lead: Lead;
  toggleEditModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps &
  RouteComponentProps;

enum LeadStatuesGroup {
  HARDWARE = 'hardware',
  SONNEN_FLAT = 'sonnen-flat',
}

export const checkboxTextGenerator = (
  label: string,
  owner: string,
  bold: boolean = false,
  dataTestId?: string
) => (
  <div>
    <p className={'c-lead-edit-status-modal__status-name'}>{label}</p>
    <p
      data-hj-suppress={true}
      className={classNames('c-lead-edit-status-modal__status-owner', {
        'c-lead-edit-status-modal__status-owner--bold': bold,
      })}
      data-testid={dataTestId}
    >
      {owner}
    </p>
  </div>
);

const LeadEditStatusModalComponent: React.FC<Props> = ({
  actions,
  isModalOpen,
  lead,
  changeLeadQueryStatus,
  userCompanyName,
  offers,
  location,
  productBatteryList,
  offerListQueryStatus,
  hardwareProductStatusUpdateQueryStatus,
  toggleEditModal,
  toggleModal,
}) => {
  const [selectedStatusesGroup, setSelectedStatusesGroup] = React.useState<LeadStatuesGroup>(
    LeadStatuesGroup.HARDWARE
  );

  useEffect(() => {
    return () => {
      closeLeadEditStatusModal();
    };
  }, []);

  useEffect(() => {
    if (isModalOpen && isEmpty(offers) && !offerListQueryStatus.success) {
      actions.getOfferList(lead.id);
    }
  }, [isModalOpen]);

  const closeLeadEditStatusModal = () => {
    toggleModal(false);
    toggleEditModal(false);

    // on the lead list offers have to be cleared each time the modal is closed
    // to fetch new ones while opening it for a different lead
    if (location.pathname === ROUTES.LEADS[0]) {
      actions.clearOffers();
    }
  };

  const initialValues = mapUserStatusToInitialValues(lead, offers);

  const hasErrors = hardwareProductStatusUpdateQueryStatus.error || changeLeadQueryStatus.error;
  const isPending =
    hardwareProductStatusUpdateQueryStatus.pending ||
    hardwareProductStatusUpdateQueryStatus.pending;

  const isMoveToSetupEnabled = (
    statusSummary: Lead['status']['summary'],
    values: LeadEditStatusForm
  ): boolean =>
    canMoveLeadToSetup({ statusSummary, values, offers, initialValues }) &&
    !changeLeadQueryStatus.pending;

  const onSubmit = (values: LeadEditStatusForm) => {
    if (!lead) return;

    const productsStatusesToUpdate = findProductsStatusesToUpdate(offers)(initialValues, values);
    if (!isEmpty(productsStatusesToUpdate))
      actions.changeHardwareProductStatus(lead.id, productsStatusesToUpdate);

    const leadStatusList = getLeadStatusListToUpdate({
      initialValues,
      values,
      productsStatusesToUpdate,
    });
    if (!isEmpty(leadStatusList)) actions.changeLeadStatus(lead.id, leadStatusList);
  };

  const moveToSetup = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    form: FormikProps<LeadEditStatusForm>
  ) => {
    onSubmit({
      ...form.values,
      inSetup: true,
    });
  };

  const renderStatusModalFooter = (form: FormikProps<LeadEditStatusForm>) => (
    <>
      {hasErrors && !isPending && (
        <div className={'c-lead-edit-status-modal__errors'}>
          <div>{I18n.t(T.lead.list._salessolution_.editStatusModal.errors.errorWhileSaving)}</div>
          <div>{I18n.t(T.lead.list._salessolution_.editStatusModal.errors.refreshPage)}</div>
        </div>
      )}
      <Media query={MediaQuery.UP_MD}>
        {(isMedium: boolean) => (
          <div className={'c-lead-edit-status-modal__buttons'}>
            <Button
              label={I18n.t(T.general.basicActions.save)}
              type={ButtonType.SECONDARY}
              size={isMedium ? ButtonSize.MEDIUM : ButtonSize.SMALL}
              mainType={MainType.SUBMIT}
              disabled={changeLeadQueryStatus.pending || !form.dirty}
              status={
                changeLeadQueryStatus.pending || !form.dirty
                  ? ButtonStatus.DISABLED
                  : ButtonStatus.ENABLED
              }
              className={'c-lead-edit-status-modal__button-setup'}
              dataTestId={TestId.Lead.StatusManager.SaveButton}
            />
            {!initialValues.inSetup && (
              <Button
                label={I18n.t(T.lead.list._salessolution_.editStatusModal.moveToSetupBtn)}
                size={isMedium ? ButtonSize.MEDIUM : ButtonSize.SMALL}
                disabled={!isMoveToSetupEnabled(lead.status.summary, form.values)}
                status={
                  !isMoveToSetupEnabled(lead.status.summary, form.values)
                    ? ButtonStatus.DISABLED
                    : ButtonStatus.ENABLED
                }
                onClick={(event) => moveToSetup(event, form)}
                dataTestId={TestId.Lead.StatusManager.MoveToSetupButton}
              />
            )}
          </div>
        )}
      </Media>
    </>
  );

  return (
    <Modal
      dataTestId="update-and-move-to-setup-modal"
      isOpen={isModalOpen}
      onClose={closeLeadEditStatusModal}
      className={'c-lead-edit-status-modal__wrapper'}
    >
      {changeLeadQueryStatus.pending ||
      hardwareProductStatusUpdateQueryStatus.pending ||
      offerListQueryStatus.pending ||
      (!offerListQueryStatus.success && !offerListQueryStatus.error) ? (
        <Loader className={'c-lead-edit-status-modal__loader'} />
      ) : (
        <div className={'c-lead-edit-status-modal'} data-hj-suppress={true}>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={leadEditStatusSchema}
            onSubmit={onSubmit}
            render={(form) => (
              <Form className={'c-lead-edit-status-modal-form'}>
                <ModalLayout
                  title={`${lead.firstName && lead.firstName} ${lead.lastName && lead.lastName}`}
                  subtitle={I18n.t(T.lead.list._salessolution_.editStatusModal.description)}
                  footer={renderStatusModalFooter(form)}
                  dataTestIdTitle="update-and-move-to-setup-title"
                  section={Sections.LEADS}
                  page={PageName.Leads.LEAD_STATUS_MANAGER_MODAL}
                >
                  <div className={'c-lead-edit-status-modal__input'}>
                    <div className={'c-lead-edit-status-modal__widget-wrapper'}>
                      <div className={'c-lead-edit-status-modal__statuses-container'}>
                        <SelectButton
                          className={'c-lead-edit-status-modal__statuses-item'}
                          onClick={() => setSelectedStatusesGroup(LeadStatuesGroup.HARDWARE)}
                          isActive={selectedStatusesGroup === LeadStatuesGroup.HARDWARE}
                          icon={<Icon.SonnenBattery />}
                          label={I18n.t(
                            T.lead.list._salessolution_.editStatusModal.switch.hardware
                          )}
                          dataTestId="hardware-tab-button"
                        />
                        <SelectButton
                          className={'c-lead-edit-status-modal__statuses-item'}
                          onClick={() => setSelectedStatusesGroup(LeadStatuesGroup.SONNEN_FLAT)}
                          isActive={selectedStatusesGroup === LeadStatuesGroup.SONNEN_FLAT}
                          icon={<Icon.NotesWithPencil />}
                          label={I18n.t(
                            T.lead.list._salessolution_.editStatusModal.switch.sonnenFlat
                          )}
                          dataTestId="sonnenflat-tab-button"
                        />
                      </div>
                    </div>

                    {selectedStatusesGroup === LeadStatuesGroup.HARDWARE && (
                      <LeadEditStatusHardware
                        form={form}
                        userCompanyName={userCompanyName}
                        isMoveToSetupEnabled={isMoveToSetupEnabled}
                        hasErrors={changeLeadQueryStatus.error}
                        leadStatusSummary={lead.status.summary}
                        offers={offers}
                        productBatteryList={productBatteryList}
                        goToCreateHardwareOffer={() => actions.goToCreateHardwareOffer(lead.id)}
                        displayMaxOffersWarning={() => actions.displayMaxOffersWarning(lead.id)}
                      />
                    )}

                    {selectedStatusesGroup === LeadStatuesGroup.SONNEN_FLAT && (
                      <LeadEditStatusSonnenFlat
                        leadStatusSummary={lead.status.summary}
                        userCompanyName={userCompanyName}
                      />
                    )}
                  </div>
                </ModalLayout>
              </Form>
            )}
          />
        </div>
      )}
    </Modal>
  );
};

export const LeadEditStatusModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadEditStatusModalComponent));
