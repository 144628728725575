import { LeadAddress, LeadConfigurationResponse, LeadPatchDto } from '+shared/store/lead/types';
import { LeadOffer } from '+shared/store/lead/types/leadOffer.interface';
import { LeadProductAvailability } from '+shared/store/lead/types/leadProductAvailability.interface';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { Query } from '+shared/store/query';

export const GET_OFFER_LIST_QUERY = 'getOfferListQuery';
export const DELETE_OFFER_QUERY = 'deleteConfigurationQuery';
export const MARK_AS_SEEN_QUERY = 'markAsSeenQuery';
export const POST_PARTNER_NOTE_QUERY = 'postPartnerNoteQuery';
export const GET_PARTNER_NOTE_QUERY = 'getPartnerNoteQuery';
export const GET_PARTNER_EMPLOYEE_COLLECTION_QUERY = 'getPartnerEmployeeCollectionQuery';
export const RECALCULATE_CONFIGURATION_QUERY = 'recalculateConfigurationQuery';
export const CLOSE_LEAD_QUERY = 'closeLeadQuery';
export const SEND_CG_INVITATION_QUERY = 'sendCGInvitationQuery';
export const GET_LEAD_PRODUCT_AVAILABILITY_QUERY = 'getLeadProductAvailabilityQuery';
export const GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY = 'getProductAvailabilityForAddressQuery';
export const GET_PRODUCT_BATTERY_LIST_QUERY = 'getProductBatteryListQuery';
export const GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY = 'getRecalculatedOfExpiredOfferQuery';
export const DELETE_EXPIRED_OFFER = 'deleteExpiredOffer';

export interface LeadOverviewPageState {
  offers: LeadOffer[];
  updatingOffer: LeadOffer | null;
  lastUpdatedLeadSection: Partial<LeadPatchDto>;
  isOpeningDocument: boolean;
  documentUrl?: string;
  partnerNotes: string | null;
  failedDocumentModal: boolean;
  failedDocument:
    | {
        resourceId: string;
        resourceType: string;
        documentType: string;
      }
    | undefined;
  newLeadAddress?: LeadAddress;
  isNewAddressErrorActive: boolean;
  productAvailability: LeadProductAvailability[];
  productAvailabilityForAddress: LeadProductAvailability[];
  productBatteryList: LeadProductBattery[];
  offerIdToChangeStatus?: string;
  displayMaxOffersWarning: boolean;
  [GET_OFFER_LIST_QUERY]: Query<any>;
  [DELETE_OFFER_QUERY]: Query<any>;
  [MARK_AS_SEEN_QUERY]: Query<any>;
  [POST_PARTNER_NOTE_QUERY]: Query<any>;
  [GET_PARTNER_NOTE_QUERY]: Query<any>;
  [RECALCULATE_CONFIGURATION_QUERY]: Query<any>;
  [CLOSE_LEAD_QUERY]: Query<any>;
  [SEND_CG_INVITATION_QUERY]: Query<any>;
  [GET_LEAD_PRODUCT_AVAILABILITY_QUERY]: Query<any>;
  [GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY]: Query<any>;
  [GET_PRODUCT_BATTERY_LIST_QUERY]: Query<any>;
  [GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY]: Query<LeadConfigurationResponse>;
  [DELETE_EXPIRED_OFFER]: Query<LeadConfigurationResponse>;
}

export const initialState: LeadOverviewPageState = {
  offers: [],
  updatingOffer: null,
  lastUpdatedLeadSection: {},
  isOpeningDocument: false,
  documentUrl: undefined,
  failedDocumentModal: false,
  failedDocument: undefined,
  partnerNotes: null,
  newLeadAddress: undefined,
  isNewAddressErrorActive: false,
  productAvailability: [],
  productAvailabilityForAddress: [],
  productBatteryList: [],
  offerIdToChangeStatus: undefined,
  displayMaxOffersWarning: false,
  [GET_OFFER_LIST_QUERY]: {},
  [DELETE_OFFER_QUERY]: {},
  [MARK_AS_SEEN_QUERY]: {},
  [POST_PARTNER_NOTE_QUERY]: {},
  [GET_PARTNER_NOTE_QUERY]: {},
  [RECALCULATE_CONFIGURATION_QUERY]: {},
  [CLOSE_LEAD_QUERY]: {},
  [SEND_CG_INVITATION_QUERY]: {},
  [GET_LEAD_PRODUCT_AVAILABILITY_QUERY]: {},
  [GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY]: {},
  [GET_PRODUCT_BATTERY_LIST_QUERY]: {},
  [GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY]: {},
  [DELETE_EXPIRED_OFFER]: {},
};
