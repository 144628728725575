import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { TestId } from '+config/testIds';
import { TooltipHoverable } from '+shared/components';
import { Button, ButtonStatus, ButtonType } from '+shared/components/Button';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { LeadProductType } from '+shared/store/lead/types';

import './LeadOverviewNewConfigurationPane.component.scss';

interface Props {
  productType: LeadProductType;
  onNewConfiguration: () => void;
  isDisabled?: boolean;
  onHoverText?: string;
}

export const LeadOverviewNewConfigurationPane: React.FC<Props> = ({
  isDisabled,
  onNewConfiguration,
  productType,
  onHoverText,
}) => {
  const onButtonClick = () => {
    if (!isDisabled) {
      onNewConfiguration();
    }
  };

  const renderProductHeadline = (productType: LeadProductType): string => {
    switch (productType) {
      case LeadProductType.FLAT_X:
        return I18n.t(T.lead.configuration._salessolution_.newConfiguration.products.sonnenFlat);
      case LeadProductType.FLAT_DIRECT:
        return I18n.t(
          T.lead.configuration._salessolution_.newConfiguration.products.sonnenFlatDirect
        );
      default:
        return I18n.t(T.lead.configuration._salessolution_.newConfiguration.products.sonnenFlat);
    }
  };

  const renderProductInfo = (productType: LeadProductType) => {
    const translateI18nCodes: (i18nCodes: { title: string; bulletPoints: string }) => {
      headline: string;
      bulletPoints: string[];
    } = (i18nCodes) => {
      const headline: string = I18n.t(i18nCodes.title);
      const bulletPoints: string[] = I18n.t(i18nCodes.bulletPoints).split('\n');
      return { headline, bulletPoints };
    };
    const getPaneData = () => {
      switch (productType) {
        case LeadProductType.FLAT_X:
          return translateI18nCodes(
            T.lead.configuration._salessolution_.newConfiguration.bulletPoints.sonnenFlat
          );
        case LeadProductType.FLAT_DIRECT:
          return translateI18nCodes(
            T.lead.configuration._salessolution_.newConfiguration.bulletPoints.sonnenFlatDirect
          );
        default:
          return translateI18nCodes(
            T.lead.configuration._salessolution_.newConfiguration.bulletPoints.sonnenFlat
          );
      }
    };
    const paneData = getPaneData();

    return (
      <div className={'c-overview-new-configuration-pane__bullet-points'}>
        <p dangerouslySetInnerHTML={{ __html: paneData.headline }} />
        {paneData.bulletPoints.map((bulletPoint, index) => (
          <div key={index} className={'c-overview-new-configuration-pane__bullet-point'}>
            <div className={'c-overview-new-configuration-pane__bullet-point-circle'} />
            <p>{bulletPoint}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames('c-overview-new-configuration-pane', {
        'c-overview-new-configuration-pane--disabled': isDisabled,
        'c-overview-new-configuration-pane-light': productType === LeadProductType.FLAT_X,
        'c-overview-new-configuration-pane-dark': productType === LeadProductType.FLAT_DIRECT,
      })}
    >
      <div className={'c-overview-new-configuration-pane__body'}>
        <div className={'c-overview-new-configuration-pane__header'}>
          <Icon.Contract className={'c-overview-new-configuration-pane__header-icon'} />
          <p className={'c-overview-new-configuration-pane__headline'}>
            {renderProductHeadline(productType)}
          </p>
          {productType === LeadProductType.FLAT_DIRECT && (
            <StatusTile
              label={I18n.t(
                T.lead.configuration._salessolution_.newConfiguration.products.sonnenFlatDirectLabel
              )}
              color={StatusTileColors.BLUE}
            />
          )}
        </div>

        <div className={'c-overview-new-configuration-pane__info-wrapper'}>
          {renderProductInfo(productType)}
        </div>
      </div>

      <div className={'c-overview-new-configuration-pane__create-button'}>
        <TooltipHoverable
          title={onHoverText}
          isHoveringDisabled={!onHoverText}
          className={'c-overview-new-configuration-pane__create-button-tooltip'}
        >
          <Button
            type={ButtonType.PRIMARY}
            label={
              <>
                <Icon.Plus
                  className={classNames('c-lead-list-header__button-icon', {
                    'c-lead-list-header__button-icon--is-disabled': isDisabled,
                  })}
                />
                {I18n.t(T.lead.configuration._salessolution_.startNewConfiguration)}
              </>
            }
            disabled={isDisabled}
            status={isDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
            dataTestId={TestId.Lead.ConfigurationOverview.StartNewConfigurationButton}
            onClick={onButtonClick}
          />
        </TooltipHoverable>
      </div>
    </div>
  );
};
