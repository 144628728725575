import * as React from 'react';

export type ActionLinks = {
  partnerAppLink: string;
  marketingLink: string;
  generalDocumentsLink: string;
  technicalDocumentsLink: string;
  sonnenDriveLink: string;
  eAcademyLink: string;
  releaseNotesLink: string;
};

interface NavSonnenWorldContextProps {
  actionLinkMap: Partial<ActionLinks>;
}

export const NavSonnenWorldContext = React.createContext<NavSonnenWorldContextProps>({
  actionLinkMap: {
    partnerAppLink: undefined,
    marketingLink: undefined,
    generalDocumentsLink: undefined,
    technicalDocumentsLink: undefined,
    sonnenDriveLink: undefined,
    eAcademyLink: undefined,
    releaseNotesLink: undefined,
  },
});

export const useNavSonnenWorldContext = () => React.useContext(NavSonnenWorldContext);
