import * as React from 'react';

import { DefaultParagraph } from '@sonnen/shared-web';

import './SonnenDriveBenefitsSlide.component.scss';

interface Props {
  icon: React.ReactNode;
  iconName: string;
  name: string;
  text: string;
}

export const SonnenDriveBenefitsSlide: React.FC<Props> = ({ icon, iconName, name, text }) => (
  <div className={'c-sonnen-drive-benefits-slide'}>
    <div className={'c-sonnen-drive-benefits-slide__inner'}>
      <div
        className={`
            c-sonnen-drive-benefits-slide__icon
            c-sonnen-drive-benefits-slide__icon--${iconName}
          `}
      >
        {icon}
      </div>
      <DefaultParagraph className="c-sonnen-drive-benefits-slide__text">{name}</DefaultParagraph>
    </div>
    <div className={'c-sonnen-drive-benefits-slide__description'}>
      <p className={'c-sonnen-drive-benefits-slide__description-name'}>{name}</p>
      <DefaultParagraph className={'c-sonnen-drive-benefits-slide__description-text'}>
        {text}
      </DefaultParagraph>
    </div>
  </div>
);
