import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { values } from 'lodash';
import { createSelector } from 'reselect';

import { getLeadData, getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { LEAD_IN_SETUP_STAGE, PATHS, SETUP_TAB_ROUTE_NAME } from '+app/router';
import { getRouterLocationPathFirstSegment } from '+app/router/store/router.selectors';
import { CountryFeatureName } from '+config/countryFlags';
import { TestId } from '+config/testIds';
import { mapUserStatusToInitialValues } from '+lead/components/SetupLeadEditStatusModal/SetupLeadEditStatusModal.helper';
import {
  getPreCommissioningFields,
  getPreCommissioningStatus,
} from '+setupTool/+preCommissioning/store/+preCommissioning.selectors';
import { PreCommissioningStatus } from '+setupTool/+preCommissioning/store/types';
import { PvRegisterState } from '+setupTool/+pvRegister/store/types';
import { PrecommissioningDashboardInfobox, PreCommissioningDocument } from '+setupTool/components';
import { getDSORegCommStatus } from '+setupTool/store/+setupTool.helpers';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { getConfiguration } from '+shared/store/setupTool';
import { dateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';
import { isStatusSet } from '+utils/status.util';

import {
  getDsoCommissioningDocuments,
  getDsoCommissioningGenerateDocumentsQueryStatus,
  getLatestDsoCommissioningDate,
  getLatestDsoCommissioningDocumentsGenerationDate,
} from '../+dsoCommissioning/store/+dsoCommissioning.selectors';
import {
  getDsoGenerateDocumentsQueryStatus,
  getDsoRegistrationDocuments,
  getLatestDocumentsGenerationDate,
} from '../+dsoRegistration/store/+dsoRegistration.selectors';
import { DsoSummaryFiles } from '../+form/components';
import { getFormFulfillment, getLatestModificationDate } from '../+form/store/+form.selectors';
import { PvRegisterStatus } from '../+pvRegister/store/+pvRegister.dictionary';
import {
  getLatestPVRegisterDate,
  getPVRegisterDocuments,
  getPvRegisterState,
  getPVRSubmissionStatus,
} from '../+pvRegister/store/+pvRegister.selectors';
import {
  getVppState,
  getVppSubmissionStatus,
  VppRegisterStatus,
  VppState,
} from '../+vppDocumentation/store';
import { StatusName } from './+setupTool.dictionary';

// This selector is placed here and not in
// +setupTool.selectors in order to avoid circular dependency
const getRegistrationSubject = createSelector(
  getConfiguration,
  (state) => state.registrationSubject
);

export interface SetupToolSteps {
  setupDataConfig: ReturnType<typeof getDashboardSetupDataConfig>;
  dsoRegistrationConfig: ReturnType<typeof getDashboardDsoRegistrationConfig>;
  dsoCommissioningConfig: ReturnType<typeof getDashboardDsoCommissioningConfig>;
  pvRegisterConfig: ReturnType<typeof getDashboardPvRegisterConfig>;
  vppConfig: ReturnType<typeof getDashboardVppConfig>;
  preCommissioningConfig: ReturnType<typeof getDashboardPreCommissioningConfig>;
}

export const getDashboardSetupDataConfig = createSelector(
  getLeadId,
  getFormFulfillment,
  getLatestModificationDate,
  getRouterLocationPathFirstSegment,
  (leadId, isFormFulfilled, latestFormModification, routerLocationPathFirstSegment) =>
    leadId
      ? {
          index: '1',
          name: `${I18n.t(T.setupTool.step.setupData)}`,
          date: latestFormModification ? formatDate(latestFormModification) : undefined,
          disabled: false,
          status: isFormFulfilled
            ? StatusName.DONE
            : latestFormModification
            ? StatusName.IN_PROGRESS
            : undefined,
          route: PATHS.SETUP_TOOL_SETUP_DATA(
            { leadId },
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME
              ? LEAD_IN_SETUP_STAGE
              : undefined
          ),
          buttonLabel: isFormFulfilled
            ? I18n.t(T.setupTool.step.update)
            : latestFormModification
            ? I18n.t(T.setupTool.step.complete)
            : undefined,
          buttonVisible: true,
          dataTestId: isFormFulfilled
            ? TestId.Lead.SetupConfigurator.SetupDataCompleteButton
            : TestId.Lead.SetupConfigurator.SetupDataStartButton,
        }
      : undefined
);

export const getDashboardDsoRegistrationConfig = createSelector(
  getLeadId,
  getFormFulfillment,
  getDsoRegistrationDocuments,
  getLatestModificationDate,
  getLatestDocumentsGenerationDate,
  getDsoGenerateDocumentsQueryStatus,
  getRouterLocationPathFirstSegment,
  (
    leadId,
    isFormFulfilled,
    documents,
    latestFormModification,
    latestDocumentsGeneration,
    dsoGenerateDocumentsQueryStatus,
    routerLocationPathFirstSegment
  ) => {
    const latestDocumentsGenerationDate = dateUtil.of(latestDocumentsGeneration);
    const latestFormModificationDate = latestFormModification
      ? dateUtil.of(latestFormModification)
      : dateUtil.now();
    return leadId
      ? {
          index: '2',
          name: `${I18n.t(T.setupTool.step.dsoRegistration)}`,
          date: latestDocumentsGeneration ? formatDate(latestDocumentsGeneration) : undefined,
          disabled:
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME
              ? !isFormFulfilled && !latestDocumentsGeneration
              : true,
          buttonDisabled:
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME ? !isFormFulfilled : true,
          buttonVisible:
            !latestDocumentsGeneration ||
            dateUtil.isBefore(latestDocumentsGenerationDate, latestFormModificationDate),
          status: getDSORegCommStatus(
            isFormFulfilled,
            latestDocumentsGeneration,
            latestFormModification
          ),
          route: PATHS.SETUP_TOOL_DSO_REGISTRATION({ leadId }),
          appendix: documents.length
            ? () => (
                <DsoSummaryFiles
                  compact={true}
                  documents={documents}
                  pending={dsoGenerateDocumentsQueryStatus.pending}
                />
              )
            : undefined,
          buttonLabel: dateUtil.isBefore(latestDocumentsGenerationDate, latestFormModificationDate)
            ? I18n.t(T.setupTool.step.redo)
            : I18n.t(T.setupTool.step.start),
          dataTestId: TestId.Lead.SetupConfigurator.DSORegistrationStartButton,
        }
      : undefined;
  }
);

export const getDashboardDsoCommissioningConfig = createSelector(
  getLeadId,
  getFormFulfillment,
  getDsoCommissioningDocuments,
  getDsoCommissioningGenerateDocumentsQueryStatus,
  getLatestDsoCommissioningDocumentsGenerationDate,
  getLatestModificationDate,
  getLatestDsoCommissioningDate,
  getRouterLocationPathFirstSegment,
  (
    leadId,
    isFormFulfilled,
    documents,
    dsoCommissioningGenerateDocumentsQueryStatus,
    latestDocumentsGeneration,
    latestFormModification,
    latestDsoCommissioningDate,
    routerLocationPathFirstSegment
  ) => {
    const latestDocumentsGenerationDate = dateUtil.of(latestDocumentsGeneration);
    const latestFormModificationDate = latestFormModification
      ? dateUtil.of(latestFormModification)
      : dateUtil.now();
    return leadId
      ? {
          index: '3',
          name: `${I18n.t(T.setupTool.step.dsoCommissioning)}`,
          date: latestDocumentsGeneration ? formatDate(latestDocumentsGeneration) : undefined,
          disabled:
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME
              ? !isFormFulfilled && !latestDocumentsGeneration
              : true,
          buttonDisabled:
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME ? !isFormFulfilled : true,
          buttonVisible:
            !latestDocumentsGeneration ||
            dateUtil.isBefore(latestDocumentsGenerationDate, latestFormModificationDate) ||
            dateUtil.isBefore(
              latestDocumentsGenerationDate,
              dateUtil.of(latestDsoCommissioningDate)
            ),
          status: getDSORegCommStatus(
            isFormFulfilled,
            latestDocumentsGeneration,
            dateUtil.isBefore(latestFormModificationDate, dateUtil.of(latestDsoCommissioningDate))
              ? latestDsoCommissioningDate
              : latestFormModification
          ),
          route: PATHS.SETUP_TOOL_DSO_COMMISSIONING({ leadId }),
          appendix: documents.length
            ? () => (
                <DsoSummaryFiles
                  compact={true}
                  documents={documents}
                  pending={dsoCommissioningGenerateDocumentsQueryStatus.pending}
                />
              )
            : undefined,
          buttonLabel: latestDocumentsGeneration
            ? I18n.t(T.setupTool.step.redo)
            : I18n.t(T.setupTool.step.start),
          dataTestId: TestId.Lead.SetupConfigurator.DSOCommissionStartButton,
        }
      : undefined;
  }
);

export const getDashboardPvRegisterConfig = createSelector(
  getLeadId,
  getFormFulfillment,
  getPVRSubmissionStatus,
  getLatestPVRegisterDate,
  getPVRegisterDocuments,
  getRouterLocationPathFirstSegment,
  getRegistrationSubject,
  getPvRegisterState,
  (
    leadId,
    isFormFulfilled,
    status,
    pvRegisterDate,
    documents,
    routerLocationPathFirstSegment,
    registrationSubject,
    pvRegisterState
  ) =>
    leadId
      ? {
          index: '4',
          name: `${I18n.t(T.setupTool.step.pvRegister)}`,
          date: pvRegisterDate ? formatDate(pvRegisterDate) : undefined,
          disabled: pvRegisterState === PvRegisterState.DISABLED,
          route: `${PATHS.SETUP_TOOL_PV_REGISTER({ leadId })}`,
          buttonVisible: true,
          buttonLabel: status ? I18n.t(T.setupTool.step.preview) : undefined,
          status: status
            ? (status === PvRegisterStatus.FINISH || status === PvRegisterStatus.FINISH_MANUALLY) &&
              // check if the required number of documents has been generated
              documents.length === (registrationSubject === RegistrationSubjectType.BATTERY ? 1 : 2)
              ? StatusName.DONE
              : StatusName.IN_PROGRESS
            : undefined,
          appendix: documents.length
            ? () => (
                <DsoSummaryFiles
                  compact={true}
                  documents={documents}
                  pending={status === PvRegisterStatus.IN_PROGRESS}
                />
              )
            : undefined,
          dataTestId: TestId.Lead.SetupConfigurator.PVRegisterStartButton,
        }
      : undefined
);

export const getDashboardVppConfig = createSelector(
  getLeadId,
  getVppSubmissionStatus,
  getRouterLocationPathFirstSegment,
  getVppState,
  (leadId, status, routerLocationPathFirstSegment, vppState) =>
    !!leadId && vppState !== VppState.HIDDEN
      ? {
          index: '1',
          uniqueIndexForID: '5',
          name: I18n.t(T.setupTool.step.meterPreparation),
          disabled: vppState === VppState.DISABLED,
          buttonDisabled: false,
          buttonVisible: true,
          buttonLabel:
            status === VppRegisterStatus.FINISH
              ? I18n.t(T.setupTool.step.preview)
              : status === VppRegisterStatus.IN_PROGRESS
              ? I18n.t(T.setupTool.step.continue)
              : undefined,
          status:
            !!status && vppState !== VppState.DISABLED
              ? status === VppRegisterStatus.FINISH
                ? StatusName.DONE
                : StatusName.IN_PROGRESS
              : undefined,
          route: PATHS.SETUP_TOOL_VPP_DOCUMENTATION(
            { leadId },
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME
              ? LEAD_IN_SETUP_STAGE
              : undefined
          ),
          dataTestId: TestId.Lead.SetupConfigurator.SonnenVPPDocumentationStartButton,
        }
      : undefined
);

export const getDashboardPreCommissioningConfig = createSelector(
  getLeadData,
  getLeadId,
  getRouterLocationPathFirstSegment,
  getPreCommissioningFields,
  getPreCommissioningStatus,
  (lead, leadId, routerLocationPathFirstSegment, fields, status) => {
    const isPrecomissioningDisabled = () => {
      if (isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)) {
        return (
          routerLocationPathFirstSegment !== SETUP_TAB_ROUTE_NAME || values(fields).includes('')
        );
      }

      return !isStatusSet(lead?.status.summary.hardwareOrderConfirmed);
    };

    return !!leadId && !!lead
      ? {
          index: '1',
          uniqueIndexForID: '6',
          name: `${I18n.t(T.setupTool.step.preCommissioning)}`,
          disabled: isPrecomissioningDisabled(),
          buttonDisabled: isPrecomissioningDisabled(),
          buttonVisible: status !== PreCommissioningStatus.GENERATED,
          buttonLabel:
            status === PreCommissioningStatus.NEEDS_REGENERATION
              ? I18n.t(T.setupTool.preCommissioning.regenerateButton)
              : I18n.t(T.setupTool.step.generate),
          status:
            status === PreCommissioningStatus.GENERATED
              ? StatusName.DONE
              : status === PreCommissioningStatus.NEEDS_REGENERATION
              ? StatusName.NEEDS_REGENERATION
              : undefined,
          route: PATHS.SETUP_TOOL_PRE_COMMISSIONING(
            { leadId },
            routerLocationPathFirstSegment === SETUP_TAB_ROUTE_NAME &&
              isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)
              ? LEAD_IN_SETUP_STAGE
              : undefined
          ),
          appendix:
            status === PreCommissioningStatus.GENERATED
              ? () => <PreCommissioningDocument fields={fields} />
              : status === PreCommissioningStatus.NEEDS_REGENERATION &&
                !mapUserStatusToInitialValues(lead).hardwareCommissioningCompleted
              ? () => <PrecommissioningDashboardInfobox />
              : undefined,
          dataTestId: TestId.Lead.SetupConfigurator.PreCommissioningStartButton,
        }
      : undefined;
  }
);
