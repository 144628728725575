import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp } from '@sonnen/shared-web/index';

import { SetupLeadEditStatusModal } from '+app/+lead/components/SetupLeadEditStatusModal';
import { SetupLeadStatuses } from '+lead/+list';
import { StatusManagementTooltipLocation } from '+lead/+list/store/+leadList.state';
import { isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { getActiveStatusManagementStatuses } from '+setupTool/store/+setupTool.helpers';
import { AccordionButton } from '+shared/components';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { getStatus } from '+shared/store/query/query.utils';

import { LeadOverviewHeaderTitle, LeadOverviewHeaderWrapper } from '../../components';
import { LeadOverviewInvitation } from '../LeadOverviewInvitation';

import './SetupLeadOverviewHeader.component.scss';

interface Props {
  lead: Lead;
  leadStage?: string;
  breadcrumbAction: () => void;
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  changeLeadStatus: (...status: LeadStatusName[]) => void;
  userCompanyName: string;
  sendCGInvitation: (leadId: string) => void;
  sendCGInvitationQueryStatus: ReturnType<typeof getStatus>;
}

export const SetupLeadOverviewHeader: React.FC<Props> = ({
  lead,
  leadStage,
  breadcrumbAction,
  isModalOpen,
  toggleModal,
  changeLeadStatus,
  userCompanyName,
  sendCGInvitation,
  sendCGInvitationQueryStatus,
}) => {
  const [isEditStatusModalOpen, setEditStatusModalOpen] = React.useState<boolean>(false);
  const [isMobileAccordionOpen, setMobileAccordionOpen] = React.useState<boolean>(false);

  const displayLeadRelatedStatuses = () => (
    <>
      <div className={'c-lead-overview-header__lead-statuses'}>
        {isLeadSonnenNowInterested(lead) ? (
          <StatusTile
            label={I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
            color={StatusTileColors.YELLOW}
          />
        ) : null}
      </div>

      <Media query={{ minWidth: breakpointUp('SM') }}>
        {isLeadSonnenNowInterested(lead) ? (
          <div className={'c-lead-overview-header__separator'} />
        ) : null}
      </Media>
    </>
  );

  return (
    <>
      <LeadOverviewHeaderWrapper
        lead={lead}
        breadcrumbAction={breadcrumbAction}
        leadStage={leadStage}
      >
        <Media query={{ minWidth: breakpointUp('MD') }}>
          {(isMedium: boolean) =>
            isMedium ? (
              <div className={'c-lead-overview-header'}>
                <div className={'c-lead-overview-header__upper'}>
                  <LeadOverviewHeaderTitle lead={lead} />
                </div>
                <div className={'c-lead-overview-header__lower'}>
                  <div className={'c-lead-overview-header__lower-right'}>
                    {displayLeadRelatedStatuses()}
                    <LeadOverviewInvitation
                      lead={lead}
                      isPending={sendCGInvitationQueryStatus.pending}
                      sendCGInvitation={sendCGInvitation}
                    />
                  </div>

                  <SetupLeadStatuses
                    lead={lead}
                    statuses={getActiveStatusManagementStatuses(lead.status)}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                    changeLeadStatus={changeLeadStatus}
                    userCompanyName={userCompanyName}
                    gtmClassName={'gtm-open-sm-button--details'}
                    location={StatusManagementTooltipLocation.SETUP_HEADER}
                  />
                </div>
              </div>
            ) : (
              <div className={'c-lead-overview-header'}>
                <div className={'c-lead-overview-header__mobile-upper'}>
                  <LeadOverviewHeaderTitle lead={lead} />

                  <div className={'c-lead-overview-header__accordion-wrapper'}>
                    <AccordionButton
                      isAccordionOpen={isMobileAccordionOpen}
                      setAccordionOpen={setMobileAccordionOpen}
                      messageOpen={I18n.t(
                        T.lead.overview._salessolution_.header.accordion.showLess
                      )}
                      messageClosed={I18n.t(
                        T.lead.overview._salessolution_.header.accordion.showMore
                      )}
                    />
                  </div>
                </div>

                <div
                  className={
                    'c-lead-overview-header__mobile-lower' +
                    'c-setup-overview-header__mobile-lower--flex'
                  }
                >
                  {isMobileAccordionOpen && (
                    <>
                      <div className={'c-lead-overview-header__accordion'}>
                        <div className={'c-lead-overview-header__mobile-lower-right'}>
                          <LeadOverviewInvitation
                            lead={lead}
                            isPending={sendCGInvitationQueryStatus.pending}
                            sendCGInvitation={sendCGInvitation}
                          />
                        </div>
                      </div>
                      <div className={'c-lead-overview-header__separator--bottom'} />
                    </>
                  )}
                  {displayLeadRelatedStatuses()}
                  <SetupLeadStatuses
                    lead={lead}
                    statuses={getActiveStatusManagementStatuses(lead.status)}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                    changeLeadStatus={changeLeadStatus}
                    userCompanyName={userCompanyName}
                    gtmClassName={'gtm-open-sm-button--details'}
                    location={StatusManagementTooltipLocation.SETUP_HEADER}
                  />
                </div>
              </div>
            )
          }
        </Media>
      </LeadOverviewHeaderWrapper>

      {
        <SetupLeadEditStatusModal
          isModalOpen={isEditStatusModalOpen && isModalOpen}
          toggleEditModal={setEditStatusModalOpen}
          toggleModal={toggleModal}
          lead={lead}
          updateStatuses={changeLeadStatus}
          userCompanyName={userCompanyName}
        />
      }
    </>
  );
};
