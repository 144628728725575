import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Carousel, PageSubheadline } from '@sonnen/shared-web';

import * as uuid from 'uuid';

import { SonnenDriveTestimonialsSlide } from '+drive/components';
import { getSlides } from '+drive/components/SonnenDriveTestimonials/SonnenDriveTestimonials.helper';

import photo from '../../../../assets/img/sonnenDrive/sonnen-drive-peoples.png';

import './SonnenDriveTestimonials.component.scss';

export const SonnenDriveTestimonials: React.FC = () => {
  const slides = getSlides();

  return (
    <div className={'c-sonnen-drive-testimonials'}>
      <PageSubheadline>{I18n.t(T.drive.reviews.title)}</PageSubheadline>
      <div className={'c-sonnen-drive-testimonials__content'}>
        <div className={'c-sonnen-drive-testimonials__carousel'}>
          <Carousel
            arrowsOnDesktop={false}
            arrowsOnMobile={false}
            autoplay={true}
            autoplaySpeed={5000}
            slidesToShow={1}
            slidesToScroll={1}
            speed={600}
          >
            {slides.map(({ avatar, opinion, author }) => {
              return (
                <SonnenDriveTestimonialsSlide
                  key={uuid.v4()}
                  avatar={avatar}
                  opinion={opinion}
                  author={author}
                />
              );
            })}
          </Carousel>
        </div>
        <div className={'c-sonnen-drive-testimonials__media-container'}>
          <img src={photo} className={'c-sonnen-drive-testimonials__photo'} />
        </div>
      </div>
    </div>
  );
};
