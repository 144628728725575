import React, { FC, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { FormWizardHeader, Wizard, WizardStep } from '@sonnen/shared-web';

import { push } from 'connected-react-router';

import { LeadRouteParams, PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { getLeadId } from '+lead/+overview/store/+overview.selectors';
import {
  DsoRegistrationCustomerData,
  DsoRegistrationDocuments,
  DsoRegistrationInstallerData,
  DsoRegistrationMeasuringDevice,
  DsoRegistrationPvSystem,
  DsoRegistrationSonnenBattery,
} from '+setupTool/+form';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { getCompletedSteps } from '+setupTool/+form/store/+form.helpers';
import {
  getBatteryNames,
  getDsoList,
  getInstallers,
  getPVInverters,
  getPVModules,
  getPvSystemForm,
  getSetupDataStatuses,
  getSonnenBatteryForm,
} from '+setupTool/+form/store/+form.selectors';
import { ErrorBox } from '+setupTool/components/ErrorBox';
import { Overlay } from '+setupTool/components/Overlay';
import { getErrors, getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';

import './DsoRegistrationForm.component.scss';

const OfferWizard = withRouter(Wizard);

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  errors: getErrors(state),
  stepsStatuses: getSetupDataStatuses(state),
  registrationSubject: getRegistrationSubject(state),
  dsoList: getDsoList(state),
  pvModules: getPVModules(state),
  pvSystemForm: getPvSystemForm(state),
  pvInverters: getPVInverters(state),
  batteryNames: getBatteryNames(state),
  sonnenBatteryForm: getSonnenBatteryForm(state),
  installersList: getInstallers(state),
});

const mapDispatchToProps = mapActions({
  goToSetupDashboard: (leadId: string, leadStage?: string) =>
    push(PATHS.SETUP_TOOL({ leadId }, leadStage)),
  toggleModal: LayoutActions.toggleModal,
  getDsoList: DsoRegistrationFormActions.getDsoList,
  getPVModuleVendors: DsoRegistrationFormActions.getPVModuleVendors,
  getPVModules: DsoRegistrationFormActions.getPVModules,
  getPVInverterVendors: DsoRegistrationFormActions.getPVInverterVendors,
  getPVInverters: DsoRegistrationFormActions.getPVInverters,
  getBatteryNames: DsoRegistrationFormActions.getBatteryNames,
  getBatteries: DsoRegistrationFormActions.getBatteries,
  getInstallers: DsoRegistrationFormActions.getInstallers,
});

type RouteProps = RouteComponentProps<LeadRouteParams>;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps &
  OwnProps;

export const DsoRegistrationFormComponent: FC<Props> = ({
  actions,
  leadId,
  errors,
  leadStage,
  stepsStatuses,
  registrationSubject,
  dsoList,
  pvModules,
  pvSystemForm,
  pvInverters,
  batteryNames,
  sonnenBatteryForm,
  installersList,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.SETUP, PageName.Setup.SETUP_DATA_FORM_MODAL);

  const overlayRef = useRef<HTMLDivElement>(null);
  const completedSteps = getCompletedSteps(stepsStatuses, registrationSubject);

  useEffect(() => {
    actions.toggleModal(true);

    if (!dsoList.length) {
      actions.getDsoList();
    }

    if (!pvModules.length) {
      actions.getPVModuleVendors();
      if (pvSystemForm && pvSystemForm.pv_module_type) {
        actions.getPVModules(pvSystemForm.pv_module_type);
      }
    }

    if (!pvInverters.length) {
      actions.getPVInverterVendors();
      if (pvSystemForm && pvSystemForm.pv_inverter_type) {
        actions.getPVInverters(pvSystemForm.pv_inverter_type);
      }
      if (pvSystemForm && pvSystemForm.existing_pv_inverter_manufacturer) {
        actions.getPVInverters(pvSystemForm.existing_pv_inverter_manufacturer);
      }
    }

    if (!batteryNames.length) {
      actions.getBatteryNames();
      if (sonnenBatteryForm && sonnenBatteryForm.battery_name) {
        actions.getBatteries(sonnenBatteryForm.battery_name);
      }
    }

    if (!installersList || !installersList.length) {
      actions.getInstallers();
    }

    return () => {
      actions.toggleModal(false);
    };
  }, []);

  const handleRef = () => {
    if (overlayRef && overlayRef.current) {
      overlayRef.current.scrollTo(0, 0);
    }
  };

  const {
    sonnenBattery,
    measuringDevice,
    pvSystem,
    pvSystemBatteryOnly,
    customerData,
    upload,
    installerData,
  } = T.setupTool.step;

  return (
    <Overlay
      className={'c-setup-tool-form-wizard'}
      title={I18n.t(T.setupTool.setupData)}
      onClose={() => actions.goToSetupDashboard(leadId!, leadStage)}
      ref={overlayRef}
    >
      <OfferWizard
        header={
          <>
            <FormWizardHeader
              className={'c-setup-tool-form-wizard__header'}
              markOnlyCompletedSteps={true}
              completedSteps={completedSteps}
            />
            <ErrorBox errors={errors} />
          </>
        }
        onNextStep={handleRef}
        onPrevStep={handleRef}
        onJumpToStep={handleRef}
        isThemeClickable={true}
      >
        <WizardStep name={I18n.t(customerData)} anchor="customer-data" canAccess={true}>
          <DsoRegistrationCustomerData stepStatus={stepsStatuses.customerData} />
        </WizardStep>
        <WizardStep
          name={I18n.t(
            registrationSubject === RegistrationSubjectType.BATTERY ? pvSystemBatteryOnly : pvSystem
          )}
          anchor="pv-system"
          canAccess={true}
        >
          <DsoRegistrationPvSystem stepStatus={stepsStatuses.pvSystem} />
        </WizardStep>
        <WizardStep name={I18n.t(sonnenBattery)} anchor="sonnen-battery" canAccess={true}>
          <DsoRegistrationSonnenBattery stepStatus={stepsStatuses.sonnenBattery} />
        </WizardStep>
        <WizardStep name={I18n.t(measuringDevice)} anchor="measuring-device" canAccess={true}>
          <DsoRegistrationMeasuringDevice stepStatus={stepsStatuses.measuringDevice} />
        </WizardStep>
        <WizardStep name={I18n.t(upload)} anchor="uploads" canAccess={true}>
          <DsoRegistrationDocuments stepStatus={stepsStatuses.uploadDocuments} />
        </WizardStep>
        <WizardStep name={I18n.t(installerData)} anchor="installer-data" canAccess={true}>
          <DsoRegistrationInstallerData
            leadStage={leadStage}
            stepStatus={stepsStatuses.installerData}
          />
        </WizardStep>
      </OfferWizard>
    </Overlay>
  );
};

export const DsoRegistrationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DsoRegistrationFormComponent);
