import * as React from 'react';

import { Lead } from '+shared/store/lead/types';

import './LeadListName.component.scss';

interface Props {
  item: Lead;
}

export const LeadListName: React.FC<Props> = ({ item }) => {
  return (
    <div className="c-lead-list-name">
      {!!item.company?.name && <Paragraph>{item.company.name}</Paragraph>}
      <Paragraph>
        {item.lastName} {item.firstName}
      </Paragraph>
    </div>
  );
};

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <p className="c-lead-list-name__paragraph" data-hj-suppress={true}>
    {children}
  </p>
);
