import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointDown } from '@sonnen/shared-web';

import { LoaderWrapper } from '+shared/components/LoaderWrapper';
import { StoreState } from '+shared/store/store.interface';

import promoImage from '../../../../assets/img/sonnenDrive/sonnen-drive-promo-placeholder.png';
import promoImageMobile from '../../../../assets/img/sonnenDrive/sonnen-drive-promo-placeholder-mobile.jpg';

import './SonnenDrivePromo.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lang: state.i18n.locale,
});

interface Props {
  lang: string;
}

export const SonnenDrivePromoComponent: React.FC<Props> = ({ lang }) => (
  <div className={'c-sonnen-drive-promo'}>
    <div className={'c-sonnen-drive-promo__media-container'}>
      {lang === 'de' ? (
        <LoaderWrapper
          render={(toggleLoading) => (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ctpof061gSs"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="sonnenDrive"
              className={'c-sonnen-drive-promo__iframe'}
              onLoad={() => toggleLoading(false)}
            />
          )}
        />
      ) : (
        <img
          className={'c-sonnen-drive-promo__image'}
          src={promoImageMobile}
          srcSet={`${promoImageMobile} ${breakpointDown('SM')}w, ${promoImage} ${breakpointDown(
            'MD'
          )}w,`}
          alt="sonnenDrive"
        />
      )}
    </div>
    <div className={'c-connen-drive-promo__text-container'}>
      <p className={'c-sonnen-drive-promo__title'}>{I18n.t(T.drive.header.header)}</p>
      <p className={'c-connen-drive-promo__description'}>{I18n.t(T.drive.header.paragraph)}</p>
    </div>
  </div>
);

export const SonnenDrivePromo = connect(mapStateToProps)(SonnenDrivePromoComponent);
