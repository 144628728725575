import { push } from 'connected-react-router';
import { isEmpty, isNil } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { forkJoin, of, throwError } from 'rxjs';
import {
  debounceTime,
  delay,
  filter,
  map,
  mergeMap,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';

import { LeadOfferPageActions } from '+app/+lead/+offer/store/+offer.actions';
import { LEAD_IN_SETUP_STAGE, PATHS, ROUTES, SETUP_TAB_ROUTE_NAME } from '+app/router';
import { RouterActions } from '+app/router/store/router.actions';
import { getRouterLocationPathFirstSegment } from '+app/router/store/router.selectors';
import { mapPathToParams, processQuery } from '+app/utils';
import { LeadActions } from '+shared/store/lead';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { ConfigurationPvType } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapToState } from '+utils/operators/mapToState.operator';
import { ofType } from '+utils/operators/ofType.operator';

import { formFields } from '../containers/LeadConfigurationForm/LeadConfigurationForm.helper';
import { ConfigurationPageActions } from './+configuration.actions';
import { getConfiguration, getConfigurationProposal } from './+configuration.selectors';
import {
  CONFIGURATION_OPTIONAL_RECOMMENDATION_SUBMIT_QUERY,
  CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
  CONFIGURATION_REMOVE_QUERY,
  CONFIGURATION_SAVE_QUERY,
  CONFIGURATION_SUBMIT_QUERY,
  SET_CONFIGURATION_PROPOSAL_QUERY,
} from './+configuration.state';

type Action$ = ActionsObservable<ConfigurationPageActions | LeadActions>;
type State$ = StateObservable<StoreState>;

const createConfiguration$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.setConfigurationForm),
    map((action) => action.configurationForm),
    mergeMap((configurationForm) =>
      of(configurationForm).pipe(
        mapToState(state$),
        mergeMap((state) =>
          forkJoin(
            of(state).pipe(
              mapPathToParams(
                ROUTES.LEAD_CONFIGURATION_NEW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],

                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
              ),
              map(([leadId]) => leadId)
            ),
            of(state).pipe(map((state) => getConfiguration(state)))
          )
        ),
        mergeMap(([leadId, configuration]) =>
          !configuration || !leadId
            ? throwError(new Error('createConfiguration$ :: cannot create configuration'))
            : of({
                configuration: configuration as NonNullable<typeof configuration>,
                leadId: leadId as NonNullable<typeof leadId>,
              })
        ),
        map((data) =>
          LeadActions.postConfiguration({
            id: data.leadId,
            config: {
              ...data.configuration,
              photovoltaicSystem: configurationForm.multiplePv
                ? [
                    {
                      ...data.configuration.photovoltaicSystem[0],
                      type: configurationForm.pvType,
                    },
                    {
                      ...data.configuration.photovoltaicSystem[1],
                      type: configurationForm.secondPvPvType,
                    },
                  ]
                : {
                    ...data.configuration.photovoltaicSystem,
                    type: configurationForm.pvType,
                  },
              powerPlant: {
                dsoConsentToCombinePhotovoltaicSystems:
                  configurationForm.dsoConsentToCombinePhotovoltaicSystems,
              },
            },
            queryKey: CONFIGURATION_SUBMIT_QUERY,
          })
        )
      )
    )
  );

const updateAutarky$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.updateAutarky),
    map((action) => action.autarky),
    mergeMap((autarky) =>
      of(autarky).pipe(
        mapToState(state$),
        mergeMap((state) =>
          forkJoin(
            of(state).pipe(
              mapPathToParams(
                ROUTES.LEAD_CONFIGURATION_NEW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],

                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
                ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
                ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
              ),
              map(([leadId]) => leadId)
            ),
            of(state).pipe(map((state) => getConfigurationProposal(state)))
          )
        ),
        mergeMap(([leadId, config]) =>
          !config || !leadId || !config.id
            ? throwError(new Error('updateAutarky$ :: cannot update autarky'))
            : of({
                config: config as NonNullable<typeof config>,
                leadId: leadId as NonNullable<typeof leadId>,
              })
        ),
        map((data) =>
          LeadActions.updateAutarky({
            id: data.leadId,
            configId: data.config.id,
            autarky,
            queryKey: SET_CONFIGURATION_PROPOSAL_QUERY,
          })
        )
      )
    )
  );

const createUpdateAutarkySuccess$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.updateAutarkySuccess),
    throttleTime(1000),
    map((action) => action.config),
    map(ConfigurationPageActions.setConfigurationProposal)
  );

const createConfigurationSuccess$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postConfigurationSuccess),
    map((action) => action.config),
    map(ConfigurationPageActions.setConfigurationProposal)
  );

const createConfigurationRecommendation$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.createRecommendation),
    debounceTime(700),
    map((action) => action.configurationForm),
    filter((form) => {
      const pvSystemModeFields =
        form.pvType === ConfigurationPvType.DETAILED
          ? [formFields.ORIENTATION, formFields.INCLINATION]
          : [formFields.SPECIFIC_YIELD_PER_YEAR];
      const requiredFields = [
        ...pvSystemModeFields,
        formFields.TOTAL_CONSUMPTION_PER_YEAR,
        formFields.COMMISSIONING_DATE,
        formFields.MODEL_NAME,
      ];

      const missingValue = requiredFields.find((field) => {
        const fieldValue = form[field];

        return isNil(fieldValue) || fieldValue === '';
      });

      return !missingValue;
    }),
    mergeMap((form) =>
      of(form).pipe(
        mapToState(state$),
        mapPathToParams(
          ROUTES.LEAD_CONFIGURATION_NEW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],

          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
        ),
        map(([leadId]) => leadId),
        mergeMap((leadId) =>
          !leadId
            ? throwError(
                new Error(
                  'createConfigurationRecommendation$ :: cannot create configuration recommendation'
                )
              )
            : of(leadId as NonNullable<typeof leadId>)
        ),
        map((leadId) =>
          LeadActions.postConfigurationRecommendation({
            id: leadId,
            form,
            queryKey: CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
          })
        )
      )
    )
  );

const createConfigurationOptionalRecommendation$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.createOptionalRecommendation),
    debounceTime(700),
    map((action) => action.configurationForm),
    filter((form) => {
      const pvSystemModeFields =
        form.pvType === ConfigurationPvType.DETAILED
          ? [formFields.ORIENTATION, formFields.INCLINATION]
          : [formFields.SPECIFIC_YIELD_PER_YEAR];
      const requiredFields = [
        ...pvSystemModeFields,
        formFields.TOTAL_CONSUMPTION_PER_YEAR,
        formFields.COMMISSIONING_DATE,
        formFields.MODEL_NAME,
      ];

      const missingValue = requiredFields.find((field) => {
        const fieldValue = form[field];

        return isNil(fieldValue) || fieldValue === '';
      });

      return !missingValue;
    }),
    mergeMap((form) =>
      of(form).pipe(
        mapToState(state$),
        mapPathToParams(
          ROUTES.LEAD_CONFIGURATION_NEW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],

          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
        ),
        map(([leadId]) => leadId),
        mergeMap((leadId) =>
          !leadId
            ? throwError(
                new Error(
                  'createConfigurationOptionalRecommendation$' +
                    ':: cannot create configuration recommendation'
                )
              )
            : of(leadId as NonNullable<typeof leadId>)
        ),
        map((leadId) =>
          LeadActions.postConfigurationOptionalRecommendation({
            id: leadId,
            form,
            queryKey: CONFIGURATION_OPTIONAL_RECOMMENDATION_SUBMIT_QUERY,
          })
        )
      )
    )
  );

const createConfigurationRecommendationSuccess$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postConfigurationRecommendationSuccess),
    map((action) => action.config),
    map((config) => ({
      capacityGross: config.battery.capacityGross.value,
      peakPower: config.photovoltaicSystem.peakPower.value,
    })),
    map(ConfigurationPageActions.setConfigurationHint)
  );

const createConfigurationOptionalRecommendationSuccess$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postConfigurationOptionalRecommendationSuccess),
    map((action) => action.config),
    map((config) => ({
      capacityGrossOptional: config.battery.capacityGross.value,
      peakPowerOptional: config.photovoltaicSystem.peakPower.value,
    })),
    map(ConfigurationPageActions.setConfigurationHint)
  );

const setupClearConfigurationHint$ = (action$: Action$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.setupClearConfigurationHint),
    debounceTime(500),
    mergeMap((action) =>
      of(action).pipe(
        delay(250),
        takeUntil(action$.pipe(ofType(LeadActions.postConfigurationRecommendation))),
        map(ConfigurationPageActions.clearConfigurationHint)
      )
    )
  );

const saveConfiguration$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.saveConfiguration),
    mergeMap((action) =>
      of(action).pipe(
        mapToState(state$),
        mergeMap((state) =>
          of(state).pipe(
            mapPathToParams(
              ROUTES.LEAD_CONFIGURATION_NEW[0],
              ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
              ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
              ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],

              ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X[0],
              ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
              ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X[0],
              ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
              ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
              ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
              ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
              ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
            ),
            mergeMap(([leadId]) =>
              of({}).pipe(
                processQuery(
                  CONFIGURATION_SAVE_QUERY,
                  () =>
                    action.offerId
                      ? LeadRepository.patchLeadOfferToAddTariff(
                          leadId,
                          action.configurationId,
                          action.offerId,
                          action.taxRegulation
                        )
                      : LeadRepository.postLeadOffer(
                          leadId,
                          action.configurationId,
                          action.batteryId,
                          action.taxRegulation
                        ),
                  {
                    onSuccess:
                      getRouterLocationPathFirstSegment(state) === SETUP_TAB_ROUTE_NAME
                        ? () => of(push(PATHS.LEAD_CONFIGURATION({ leadId }, LEAD_IN_SETUP_STAGE)))
                        : () => of(push(PATHS.LEAD_CONFIGURATION({ leadId }))),
                  }
                )
              )
            )
          )
        )
      )
    )
  );

const removeConfiguration$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(ConfigurationPageActions.removeConfiguration),
    mergeMap((action) =>
      of(action).pipe(
        mapToState(state$),
        mapPathToParams(
          ROUTES.LEAD_CONFIGURATION_NEW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],

          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
          ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
          ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
        ),
        map(([leadId]) =>
          LeadActions.deleteConfiguration({
            queryKey: CONFIGURATION_REMOVE_QUERY,
            configurationId: action.configurationId,
            leadId,
          })
        )
      )
    )
  );

const getExistingOfferData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(RouterActions.isReady),
    mapToState(state$),
    mapPathToParams(
      ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
      ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],

      ROUTES.LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
      ROUTES.LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0],
      ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW[0],
      ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW[0]
    ),
    filter((params) => !isEmpty(params)),
    map(([leadId, offerId]) => LeadOfferPageActions.getOffer(leadId, offerId))
  );

export const epics = combineEpics(
  createConfiguration$,
  updateAutarky$,
  createConfigurationSuccess$,
  createConfigurationRecommendation$,
  createConfigurationOptionalRecommendation$,
  createConfigurationRecommendationSuccess$,
  createConfigurationOptionalRecommendationSuccess$,
  setupClearConfigurationHint$,
  saveConfiguration$,
  removeConfiguration$,
  createUpdateAutarkySuccess$,
  getExistingOfferData$
);
