import { omit } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, identity, iif, merge, of, zip } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { dataGuard, makeQuery, mapToState, ofType, processQuery } from '+app/utils';
import { getLeadId } from '+lead/+overview/store/+overview.selectors';
import { getDsoCommissioningFields } from '+setupTool/+dsoCommissioning/store/+dsoCommissioning.selectors';
import { DsoCommissioningInterface } from '+setupTool/+dsoCommissioning/store/types';
import { DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY } from '+setupTool/+form/store';
import {
  getCustomerDataProps,
  getDocumentsFileProps,
  getInstallerDataProps,
  getMeasuringDeviceProps,
  getPvSystemProps,
  getSonnenBatteryProps,
} from '+setupTool/+form/store/+form.helpers';
import { getDsoRegistrationFormSubmitQueryStatus } from '+setupTool/+form/store/+form.selectors';
import { PreCommissioningActions } from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import { PreCommissioningInterface } from '+setupTool/+preCommissioning/store/types';
import {
  getVppDocumentationFields,
  getVppDocumentationProps,
  getVppSubmissionStatus,
  getVppSubmissionStatusFromStepsUpdatedAt,
  vppDocumentationFileCategories,
  VppDocumentationInterface,
  VppRegisterStatus,
} from '+setupTool/+vppDocumentation/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import {
  getLatestSubmission,
  getSubmissionData$,
  mapActionTypeToErrorMessage,
  omitFormValues,
} from '+setupTool/store/+setupTool.helpers';
import {
  getAdditionalFeatures,
  getRegistrationSubject,
  getSubmissionDataQueryStatus,
  getSubmissionId,
} from '+setupTool/store/+setupTool.selectors';
import { DsoRegisterActions, getDsoRegisterForm } from '+shared/store/setupTool';
import { DsoRegisterRepository } from '+shared/store/setupTool/setupTool.repository';
import { DsoRegistrationFormAttributes } from '+shared/store/setupTool/types';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';

import { DsoCommissioningActions } from '../+dsoCommissioning/store/+dsoCommissioning.actions';
import { epics as dsoCommissioningEpics$ } from '../+dsoCommissioning/store/+dsoCommissioning.epics';
import { getDsoCommissioningProps } from '../+dsoCommissioning/store/+dsoCommissioning.helpers';
import { DsoRegistrationActions } from '../+dsoRegistration/store/+dsoRegistration.actions';
import { epics as dsoRegistrationEpics$ } from '../+dsoRegistration/store/+dsoRegistration.epics';
import { DsoRegistrationFormActions } from '../+form/store/+form.actions';
import { epics as form$ } from '../+form/store/+form.epics';
import {
  DsoRegistrationCustomerDataFiles,
  DsoRegistrationCustomerDataInitial,
  DsoRegistrationDocumentsFiles,
  DsoRegistrationDocumentsInitial,
  DsoRegistrationInstallerDataInitial,
  getDsoRegistrationMeasuringDeviceInitial,
  getDsoRegistrationPvSystemInitial,
  getDsoRegistrationSonnenBatteryInitial,
} from '../+form/store/schemas';
import { PVRegisterActions } from '../+pvRegister/store/+pvRegister.actions';
import { epics as pvRegisterEpics$ } from '../+pvRegister/store/+pvRegister.epics';
import { getPvRegisterProps } from '../+pvRegister/store/+pvRegister.helpers';
import { VppDocumentationActions } from '../+vppDocumentation/store/+vppDocumentation.actions';
import { epics as vppDocumentationEpics$ } from '../+vppDocumentation/store/+vppDocumentation.epics';
import { SubmissionStep } from './+setupTool.dictionary';

type Action$ = ActionsObservable<DsoRegistrationFormActions | DsoRegisterActions>;
type State$ = StateObservable<StoreState>;

const getSetupToolSubmissionData$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SetupToolActions.getSetupToolSubmissionData),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(state).pipe(
        mergeMap((state) =>
          zip(
            of(getDsoRegisterForm(state) as DsoRegistrationFormAttributes),
            of(getDsoRegistrationFormSubmitQueryStatus(state)),
            of(getSubmissionDataQueryStatus(state) as any),
            of(getDsoCommissioningFields(state) as DsoCommissioningInterface),
            of(getVppDocumentationFields(state) as VppDocumentationInterface),
            of(null),
            of(getUserProfileId(state))
          )
        ),
        mergeMap(
          ([
            form,
            formSubmitStatus,
            submissionDataQueryStatus,
            commissioningFields,
            vppFields,
            _,
            userId,
          ]) =>
            of({}).pipe(
              processQuery(
                action.queryKey,
                () => DsoRegisterRepository.getSubmissionByCustomerId(action.customerId),
                {
                  onSuccess: (res: any) => {
                    const latestSubmission = getLatestSubmission(res.data, 'updatedAt');

                    if (!latestSubmission) {
                      if (!formSubmitStatus.pending && !submissionDataQueryStatus.pending) {
                        return of(
                          DsoRegisterActions.postRegisterDso({
                            data: {
                              ...form,
                              commissioningFields,
                              vppFields,
                              customerId: action.customerId,
                              userId,
                              step: SubmissionStep.INITIAL,
                            } as any,
                            queryKey: DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
                          })
                        );
                      } else {
                        return of();
                      }
                    }

                    const customerDataProps = getCustomerDataProps(latestSubmission.fields);
                    const pvSystemProps = getPvSystemProps(
                      latestSubmission.fields,
                      latestSubmission.registrationSubject
                    );
                    const sonnenBatteryProps = getSonnenBatteryProps(
                      latestSubmission.fields,
                      latestSubmission.registrationSubject
                    );
                    const measuringDeviceProps = getMeasuringDeviceProps(latestSubmission.fields);
                    const documentsFileProps = getDocumentsFileProps(latestSubmission);
                    const installerDataProps = getInstallerDataProps(latestSubmission.fields);
                    const dsoCommissioningFieldsProps = getDsoCommissioningProps(latestSubmission);
                    const pvRegisterProps = getPvRegisterProps(latestSubmission);
                    const vppDocumentationProps = getVppDocumentationProps(latestSubmission);
                    const vppSubmissionStatus = getVppSubmissionStatusFromStepsUpdatedAt(
                      latestSubmission.stepsUpdatedAt
                    );

                    return merge(
                      dataGuard(DsoRegistrationFormActions.setDsoCustomerData)(customerDataProps),
                      dataGuard(DsoRegistrationFormActions.setDsoPvSystem)(pvSystemProps),
                      dataGuard(DsoRegistrationFormActions.setDsoSonnenBattery)(sonnenBatteryProps),
                      dataGuard(DsoRegistrationFormActions.setDsoMeasuringDevice)(
                        measuringDeviceProps
                      ),
                      dataGuard(DsoRegistrationFormActions.setDsoDocumentsFile)(documentsFileProps),
                      dataGuard(DsoRegistrationFormActions.setDsoInstallerData)(installerDataProps),
                      dataGuard(DsoCommissioningActions.setFields)(dsoCommissioningFieldsProps),
                      dataGuard(PVRegisterActions.init)(pvRegisterProps),
                      dataGuard(VppDocumentationActions.setFields)(vppDocumentationProps),
                      dataGuard(VppDocumentationActions.setSubmissionStatus)(vppSubmissionStatus),
                      dataGuard(VppDocumentationActions.setVppAdditionalFeatures)(
                        latestSubmission.vppAdditionalFeatures
                      ),
                      dataGuard(SetupToolActions.setAdditionalFeatures)(
                        latestSubmission.additionalFeatures
                      ),
                      dataGuard(SetupToolActions.setUploadedDocuments)(
                        latestSubmission.documents.filter((doc: any) => doc.status)
                      ),
                      dataGuard(SetupToolActions.setGeneratedDocuments)(
                        latestSubmission.documents.filter((doc: any) => doc.status)
                      ),
                      of(SetupToolActions.setSubmissionId(latestSubmission.id)),
                      of(
                        SetupToolActions.setRegistrationSubjectType(
                          latestSubmission.registrationSubject
                        )
                      ),
                      of(VppDocumentationActions.setVppState(latestSubmission.vppState)),
                      of(PVRegisterActions.setPVRegisterState(latestSubmission.pvrState)),
                      of(
                        SetupToolActions.setLatestModificationDate(latestSubmission.stepsUpdatedAt)
                      ),
                      of(
                        DsoRegistrationFormActions.setSetupDataStatuses(
                          latestSubmission.setupDataStatuses
                        )
                      ),
                      of(
                        DsoRegistrationFormActions.checkFormFulfillment(
                          latestSubmission.registrationSubject
                        )
                      )
                    );
                  },
                  onFailure: () =>
                    of(
                      SetupToolActions.appendError(
                        mapActionTypeToErrorMessage(
                          SetupToolActions.getSetupToolSubmissionData.type
                        )
                      )
                    ),
                }
              )
            )
        )
      )
    )
  );

export const getPreCommissioningData$ = (action$: Action$) =>
  action$.pipe(
    ofType(PreCommissioningActions.getPreCommissioningData),
    mergeMap(({ queryKey, customerId }) =>
      of({}).pipe(
        processQuery(
          queryKey,
          () => DsoRegisterRepository.getPreCommissioningDataByCustomerId(customerId),
          {
            onSuccess: (res: { data: PreCommissioningInterface }) => {
              return merge(
                dataGuard(PreCommissioningActions.setPreCommissioningData)(
                  omitFormValues(res!.data, ['generationStatus'])
                ),
                dataGuard(PreCommissioningActions.setPreCommissioningStatus)(
                  res!.data.generationStatus
                )
              );
            },
            onFailure: (err) =>
              of(
                SetupToolActions.appendError(
                  mapActionTypeToErrorMessage(PreCommissioningActions.getPreCommissioningData.type)
                )
              ),
          }
        )
      )
    )
  );

export const markGenerationOfPreCommissioningDocument$ = (action$: Action$) =>
  action$.pipe(
    ofType(PreCommissioningActions.markGenerationOfPreCommissioningDocument),
    mergeMap(({ queryKey, customerId, fields }) =>
      of({}).pipe(
        processQuery(
          queryKey,
          () => DsoRegisterRepository.putPreCommissioningDataByCustomerId(customerId, fields),
          {
            onSuccess: (res: { data: PreCommissioningInterface }) => {
              return merge(
                dataGuard(PreCommissioningActions.setPreCommissioningData)(res!.data),
                dataGuard(PreCommissioningActions.setPreCommissioningStatus)(
                  res!.data.generationStatus
                )
              );
            },
            onFailure: (err) =>
              of(
                SetupToolActions.appendError(
                  mapActionTypeToErrorMessage(
                    PreCommissioningActions.markGenerationOfPreCommissioningDocument.type
                  )
                )
              ),
          }
        )
      )
    )
  );

const setLatestSubmissionPost$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoRegisterActions.postRegisterDsoSuccess),
    map((action) => action.config.data),
    mergeMap((submission) =>
      concat(
        of(SetupToolActions.setSubmissionId(submission.id)),
        of(SetupToolActions.setLatestModificationDate(submission.stepsUpdatedAt)),
        of(DsoRegistrationFormActions.checkFormFulfillment(submission.registrationSubject))
      )
    )
  );

const setLatestSubmissionPatch$ = (action$: Action$) =>
  action$.pipe(
    ofType(DsoRegisterActions.patchSubmissionSuccess),
    map((action) => action.config.data),
    mergeMap((data) => {
      return concat(
        iif(
          () =>
            Object.values(data.submission.setupDataStatuses).every((val) => val === 'completed'),
          of(DsoRegistrationFormActions.trackSetupDataCompleted())
        ),
        of(SetupToolActions.setSubmissionId(data.submission.id)),
        of(SetupToolActions.setLatestModificationDate(data.submission.stepsUpdatedAt)),
        of(DsoRegistrationFormActions.checkFormFulfillment(data.submission.registrationSubject))
      );
    })
  );

const saveSubmission$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SetupToolActions.saveSubmission),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(state).pipe(
        mergeMap((state) =>
          zip(
            of(
              omit(
                getDsoRegisterForm(state),
                DsoRegistrationDocumentsFiles,
                DsoRegistrationCustomerDataFiles
              ) as DsoRegistrationFormAttributes
            ),
            of(getDsoCommissioningFields(state) as DsoCommissioningInterface),
            of(
              omit(
                getVppDocumentationFields(state),
                vppDocumentationFileCategories
              ) as VppDocumentationInterface
            ),
            of(getAdditionalFeatures(state)),
            of(getLeadId(state)),
            of(getUserProfileId(state)),
            of(getSubmissionId(state)),
            of(getRegistrationSubject(state))
          )
        ),
        map(
          ([
            form,
            commissioningFields,
            vppFields,
            additionalFeatures,
            leadId,
            userId,
            submissionId,
            registrationSubject,
          ]: any) =>
            DsoRegisterActions.patchSubmission({
              data: {
                ...form,
                commissioningFields,
                vppFields:
                  getVppSubmissionStatus(state) !== VppRegisterStatus.FINISH ? vppFields : {},
                customerId: leadId!,
                userId,
                step: action.step,
                stepStatuses: action.stepStatuses,
              },
              submissionId,
              registrationSubject,
              additionalFeatures,
              queryKey: DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
            })
        )
      )
    )
  );

const saveSubmissionOnlyVppFields$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SetupToolActions.saveSubmissionOnlyVppFields),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(state).pipe(
        mergeMap((state) =>
          zip(
            of(
              omit(
                getDsoRegisterForm(state),
                DsoRegistrationDocumentsFiles,
                DsoRegistrationCustomerDataFiles
              ) as DsoRegistrationFormAttributes
            ),
            of(getDsoCommissioningFields(state) as DsoCommissioningInterface),
            of(
              omit(
                getVppDocumentationFields(state),
                vppDocumentationFileCategories
              ) as VppDocumentationInterface
            ),
            of(getAdditionalFeatures(state)),
            of(getLeadId(state)),
            of(getUserProfileId(state)),
            of(getSubmissionId(state)),
            of(getRegistrationSubject(state))
          )
        ),
        map(
          ([
            form,
            commissioningFields,
            vppFields,
            additionalFeatures,
            leadId,
            userId,
            submissionId,
            registrationSubject,
          ]: any) =>
            DsoRegisterActions.patchSubmissionOnlyVppFields({
              data: {
                ...form,
                commissioningFields,
                vppFields:
                  getVppSubmissionStatus(state) !== VppRegisterStatus.FINISH ? vppFields : {},
                customerId: leadId!,
                userId,
                step: action.step,
                stepStatuses: action.stepStatuses,
              },
              submissionId,
              registrationSubject,
              additionalFeatures,
              queryKey: DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY,
            })
        )
      )
    )
  );

const saveSubmissionWithCallback$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SetupToolActions.saveSubmissionWithCallback),
    map(identity),
    mergeMap((action) =>
      of(action).pipe(
        mapToState(state$),
        mergeMap(getSubmissionData$),
        mergeMap(
          ([
            form,
            commissioningFields,
            vppFields,
            additionalFeatures,
            leadId,
            userId,
            submissionId,
            registrationSubject,
          ]: any) =>
            makeQuery(action.payload.query)({
              call: () =>
                DsoRegisterRepository.patchSubmission(
                  {
                    ...form,
                    commissioningFields,
                    vppFields,
                    customerId: leadId!,
                    userId,
                    ...action.payload,
                  },
                  submissionId,
                  registrationSubject,
                  additionalFeatures
                ),
              onSuccess: (res) =>
                merge(of(DsoRegisterActions.patchSubmissionSuccess(res!)), of(action.callback)),
              onFailure: (err) =>
                merge(
                  of(DsoRegisterActions.patchSubmissionFailure(err)),
                  of(
                    SetupToolActions.appendError(
                      mapActionTypeToErrorMessage(DsoRegisterActions.patchSubmission.type)
                    )
                  )
                ),
            })
        )
      )
    )
  );

const saveSubmissionWithCallbackOnlyVppFields$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SetupToolActions.saveSubmissionWithCallbackOnlyVppFields),
    map(identity),
    mergeMap((action) =>
      of(action).pipe(
        mapToState(state$),
        mergeMap(getSubmissionData$),
        mergeMap(
          ([
            form,
            commissioningFields,
            vppFields,
            additionalFeatures,
            leadId,
            userId,
            submissionId,
            registrationSubject,
          ]: any) =>
            makeQuery(action.payload.query)({
              call: () =>
                DsoRegisterRepository.patchSubmissionOnlyVppFields(
                  {
                    ...form,
                    commissioningFields,
                    vppFields,
                    customerId: leadId!,
                    userId,
                    ...action.payload,
                  },
                  submissionId,
                  registrationSubject,
                  additionalFeatures
                ),
              onSuccess: (res) =>
                merge(of(DsoRegisterActions.patchSubmissionSuccess(res!)), of(action.callback)),
              onFailure: (err) =>
                merge(
                  of(DsoRegisterActions.patchSubmissionFailure(err)),
                  of(
                    SetupToolActions.appendError(
                      mapActionTypeToErrorMessage(DsoRegisterActions.patchSubmission.type)
                    )
                  )
                ),
            })
        )
      )
    )
  );

const saveRegistrationSubjectType$ = (action$: Action$, state$: State$) =>
  action$.pipe(
    ofType(SetupToolActions.saveRegistrationSubjectType),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(state).pipe(
        mergeMap(() =>
          of({}).pipe(
            processQuery(
              action.queryKey,
              () =>
                DsoRegisterRepository.patchRegistrationTypeSubmission(
                  action.subject,
                  action.submissionId
                ),
              {
                onSuccess: (res: any) => {
                  const customerDataProps = getCustomerDataProps(res!.data.fields);
                  const pvSystemProps = getPvSystemProps(
                    res!.data.fields,
                    res!.data.registrationSubject
                  );
                  const sonnenBatteryProps = getSonnenBatteryProps(
                    res!.data.fields,
                    res!.data.registrationSubject
                  );
                  const measuringDeviceProps = getMeasuringDeviceProps(res!.data.fields);
                  const documentsFileProps = getDocumentsFileProps(res!.data);
                  const installerDataProps = getInstallerDataProps(res!.data.fields);
                  return merge(
                    dataGuard(DsoRegistrationFormActions.setDsoCustomerData)(
                      DsoRegistrationCustomerDataInitial
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoCustomerData)(customerDataProps),
                    dataGuard(DsoRegistrationFormActions.setDsoPvSystem)(
                      getDsoRegistrationPvSystemInitial(action.subject)
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoPvSystem)(pvSystemProps),
                    dataGuard(DsoRegistrationFormActions.setDsoSonnenBattery)(
                      getDsoRegistrationSonnenBatteryInitial(action.subject)
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoSonnenBattery)(sonnenBatteryProps),
                    dataGuard(DsoRegistrationFormActions.setDsoMeasuringDevice)(
                      getDsoRegistrationMeasuringDeviceInitial(action.subject)
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoMeasuringDevice)(
                      measuringDeviceProps
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoDocumentsFile)(
                      DsoRegistrationDocumentsInitial
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoDocumentsFile)(documentsFileProps),
                    dataGuard(DsoRegistrationFormActions.setDsoInstallerData)(
                      DsoRegistrationInstallerDataInitial
                    ),
                    dataGuard(DsoRegistrationFormActions.setDsoInstallerData)(installerDataProps),
                    dataGuard(SetupToolActions.setGeneratedDocuments)(
                      res!.data.documents.filter((doc: any) => doc.status)
                    ),
                    of(SetupToolActions.setRegistrationSubjectType(res!.data.registrationSubject)),
                    of(DsoRegistrationActions.setDsoRegistrationModificationDate('')),
                    of(DsoCommissioningActions.setDsoCommissioningDocumentsGenerationDate('')),
                    of(
                      DsoRegistrationFormActions.setSetupDataStatuses(res!.data.setupDataStatuses)
                    ),
                    of(
                      DsoRegistrationFormActions.checkFormFulfillment(res!.data.registrationSubject)
                    )
                  );
                },
                onFailure: (err) =>
                  of(
                    SetupToolActions.appendError(
                      mapActionTypeToErrorMessage(SetupToolActions.saveRegistrationSubjectType.type)
                    )
                  ),
              }
            )
          )
        )
      )
    )
  );

export const epics = combineEpics(
  form$,
  dsoCommissioningEpics$,
  getSetupToolSubmissionData$,
  getPreCommissioningData$,
  markGenerationOfPreCommissioningDocument$,
  dsoRegistrationEpics$,
  setLatestSubmissionPatch$,
  setLatestSubmissionPost$,
  pvRegisterEpics$,
  vppDocumentationEpics$,
  saveSubmission$,
  saveSubmissionOnlyVppFields$,
  saveSubmissionWithCallback$,
  saveSubmissionWithCallbackOnlyVppFields$,
  saveRegistrationSubjectType$
);
