import * as React from 'react';

import { Icofont, Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import { get } from 'lodash';

import useInputEvent from '+shared/hooks/useInputEvent';

import { validateField } from '../Form.helpers';

import './FormStatusCheckbox.component.scss';

export interface FormInputProps<T> {
  name: string;
  label?: React.ReactNode;
  form: FormikProps<T>;
  disabled?: boolean;
  initialValue: boolean;
  isStatusAutomatic?: boolean;
  suppressLabelInHotjar?: boolean;
}

const ObservableInputElement = ({ form, field, ...props }: FieldProps) => {
  const { onChange } = useInputEvent(field);

  return <input {...props} {...field} checked={get(form.values, field.name)} onChange={onChange} />;
};

export const FormStatusCheckbox = <T extends any = any>({
  form,
  name,
  label,
  disabled = false,
  initialValue,
  isStatusAutomatic,
  suppressLabelInHotjar = false,
}: FormInputProps<T>) => {
  const validation = validateField({ name, form });
  let icon = <Icon.Checkmark className={'c-form-status-checkbox__box-icon'} />;

  const idValue = form.values[name] ? `${name}_checkbox_checked` : `${name}_checkbox_unchecked`;

  if (isStatusAutomatic) {
    icon = (
      <Icon.StatusAutomatic
        className={classNames(
          'c-form-status-checkbox__box-icon',
          'c-form-status-checkbox__box-icon--automatic'
        )}
      />
    );
  }

  if (initialValue) {
    icon = (
      <Icofont
        type={'tick'}
        className={classNames(
          'c-form-status-checkbox__box-icon',
          'c-form-status-checkbox__box-icon--status-set'
        )}
      />
    );
  }

  return (
    <div
      className={classNames('c-form-status-checkbox', {
        'c-form-status-checkbox--error': validation.hasError,
      })}
    >
      <Field
        component={ObservableInputElement}
        name={name}
        className={'c-form-status-checkbox__input'}
        type={'checkbox'}
        disabled={isStatusAutomatic || initialValue}
        id={idValue}
      />
      <label className={'c-form-status-checkbox__label'} htmlFor={idValue}>
        <span
          className={classNames('c-form-status-checkbox__box', {
            'c-form-status-checkbox__box--automatic': isStatusAutomatic,
            'c-form-status-checkbox__box--status-set': initialValue,
          })}
        >
          {icon}
        </span>
        <div className={'c-form-status-checkbox__text'} data-hj-suppress={suppressLabelInHotjar}>
          {label}
        </div>
      </label>
    </div>
  );
};
