import * as React from 'react';

import { DefaultParagraph } from '@sonnen/shared-web';

import './SonnenDriveCarsSlide.component.scss';

interface Props {
  featured?: boolean;
  image: string;
  name: string;
  price: string;
  priceSubline: string;
  newPrice?: string;
  newPriceSubline?: string;
  amount: string;
  button?: string;
}

export const SonnenDriveCarsSlide: React.FC<Props> = ({
  image,
  name,
  price,
  priceSubline,
  newPrice,
  newPriceSubline,
  amount,
}) => (
  <div className={'c-sonnen-drive-cars-slide'}>
    <div className={'c-sonnen-drive-cars-slide__inner'}>
      <DefaultParagraph className={'c-sonnen-drive-cars-slide__name'}>{name}</DefaultParagraph>
      <div className={'c-sonnen-drive-cars-slide__image'}>
        <img src={image} className={'c-sonnen-drive-cars-slide__car'} />
      </div>
      <div className={'c-sonnen-drive-cars-slide__pricing'}>
        <div className={'c-sonnen-drive-cars-slide__price-normal'}>
          <div className={'c-sonnen-drive-cars-slide__price-container'}>
            <del className={'c-sonnen-drive-cars-slide__price'}>{price}</del>
            <span className={'c-sonnen-drive-cars-slide__amount'}>{amount}</span>
          </div>
          <DefaultParagraph className={'c-sonnen-drive-cars-slide__price-description'}>
            {priceSubline}
          </DefaultParagraph>
        </div>
        <div className={'c-sonnen-drive-cars-slide__price-promo'}>
          <div className={'c-sonnen-drive-cars-slide__price-container'}>
            <ins className={'c-sonnen-drive-cars-slide__promo'}>{newPrice}</ins>
            <span className={'c-sonnen-drive-cars-slide__amount'}>{amount}</span>
          </div>
          <DefaultParagraph className={'c-sonnen-drive-cars-slide__promo-description'}>
            {newPriceSubline}
          </DefaultParagraph>
        </div>
      </div>
    </div>
  </div>
);
