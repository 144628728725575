import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import imageCenter from '../../../../assets/img/sonnenDrive/sonnen-drive-cars.png';
import imageRight from '../../../../assets/img/sonnenDrive/sonnen-drive-nissan.png';
import imageLeft from '../../../../assets/img/sonnenDrive/sonnen-drive-renault.jpg';

export interface CarouselSlide {
  featured?: boolean;
  image: string;
  name: string;
  price: string;
  priceSubline: string;
  newPrice?: string;
  newPriceSubline?: string;
  priceFromText?: string;
  amount: string;
  button?: string;
}

export const getSlides = (): CarouselSlide[] => [
  {
    image: imageLeft,
    name: I18n.t(T.drive.cars.left.name),
    price: I18n.t(T.drive.cars.left.price),
    priceSubline: I18n.t(T.drive.cars.left.priceSubline),
    newPrice: I18n.t(T.drive.cars.left.newPrice),
    newPriceSubline: I18n.t(T.drive.cars.left.newPriceSubline),
    amount: I18n.t(T.drive.cars.price.perMonth),
  },
  {
    featured: true,
    image: imageCenter,
    name: I18n.t(T.drive.cars.center.name),
    priceFromText: I18n.t(T.drive.cars.center.priceFromText),
    price: I18n.t(T.drive.cars.center.price),
    priceSubline: I18n.t(T.drive.cars.center.priceSubline),
    amount: I18n.t(T.drive.cars.price.perMonth),
    button: I18n.t(T.drive.cars.center.button),
  },
  {
    image: imageRight,
    name: I18n.t(T.drive.cars.right.name),
    price: I18n.t(T.drive.cars.right.price),
    priceSubline: I18n.t(T.drive.cars.right.priceSubline),
    newPrice: I18n.t(T.drive.cars.right.newPrice),
    newPriceSubline: I18n.t(T.drive.cars.right.newPriceSubline),
    amount: I18n.t(T.drive.cars.price.perMonth),
  },
];
