import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Status, StatusType, TableCell } from '@sonnen/shared-web';

import { LeadListName } from '+lead/+list';
import { isLeadSonnenNowInterested, mapConsumption, mapLocation } from '+lead/store/+lead.helper';
import { Button, ButtonSize, ButtonType } from '+shared/components/Button';
import { Lead } from '+shared/store/lead/types';
import { formatSquareMeters } from '+utils/format.util.old';

import './InboxLeadListTableItem.component.scss';

interface Props {
  item: Lead;
  toggleAssignLeadModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
  setChosenInboxLead: (arg: Lead) => void;
  toggleDeclineLeadModal: (arg: boolean) => void;
}

const displayDeclineBtn = (openLeadDeclineModal: () => void) => (
  <Button
    className={'c-lead-overview-email-changed-modal__link-button'}
    onClick={openLeadDeclineModal}
    label={I18n.t(T.lead.list._salessolution_.decline)}
    size={ButtonSize.SMALL}
    type={ButtonType.TERTIARY}
    dataTestId="leads-inbox-decline-lead-btn"
  />
);

const displayAcceptBtn = (openLeadAssignModal: () => void) => (
  <Button
    className={`c-inbox-lead-list-table-item__cta`}
    size={ButtonSize.MEDIUM}
    label={I18n.t(T.lead.list._salessolution_.accept)}
    type={ButtonType.SECONDARY}
    onClick={openLeadAssignModal}
    dataTestId="leads-inbox-accept-lead-btn"
  />
);

const InboxLeadListTableItemHints = ({ item }: { item: Lead }) => (
  <>
    {item.alreadyInContact ? (
      <div className={'c-inbox-lead-list-table-item__hint'}>
        <Status
          type={StatusType.SUCCESS}
          label={I18n.t(T.lead.list._salessolution_.alreadyYourContact)}
        />
      </div>
    ) : null}

    {item.automaticallyConverted ? (
      <div className={'c-inbox-lead-list-table-item__hint'}>
        <Status
          type={StatusType.WARNING}
          label={I18n.t(T.lead.list._salessolution_.automaticallyConverted)}
        />
      </div>
    ) : null}

    {isLeadSonnenNowInterested(item) ? (
      <div className={'c-inbox-lead-list-table-item__hint'}>
        <Status
          type={StatusType.WARNING}
          label={I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
        />
      </div>
    ) : null}
  </>
);

export const InboxLeadListTableItem: React.FC<Props> = ({
  item,
  toggleAssignLeadModal,
  toggleModal,
  setChosenInboxLead,
  toggleDeclineLeadModal,
}) => {
  const openLeadAssignModal = () => {
    toggleAssignLeadModal(true);
    toggleModal(true);
    setChosenInboxLead(item);
  };

  const openLeadDeclineModal = () => {
    toggleDeclineLeadModal(true);
    toggleModal(true);
    setChosenInboxLead(item);
  };

  return (
    <div className={`c-inbox-lead-list-table-item`}>
      <Media query={{ minWidth: 576 }}>
        {(isMedium: boolean) =>
          isMedium ? (
            <>
              <div className={`c-inbox-lead-list-table-item-details`}>
                <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                  <LeadListName item={item} />
                </TableCell>
                <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                  {mapLocation(item)}
                </TableCell>
                <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                  {mapConsumption(item.energyConsumption)}
                </TableCell>
                <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                  {item.roofDetails && item.roofDetails.size
                    ? formatSquareMeters(item.roofDetails.size.value)
                    : ''}
                </TableCell>

                <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                  {displayDeclineBtn(openLeadDeclineModal)}
                </TableCell>
                <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                  {displayAcceptBtn(openLeadAssignModal)}
                </TableCell>
              </div>
              <InboxLeadListTableItemHints item={item} />
            </>
          ) : (
            <>
              <TableCell className={`c-inbox-lead-list-table-item__cell`}>
                <Icon.Account className={'c-inbox-lead-list-table-item__nav-icon'} />
                <LeadListName item={item} />
              </TableCell>

              <div className={'c-inbox-lead-list-table-item__details-wrapper'}>
                <div>
                  {I18n.t(T.lead.list._salessolution_.location)} {mapLocation(item)}
                </div>
                <div>
                  {I18n.t(T.lead.list._salessolution_.consumption)}{' '}
                  {mapConsumption(item.energyConsumption)} /{' '}
                  {I18n.t(T.lead.list._salessolution_.roofSize)}{' '}
                  {item.roofDetails ? formatSquareMeters(item.roofDetails.size.value) : ''}
                </div>
              </div>
              <InboxLeadListTableItemHints item={item} />
              <div className={'c-inbox-lead-list-table-item__buttons-wrapper'}>
                {displayDeclineBtn(openLeadDeclineModal)}
                {displayAcceptBtn(openLeadAssignModal)}
              </div>
            </>
          )
        }
      </Media>
    </div>
  );
};
