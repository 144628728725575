import { omit } from 'lodash/fp';
import { combineReducers } from 'redux';

import { LEAD_ACTIONS, LeadActions } from '+shared/store/lead';
import { LeadStage, LeadStatusName } from '+shared/store/lead/types';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux/createReducer.util';

import * as configuration from '../+configuration/store/+configuration.reducer';
import * as hardware from '../+hardware/store/+hardware.reducer';
import * as impactAnalysis from '../+impactAnalysis/store/+impactAnalysis.reducer';
import * as leadList from '../+list/store/+leadList.reducer';
import * as offer from '../+offer/store/+offer.reducer';
import * as overview from '../+overview/store/+overview.reducer';
import { LEAD_PAGE_ACTIONS, LeadPageActions } from './+lead.actions';
import { getLeadHwStatusesToUpdate } from './+lead.helper';
import {
  ASSIGN_PARTNER_TO_LEAD_QUERY,
  CHANGE_LEAD_STATUS_QUERY,
  DECLINE_LEAD_QUERY,
  GET_DELIVERY_ADDRESS_QUERY,
  GET_INVOICE_ADDRESS_QUERY,
  GET_LEAD_QUERY,
  initialCommonState,
  initialPartnerAssignmentState,
  LEAD_SUBMIT_QUERY,
  LEAD_UPDATE_QUERY,
  LeadPageCommonState,
  LeadPagePartnerAssignmentState,
  VALIDATE_LEAD_ADDRESS_QUERY,
} from './+lead.state';

const partnerAssignmentReducer = createReducer<LeadPagePartnerAssignmentState, LeadPageActions>(
  (state = initialPartnerAssignmentState, action): LeadPagePartnerAssignmentState => {
    switch (action.type) {
      case LEAD_PAGE_ACTIONS.SET_ASSIGN_LEAD_MODAL:
        return {
          ...state,
          isAssignLeadModalOpen: action.isAssignLeadModalOpen,
        };
      case LEAD_PAGE_ACTIONS.SET_DECLINE_LEAD_MODAL:
        return {
          ...state,
          isDeclineLeadModalOpen: action.isDeclineLeadModalOpen,
        };
      case LEAD_PAGE_ACTIONS.SET_SELECTED_LEAD:
        return {
          ...state,
          selectedLead: action.selectedLead,
        };
      default:
        return state;
    }
  }
);

const commonReducer = createReducer<LeadPageCommonState, LeadPageActions | LeadActions>(
  (state = initialCommonState, action): LeadPageCommonState => {
    switch (action.type) {
      case LEAD_ACTIONS.SET_LEAD:
        return {
          ...state,
          lead: action.lead,
        };
      case LEAD_ACTIONS.SET_USER_PERMISSIONS:
        return {
          ...state,
          userPermissions: action.permissions,
        };
      case LEAD_PAGE_ACTIONS.MARK_LEAD_AS_SEEN_SUCCESS:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                isNewlyAssigned: false,
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SET_PARTNER_EMPLOYEE_LIST:
        return {
          ...state,
          partnerEmployeeList: action.partnerEmployeeList,
        };
      case LEAD_PAGE_ACTIONS.SET_ASSIGN_LEAD_MODAL:
      case LEAD_PAGE_ACTIONS.SET_DECLINE_LEAD_MODAL:
      case LEAD_PAGE_ACTIONS.SET_SELECTED_LEAD:
        return {
          ...state,
          partnerAssignment: partnerAssignmentReducer(state.partnerAssignment, action),
        };
      case LEAD_PAGE_ACTIONS.SET_DELIVERY_ADDRESS_SUGGESTIONS:
        return {
          ...state,
          deliveryAddressSuggestions: action.deliveryAddress,
        };
      case LEAD_PAGE_ACTIONS.SET_INVOICE_ADDRESS_SUGGESTIONS:
        return {
          ...state,
          invoiceAddressSuggestions: action.invoiceAddress,
        };
      case LEAD_PAGE_ACTIONS.SET_PARTNER_NOTE:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                note: action.noteId,
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SET_DELETED_LEAD_STATUS:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  ...state.lead.status,
                  summary: omit(action.statusNames, state.lead.status.summary),
                },
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SET_OFFER_SENT_STATUS_INACTIVE:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  ...state.lead.status,
                  summary: {
                    ...state.lead.status.summary,
                    [LeadStatusName.FLAT_OFFER_SENT]: {
                      isSet: false,
                      stage: LeadStage.OFFER_SENT,
                      name: LeadStatusName.FLAT_OFFER_SENT,
                      createdAt: new Date(),
                      reason: 'Last offer was deleted',
                    },
                  },
                },
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SET_HARDWARE_OFFER_SENT_STATUS_INACTIVE:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  ...state.lead.status,
                  summary: {
                    ...state.lead.status.summary,
                    [LeadStatusName.HW_OFFER_SENT]: {
                      isSet: false,
                      stage: LeadStage.OFFER_SENT,
                      name: LeadStatusName.HW_OFFER_SENT,
                      createdAt: new Date(),
                      reason: 'Last offer was deleted',
                    },
                  },
                },
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SET_HARDWARE_ALREADY_SOLD_STATUS_INACTIVE:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  ...state.lead.status,
                  summary: {
                    ...state.lead.status.summary,
                    [LeadStatusName.HW_ALREADY_SOLD]: {
                      isSet: false,
                      stage: LeadStage.HW_ALREADY_SOLD,
                      name: LeadStatusName.HW_ALREADY_SOLD,
                      createdAt: new Date(),
                      hierarchy: 0,
                      reason: 'Last flat only offer was deleted',
                    },
                  },
                },
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.VALIDATE_LEAD_ADDRESS_SUCCESS:
        return {
          ...state,
          isAddressValid: action.isAddressValid,
          openAddressEdit: false,
        };
      case LEAD_PAGE_ACTIONS.EDIT_LEAD_ADDRESS:
        return {
          ...state,
          openAddressEdit: true,
        };
      case LEAD_PAGE_ACTIONS.VALIDATE_LEAD_ADDRESS:
      case LEAD_PAGE_ACTIONS.VALIDATE_LEAD_ADDRESS_CLEAR:
        return {
          ...state,
          isAddressValid: undefined,
          openAddressEdit: false,
        };
      case LEAD_PAGE_ACTIONS.CLEAR_LEAD_DATA:
        return {
          ...initialCommonState,
          [GET_LEAD_QUERY]: state[GET_LEAD_QUERY],
        };
      case LEAD_PAGE_ACTIONS.SET_CG_INVITATION_SENT:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  currentStatus: state.lead.status.currentStatus,
                  summary: {
                    ...state.lead.status.summary,
                    invitationSent: {
                      isSet: true,
                      createdAt: new Date(),
                      stage: LeadStage.INVITATION_SENT,
                      name: LeadStatusName.INVITATION_SENT,
                    },
                  },
                },
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SET_LEAD_DSO:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                dso: action.dso,
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.SAVE_ASYNC_LEAD_STATUS:
        return {
          ...state,
          leadStatusToBeChanged: action.status,
        };
      case LEAD_PAGE_ACTIONS.SET_ASYNC_FLAT_OFFER_SENT_STATUS:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  ...state.lead.status,
                  summary: {
                    ...state.lead.status.summary,
                    [LeadStatusName.FLAT_OFFER_SENT]: {
                      stage: LeadStage.OFFER_SENT,
                      name: LeadStatusName.FLAT_OFFER_SENT,
                      isSet: true,
                      createdAt: new Date(),
                    },
                  },
                },
              },
            }
          : state;
      case LEAD_PAGE_ACTIONS.CLEAR_ASYNC_LEAD_STATUS:
        return {
          ...state,
          leadStatusToBeChanged: undefined,
        };
      case LEAD_PAGE_ACTIONS.SET_LEAD_HARDWARE_STATUS:
        return state.lead
          ? {
              ...state,
              lead: {
                ...state.lead,
                status: {
                  ...state.lead.status,
                  summary: {
                    ...state.lead.status.summary,
                    [LeadStatusName.HW_OFFER_SENT]: getLeadHwStatusesToUpdate(action.status)[
                      LeadStatusName.HW_OFFER_SENT
                    ],
                    [LeadStatusName.HW_OFFER_ACCEPTED]: getLeadHwStatusesToUpdate(action.status)[
                      LeadStatusName.HW_OFFER_ACCEPTED
                    ],
                    [LeadStatusName.HW_ORDER_CONFIRMED]: getLeadHwStatusesToUpdate(action.status)[
                      LeadStatusName.HW_ORDER_CONFIRMED
                    ],
                    [LeadStatusName.CONTACTED]: {
                      stage: LeadStage.CONTACTED,
                      name: LeadStatusName.CONTACTED,
                      isSet: true,
                      createdAt: new Date(),
                    },
                    [LeadStatusName.ON_SITE_VISIT_ARRANGED]: {
                      stage: LeadStage.ON_SITE_VISIT_ARRANGED,
                      name: LeadStatusName.ON_SITE_VISIT_ARRANGED,
                      isSet: true,
                      createdAt: new Date(),
                    },
                    [LeadStatusName.ON_SITE_VISIT_DONE]: {
                      stage: LeadStage.ON_SITE_VISIT_DONE,
                      name: LeadStatusName.ON_SITE_VISIT_DONE,
                      isSet: true,
                      createdAt: new Date(),
                    },
                  },
                },
              },
            }
          : state;
      default:
        return queryReducer(state, action, [
          LEAD_SUBMIT_QUERY,
          LEAD_UPDATE_QUERY,
          GET_LEAD_QUERY,
          CHANGE_LEAD_STATUS_QUERY,
          ASSIGN_PARTNER_TO_LEAD_QUERY,
          DECLINE_LEAD_QUERY,
          VALIDATE_LEAD_ADDRESS_QUERY,
          GET_DELIVERY_ADDRESS_QUERY,
          GET_INVOICE_ADDRESS_QUERY,
        ]);
    }
  }
);

export const reducer = combineReducers({
  common: commonReducer,
  offer: offer.reducer,
  configuration: configuration.reducer,
  hardware: hardware.reducer,
  overview: overview.reducer,
  list: leadList.reducer,
  impactAnalysis: impactAnalysis.reducer,
});
