import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import { LeadConfigurationNew } from '+app/+lead/+configuration/containers/LeadConfigurationNew';
import { LeadHardwareNew } from '+app/+lead/+hardware';
import { LeadImpactAnalysis } from '+app/+lead/+impactAnalysis';
import { SetupLeadList } from '+app/+lead/+list/containers/SetupLeadList';
import { LeadOfferComposer } from '+app/+lead/+offer/containers';
import { SetupLeadOverview } from '+app/+lead/+overview';
import { LEAD_IN_SETUP_STAGE, ROUTES } from '+app/router';
import { mapActions } from '+app/utils';
import { RestrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { setupLeadTabAllowedRoles } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';

import { SetupLeadContext } from './SetupLead.context';

const mapStateToProps = (state: StoreState) => ({
  userProfileRoles: getUserProfileRoles(state),
});

const mapDispatchToProps = mapActions({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SetupComponent: React.FC<Props> = ({ userProfileRoles }) => {
  return (
    <SetupLeadContext.Provider value={{ leadStage: LEAD_IN_SETUP_STAGE }}>
      <RestrictedToRolesOr404 allowedRoles={setupLeadTabAllowedRoles} userRoles={userProfileRoles}>
        <Switch>
          <Route path={ROUTES.SETUP_LEAD_IMPACT_ANALYSIS} component={LeadImpactAnalysis} />
          <Route path={ROUTES.SETUP_LEAD_OFFER} component={LeadOfferComposer} />
          <Route
            path={ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_X}
            component={LeadConfigurationNew}
          />
          <Route
            path={ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT}
            component={LeadConfigurationNew}
          />
          <Route path={ROUTES.SETUP_LEAD_HARDWARE_NEW} component={LeadHardwareNew} />
          <Route path={ROUTES.SETUP_LEAD_OVERVIEW} component={SetupLeadOverview} />
          <Route path={[ROUTES.SETUP_LEADS[0]]} component={SetupLeadList} />
        </Switch>
      </RestrictedToRolesOr404>
    </SetupLeadContext.Provider>
  );
};

export const SetupLead = connect(mapStateToProps, mapDispatchToProps)(SetupComponent);
