import React, { ReactNode } from 'react';

import classnames from 'classnames';

import './Bodies.scss';

interface Props {
  text: ReactNode;
  className?: string;
}

export const BodyLarge: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'body-large');

  return <p className={classNames}>{text}</p>;
};

export const BodyMedium: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'body-medium');

  return <p className={classNames}>{text}</p>;
};

export const BodySmall: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'body-small');

  return <p className={classNames}>{text}</p>;
};

export const BodySmallCaps: React.FC<Props> = ({ text, className }) => {
  const classNames = classnames(className, 'body-small-caps');

  return <p className={classNames}>{text}</p>;
};
