import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { StatusManagementTooltipLocation } from '+app/+lead/+list/store/+leadList.state';
import { SetupLeadEditStatusModal } from '+app/+lead/components/SetupLeadEditStatusModal';
import { SetupLeadStatusTooltipWrapper } from '+lead/+list';
import { Button, ButtonType } from '+shared/components/Button';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';

import './SetupLeadStatuses.component.scss';

interface Statuses {
  registration: string[];
  hardwareSetup: string[];
  sonnenFlatSetup: string[];
  registrationCompleted: boolean;
  hardwareSetupCompleted: boolean;
  sonnenFlatSetupCompleted: boolean;
}

interface Props {
  lead: Lead;
  statuses: Statuses;
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  changeLeadStatus: (...status: LeadStatusName[]) => void;
  userCompanyName: string;
  gtmClassName?: string;
  location?: StatusManagementTooltipLocation;
}

type StatusKey = 'registration' | 'hardwareSetup' | 'sonnenFlatSetup';

const displayStatuses: StatusKey[] = ['registration', 'hardwareSetup', 'sonnenFlatSetup'];

export const SetupLeadStatuses = ({
  lead,
  statuses,
  isModalOpen,
  toggleModal,
  changeLeadStatus,
  userCompanyName,
  gtmClassName,
}: Props) => {
  const [isEditStatusModalOpen, setEditStatusModalOpen] = React.useState<boolean>(false);

  return (
    <div className="c-setup-lead-statuses">
      {displayStatuses.map((item) => (
        <SetupLeadStatusItem key={item} item={item} statuses={statuses} />
      ))}

      <Button
        type={ButtonType.SECONDARY}
        label={I18n.t(T.setupTool.statusManagement.button.update)}
        className={classNames('c-setup-lead-statuses__button', gtmClassName)}
        dataTestId="update-button"
        onClick={(event) => {
          event.stopPropagation();
          setEditStatusModalOpen(true);
          toggleModal(true);
        }}
      />
      <div onClick={(e) => e.stopPropagation()}>
        <SetupLeadEditStatusModal
          isModalOpen={isEditStatusModalOpen && isModalOpen}
          toggleEditModal={setEditStatusModalOpen}
          toggleModal={toggleModal}
          lead={lead}
          updateStatuses={changeLeadStatus}
          userCompanyName={userCompanyName}
        />
      </div>
    </div>
  );
};

interface SetupLeadStatusIconProps {
  completionStatus: 'completed' | 'inProgress' | 'notStarted';
}

const SetupLeadStatusIcon = ({ completionStatus }: SetupLeadStatusIconProps) => {
  const className = 'c-setup-lead-statuses__item__icon';
  return completionStatus === 'completed' ? (
    <Icon.TickCircle color="#338707" className={className} />
  ) : completionStatus === 'inProgress' ? (
    <Icon.TwoThirdsOfCircle color="#338707" className={className} />
  ) : (
    <Icon.Zigzag color="#61687A" className={className} />
  );
};

interface SetupLeadStatusItemProps {
  statuses: Statuses;
  item: StatusKey;
}

const SetupLeadStatusItem = ({ statuses, item }: SetupLeadStatusItemProps) => {
  const state = statuses[item];
  const completionStatus = statuses[`${item}Completed`]
    ? 'completed'
    : state.length
    ? 'inProgress'
    : 'notStarted';

  return (
    <SetupLeadStatusTooltipWrapper statuses={statuses[item]}>
      <div className="c-setup-lead-statuses__item" data-testid={`${item}-status`}>
        <SetupLeadStatusIcon completionStatus={completionStatus} />
        {I18n.t(T.setupTool.statusManagement.section.header[item])}
      </div>
    </SetupLeadStatusTooltipWrapper>
  );
};
