import * as React from 'react';

import './SonnenDriveTestimonialsSlide.component.scss';

interface Props {
  avatar: string;
  opinion: string;
  author: string;
}

export const SonnenDriveTestimonialsSlide: React.FC<Props> = ({ avatar, opinion, author }) => (
  <div className={'c-sonnen-drive-testimonials-slide'}>
    <img src={avatar} className={'c-sonnen-drive-testimonials-slide__avatar'} />
    <p className={'c-sonnen-drive-testimonials-slide__opinion'}>{opinion}</p>
    <p className={'c-sonnen-drive-testimonials-slide__author'}>{author}</p>
  </div>
);
