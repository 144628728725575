import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { EnergyUnit } from '@sonnen/shared-web';

import { isNil } from 'lodash/fp';

import { OtherUnit } from '+app/App.constants';
import { ConfigurationForm } from '+lead-configuration/store/types';
import { getAreaCallingCode } from '+shared/components/Form/FormInputPhone/FormInputPhone.helper';
import { LeadSource } from '+shared/store/lead/types/lead.interface';
import { searchByKey } from '+utils/array.util';
import { dateUtil } from '+utils/date.util';
import { formatEnergy, formatPower } from '+utils/format.util';

import {
  BatteryModelName,
  BatteryModule,
  ConfigurationPvType,
  Lead,
  LeadAddress,
  LeadConfigurationAttributes,
  LeadCreateDto,
  LeadCustomerType,
  LeadProductType,
  SinglePhotovoltaicSystem,
} from './types';
import {
  LeadProductBattery,
  LeadProductBatteryParametersAttribute,
} from './types/leadProductBattery.interface';

const getCountryCode = ({ deliveryAddress }: LeadCreateDto) =>
  deliveryAddress ? getAreaCallingCode(deliveryAddress.country) : '';

export const mapLeadAttributesToDto = (lead: LeadCreateDto) => ({
  company: lead.customerType === LeadCustomerType.BUSINESS ? lead.company : undefined,
  salutation: lead.salutation,
  title: lead.title,
  first_name: lead.firstName,
  last_name: lead.lastName,
  email: lead.email,
  phone: lead.phone && lead.phone.length ? getCountryCode(lead) + lead.phone : lead.phone,
  mobile_phone:
    lead.mobilePhone && lead.mobilePhone.length
      ? getCountryCode(lead) + lead.mobilePhone
      : lead.mobilePhone,
  delivery_address: lead.deliveryAddress && {
    street: lead.deliveryAddress.street,
    zip_code: lead.deliveryAddress.zipCode,
    city: lead.deliveryAddress.city,
    country: lead.deliveryAddress.country,
    kind: lead.deliveryAddress.kind,
  },
  invoice_address: lead.invoiceAddress
    ? {
        street: lead.invoiceAddress.street,
        zip_code: lead.invoiceAddress.zipCode,
        city: lead.invoiceAddress.city,
        country: lead.invoiceAddress.country,
      }
    : undefined,
  agreedToGdpr: lead.agreedToGdpr,
  optedOutOfMarketingMailing: lead.optedOutOfMarketingMailing,
});

const getCommonPvSystemDataToDto = (
  photovoltaicSystem: SinglePhotovoltaicSystem,
  isMultiplePv = false
) => ({
  type: photovoltaicSystem.type,
  peak_power: { ...photovoltaicSystem.peakPower },
  commissioning_date: photovoltaicSystem.commissioningDate,
  system_name: isMultiplePv ? photovoltaicSystem.systemName : undefined,
});

const mapSinglePvSystemToDto = (
  photovoltaicSystem: SinglePhotovoltaicSystem,
  isMultiplePv = false
) =>
  photovoltaicSystem.type === ConfigurationPvType.SPECIFIC_YIELD
    ? {
        ...getCommonPvSystemDataToDto(photovoltaicSystem, isMultiplePv),
        specific_yield_per_year: { ...photovoltaicSystem.specificYieldPerYear },
      }
    : {
        ...getCommonPvSystemDataToDto(photovoltaicSystem, isMultiplePv),
        inclination: { ...photovoltaicSystem.inclination },
        orientation: { ...photovoltaicSystem.orientation },
      };

export const mapConfigurationToDto = (configuration: LeadConfigurationAttributes) => ({
  total_consumption_per_year: { ...configuration.totalConsumptionPerYear },
  photovoltaic_system: Array.isArray(configuration.photovoltaicSystem)
    ? configuration.photovoltaicSystem.map((pvSystem) => mapSinglePvSystemToDto(pvSystem, true))
    : mapSinglePvSystemToDto(configuration.photovoltaicSystem),
  power_plant: Array.isArray(configuration.photovoltaicSystem)
    ? {
        dso_consent_to_combine_photovoltaic_systems:
          configuration.powerPlant?.dsoConsentToCombinePhotovoltaicSystems,
      }
    : undefined,
  battery: {
    // TODO: replace capacityGross with a proper form name
    id: configuration.battery.capacityGross,
    accessories: configuration.battery.accessories,
  },
  electric_car: configuration.electricCar
    ? {
        total_consumption_per_year: { ...configuration.electricCar?.totalConsumptionPerYear },
        mileage_per_year: { ...configuration.electricCar?.mileagePerYear },
      }
    : undefined,
  heat_pump: configuration.heatPump
    ? {
        total_consumption_per_year: { ...configuration.heatPump?.totalConsumptionPerYear },
      }
    : undefined,
  product_type: configuration.productType,
  product_version: configuration.productVersion,
});

export const mapLeadCustomerType = (lead: Lead): Lead => ({
  ...lead,
  customerType: lead.company ? LeadCustomerType.BUSINESS : LeadCustomerType.PRIVATE,
});

export const getInlineAddress = ({ city = '', street = '', zipCode = '' }: LeadAddress): string => {
  const zipCity = [zipCode, city].filter((value) => !!value).join(' ');
  const address = [street, zipCity].filter((value) => !!value).join(', ');

  return address.length ? address : '';
};

export const isLeadFlatOfferAccepted = (lead: Lead | undefined): boolean =>
  !isNil(lead) && lead.status && lead.status.summary.flatOfferAccepted
    ? lead.status.summary.flatOfferAccepted.isSet
    : false;

export const mapConfigurationToRecommendationDto = (form: ConfigurationForm) => ({
  total_consumption_per_year: {
    value: form.totalConsumptionPerYear,
    unit: EnergyUnit.KWH,
  },
  electric_car: form.eVehicle
    ? {
        total_consumption_per_year: { value: 0, unit: EnergyUnit.KWH },
        mileage_per_year: { value: 0, unit: OtherUnit.KM },
      }
    : undefined,
  heat_pump: form.heatPump
    ? {
        total_consumption_per_year: { value: 0, unit: EnergyUnit.KWH },
      }
    : undefined,
  photovoltaic_system:
    form.pvType === ConfigurationPvType.SPECIFIC_YIELD
      ? {
          type: ConfigurationPvType.SPECIFIC_YIELD,
          specific_yield_per_year: {
            value: form.specificYieldPerYear,
            unit: EnergyUnit.KWH_PER_KWP,
          },
          commissioning_date: form.commissioningDate,
        }
      : {
          type: ConfigurationPvType.DETAILED,
          inclination: {
            value: form.inclination,
            unit: OtherUnit.DEGREE,
          },
          orientation: {
            value: form.orientation,
            unit: OtherUnit.DEGREE,
          },
          commissioning_date: form.commissioningDate,
        },
  battery: {
    model_name: form.modelName,
  },
  product_type: form.productType,
  product_version: form.productVersion,
  recommendation_mode: form.recommendationMode,
});

export const mapAutarkyToDto = (autarky: number) => ({
  power_plant: {
    expected_autarky: {
      value: autarky,
      unit: OtherUnit.PERCENT,
    },
  },
});

export const isSonnenLead = (lead: Lead) => lead.source === LeadSource.SONNEN;

// PRODUCT BATTERIES

export const batteryModelNameMap = (modelName: BatteryModelName) =>
  ({
    [BatteryModelName.SONNENBATTERIE_10_AC]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelAc
    ),
    [BatteryModelName.SONNENBATTERIE_10_DC]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelDc
    ),
    [BatteryModelName.SONNENBATTERIE_ECO_8]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelEco8
    ),
    [BatteryModelName.SONNENBATTERIE_ECO_7]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelEco7
    ),
    [BatteryModelName.SONNENBATTERIE_ECO_6]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelEco6
    ),
    [BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_AC]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelPerformanceAc
    ),
    [BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC]: I18n.t(
      T.lead.configuration._salessolution_.battery.modelPerformanceDc
    ),
  }[modelName]);

// if a battery has 'battery' included in products,
// it means its endOfSalesDate hasn't passed yet and it's still available for sale
export const isBatteryAvailableForSale = (battery: LeadProductBattery) =>
  !!battery.products.find((product) => product.name === LeadProductType.BATTERY);

export const isOrWasBatteryAvailableForSale = (battery: LeadProductBattery) =>
  isNil(battery.startOfSales) || dateUtil.of(battery.startOfSales) <= dateUtil.now();

const splitByKwh = (capacityLabel: string) => capacityLabel.split('kWh');

export const formatLabelToNumber = (capacity: string) =>
  splitByKwh(capacity).length ? parseFloat(splitByKwh(capacity)[0]) : 0;

const formatBatteryModule =
  ({ locale }: { locale: string }) =>
  (module: BatteryModule): string =>
    I18n.t(T.lead.configuration._salessolution_.battery.module[module]);

export const batteriesParametersToLabelMapper =
  (locale: string) => (key: string, productBatteryList: LeadProductBattery[]) => {
    const batteryParameters = searchByKey('id', key, productBatteryList, 'parameters');

    return batteryParametersToLabelMapper(locale)(batteryParameters);
  };

export const batteryParametersToLabelMapper =
  (locale: string) => (batteryParameters: LeadProductBatteryParametersAttribute) => {
    const capacity = formatEnergy({ locale, unit: EnergyUnit.KWH })(
      batteryParameters.capacityInKwh * 1000
    );

    const additionalInfo = [
      ...(batteryParameters.displayPower && !!batteryParameters.powerInKw
        ? [
            formatPower({ locale, unit: EnergyUnit.KW, precision: 1 })(
              batteryParameters.powerInKw * 1000
            ),
          ]
        : []),
      ...(batteryParameters.module
        ? [formatBatteryModule({ locale })(batteryParameters.module)]
        : []),
    ].join(', ');

    return [capacity, ...(additionalInfo ? [`(${additionalInfo})`] : [])].join(' ');
  };
