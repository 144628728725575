import { EnergyProvider, LeadConfigurationResponse } from '+shared/store/lead/types';
import { LeadOffer } from '+shared/store/lead/types/leadOffer.interface';
import { UtilityData } from '+shared/store/lead/types/leadUtilityData.interface';
import { Query } from '+shared/store/query';

export const POST_OFFER_QUERY = 'postOfferQuery';
export const SEND_OFFER_QUERY = 'sendOfferQuery';
export const GET_OFFER_QUERY = 'getOfferQuery';
export const GET_ENERGY_PROVIDER_QUERY = 'getEnergyProviderQuery';
export const GET_UTILITY_DAYA_QUERY = 'getUtilityDataQuery';
export const PATCH_UTILITY_DATA_QUERY = 'patchUtilityDataQuery';

export interface LeadOfferPageState {
  offer?: LeadOffer;
  configuration?: LeadConfigurationResponse;
  energyProviders: EnergyProvider[];
  utilityData?: UtilityData;
  [POST_OFFER_QUERY]: Query<any>;
  [SEND_OFFER_QUERY]: Query<any>;
  [GET_OFFER_QUERY]: Query<any>;
  [GET_ENERGY_PROVIDER_QUERY]: Query<any>;
  [GET_UTILITY_DAYA_QUERY]: Query<any>;
  [PATCH_UTILITY_DATA_QUERY]: Query<any>;
}

export const initialState: LeadOfferPageState = {
  offer: undefined,
  configuration: undefined,
  energyProviders: [],
  utilityData: undefined,
  [POST_OFFER_QUERY]: {},
  [SEND_OFFER_QUERY]: {},
  [GET_OFFER_QUERY]: {},
  [GET_ENERGY_PROVIDER_QUERY]: {},
  [GET_UTILITY_DAYA_QUERY]: {},
  [PATCH_UTILITY_DATA_QUERY]: {},
};
