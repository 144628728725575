import { SortOrder } from '@coolio/json-api';
import { flatten } from 'lodash/fp';

import { createQueryString } from '+app/utils/queryParams.util';
import { CustomerSortKey } from '+shared/store/customer/types/customerSort.type';

export interface RouteQueryParams {
  search?: string;
  sort?: CustomerSortKey;
  page?: string;
  order?: SortOrder;
}

export interface CustomerListRouteQueryParams extends RouteQueryParams {}

export interface SiteListRouteQueryParams extends RouteQueryParams {
  size?: string;
  postalCodes?: string;
  meterPreparationStatuses?: string;
  fstCheckSuccessful?: string;
}

export interface CaseListRouteQueryParams extends RouteQueryParams {
  customerId?: string;
  status?: string;
}
export interface LeadListRouteQueryParams extends RouteQueryParams {}
export interface ContactListRouteQueryParams extends RouteQueryParams {}

export interface NewsInboxListRouteQueryParams {
  newsId?: string;
  newsTitle?: string;
  page?: string;
}

export interface CustomerRouteParams {
  customerId: string;
  siteId?: string;
}

export interface LeadRouteParams {
  leadId: string;
}

export interface LeadOfferRouteParams {
  leadId: string;
  offerId?: string;
}

export const LEAD_IN_SETUP_STAGE = 'inSetup';
export const SETUP_TAB_ROUTE_NAME = 'setup';

export const ROUTES = {
  ROOT: ['/'],
  DASHBOARD: ['/home'],
  NEWS_INBOX: ['/news'],
  CUSTOMERS: ['/customers'],
  CUSTOMERS_OLD: ['/customers-old'],
  CUSTOMER: ['/customers/:customerId/site/:siteId', '/customers/:customerId'],
  CUSTOMER_BATTERY: [
    '/customers/:customerId/site/:siteId/battery',
    '/customers/:customerId/battery',
  ],
  CUSTOMER_HEAT_PUMP: [
    '/customers/:customerId/site/:siteId/heat-pump',
    '/customers/:customerId/heat-pump',
  ],
  CUSTOMER_PV_SYSTEMS: [
    '/customers/:customerId/site/:siteId/pv-systems',
    '/customers/:customerId/pv-systems',
  ],
  CUSTOMER_ANALYSIS: [
    '/customers/:customerId/site/:siteId/analysis',
    '/customers/:customerId/analysis',
  ],
  CUSTOMER_CONTRACT: [
    '/customers/:customerId/site/:siteId/contract',
    '/customers/:customerId/contract',
  ],
  CUSTOMER_CASES: ['/customers/:customerId/site/:siteId/cases', '/customers/:customerId/cases'],
  CASES: ['/cases'],
  LEADS: ['/leads'],
  LEADS_INBOX: ['/leads/inbox'],
  LEAD_NEW: ['/leads/new'],
  LEAD_OVERVIEW: ['/leads/:leadId'],
  LEAD_CONFIGURATION: ['/leads/:leadId/configuration'],
  LEAD_CONFIGURATION_NEW: ['/leads/:leadId/configuration/new'],
  LEAD_CONFIGURATION_NEW_FOR_HW: ['/leads/:leadId/configuration/new/:offerId'],
  LEAD_CONFIGURATION_NEW_FLAT_X: ['/leads/:leadId/configuration/new-flat'],
  LEAD_CONFIGURATION_NEW_FLAT_DIRECT: ['/leads/:leadId/configuration/new-flat-direct'],
  LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW: ['/leads/:leadId/configuration/new-flat/:offerId'],
  LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW: [
    '/leads/:leadId/configuration/new-flat-direct/:offerId',
  ],
  LEAD_HARDWARE_NEW: ['/leads/:leadId/hardware/new'],
  LEAD_OFFER: ['/leads/:leadId/offer/:offerId'],
  LEAD_IMPACT_ANALYSIS: ['/leads/:leadId/offer/:offerId/impact-analysis'],
  LEAD_SETUP_TOOL: ['/leads/:leadId/setup-tool'],
  LEAD_SETUP_TOOL_SETUP_DATA: ['/leads/:leadId/setup-tool/setup-data'],
  LEAD_SETUP_TOOL_METER_PREPARATION: ['/leads/:leadId/setup-tool/meter-preparation'],
  LEAD_SETUP_TOOL_VPP_DOCUMENTATION: ['/leads/:leadId/setup-tool/vpp-documentation'],
  LEAD_SETUP_TOOL_PRE_COMMISSIONING: ['/leads/:leadId/setup-tool/pre-commissioning'],
  SETUP_LEADS: ['/setup'],
  SETUP_LEAD_OVERVIEW: ['/setup/:leadId'],
  SETUP_LEAD_CONFIGURATION: ['/setup/:leadId/configuration'],
  SETUP_LEAD_CONFIGURATION_NEW: ['/setup/:leadId/configuration/new'],
  SETUP_LEAD_CONFIGURATION_NEW_FOR_HW: ['/setup/:leadId/configuration/new/:offerId'],
  SETUP_LEAD_CONFIGURATION_NEW_FLAT_X: ['/setup/:leadId/configuration/new-flat'],
  SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT: ['/setup/:leadId/configuration/new-flat-direct'],
  SETUP_LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW: ['/setup/:leadId/configuration/new-flat/:offerId'],
  SETUP_LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW: [
    '/setup/:leadId/configuration/new-flat-direct/:offerId',
  ],
  SETUP_LEAD_HARDWARE_NEW: ['/setup/:leadId/hardware/new'],
  SETUP_LEAD_OFFER: ['/setup/:leadId/offer/:offerId'],
  SETUP_LEAD_IMPACT_ANALYSIS: ['/setup/:leadId/offer/:offerId/impact-analysis'],
  SETUP_LEAD_SETUP_TOOL: ['/setup/:leadId/setup-tool'],
  SETUP_LEAD_SETUP_TOOL_SETUP_DATA: ['/setup/:leadId/setup-tool/setup-data'],
  SETUP_LEAD_SETUP_TOOL_DSO_REGISTRATION: ['/setup/:leadId/setup-tool/dso-registration'],
  SETUP_LEAD_SETUP_TOOL_DSO_COMMISSIONING: ['/setup/:leadId/setup-tool/dso-commissioning'],
  SETUP_LEAD_SETUP_TOOL_PV_REGISTER: ['/setup/:leadId/setup-tool/pv-register'],
  SETUP_LEAD_SETUP_TOOL_VPP_DOCUMENTATION: ['/setup/:leadId/setup-tool/vpp-documentation'],
  SETUP_LEAD_SETUP_TOOL_PRE_COMMISSIONING: ['/setup/:leadId/setup-tool/pre-commissioning'],
  HELP: ['/help'],
  VIDEOS: ['/help/videos'],
  ACCOUNT: ['/account'],
  DRIVE: ['/drive'],
  ACCOUNT_COMPANY: ['/account/company'],
  LOGOUT: ['/logout'],
  CONTACTS: ['/contacts'],
};

export const PUBLIC_ROUTES = {
  LOGIN: ['/login'],
  REGISTER: ['/register'],
  REGISTER_SUCCESS: ['/register/success'],
  REGISTER_FAILURE: ['/register/failure'],
  DOWNLOAD: ['/download/:file'],
  NEWS_CHANNEL: ['/news-channel'],
};

export const PATHS = {
  NEWS_INBOX: (params?: NewsInboxListRouteQueryParams) =>
    params ? ROUTES.NEWS_INBOX[0] + createQueryString(params) : ROUTES.NEWS_INBOX[0],
  CUSTOMERS: (params?: CustomerListRouteQueryParams) =>
    params ? `/customers/${createQueryString(params)}` : '/customers/',
  CUSTOMERS_OLD: (params?: SiteListRouteQueryParams) =>
    params ? `/customers-old/${createQueryString(params)}` : '/customers-old/',
  CUSTOMER: ({ customerId, siteId }: CustomerRouteParams) =>
    siteId ? `/customers/${customerId}/site/${siteId}` : `/customers/${customerId}`,
  CUSTOMER_BATTERY: ({ customerId, siteId }: CustomerRouteParams) =>
    `${PATHS.CUSTOMER({ customerId, siteId })}/battery`,
  CUSTOMER_HEAT_PUMP: ({ customerId, siteId }: CustomerRouteParams) =>
    `${PATHS.CUSTOMER({ customerId, siteId })}/heat-pump`,
  CUSTOMER_PV_SYSTEMS: ({ customerId, siteId }: CustomerRouteParams) =>
    `${PATHS.CUSTOMER({ customerId, siteId })}/pv-systems`,
  CUSTOMER_ANALYSIS: ({ customerId, siteId }: CustomerRouteParams) =>
    `${PATHS.CUSTOMER({ customerId, siteId })}/analysis`,
  CUSTOMER_CONTRACT: ({ customerId, siteId }: CustomerRouteParams) =>
    `${PATHS.CUSTOMER({ customerId, siteId })}/contract`,
  CUSTOMER_CASES: ({ customerId, siteId }: CustomerRouteParams) =>
    `${PATHS.CUSTOMER({ customerId, siteId })}/cases`,
  CASES: (params?: CaseListRouteQueryParams) =>
    params ? ROUTES.CASES[0] + createQueryString(params) : ROUTES.CASES[0],
  LEADS: (params?: LeadListRouteQueryParams) =>
    params ? ROUTES.LEADS[0] + createQueryString(params) : ROUTES.LEADS[0],
  LEADS_INBOX: (params?: LeadListRouteQueryParams) =>
    params ? ROUTES.LEADS_INBOX[0] + createQueryString(params) : ROUTES.LEADS_INBOX[0],
  LEAD_NEW: () => ROUTES.LEAD_NEW[0],
  LEAD_OVERVIEW: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    leadStage === LEAD_IN_SETUP_STAGE
      ? `${ROUTES.SETUP_LEADS[0]}/${leadId}`
      : `${ROUTES.LEADS[0]}/${leadId}`,
  LEAD_CONFIGURATION: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration`,

  LEAD_CONFIGURATION_NEW: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration/new`,
  LEAD_CONFIGURATION_NEW_FOR_HW: ({ leadId, offerId }: LeadOfferRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration/new/${offerId}`,

  LEAD_CONFIGURATION_NEW_FLAT_X: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration/new-flat`,
  LEAD_CONFIGURATION_NEW_FLAT_DIRECT: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration/new-flat-direct`,
  LEAD_CONFIGURATION_NEW_FLAT_X_FOR_HW: (
    { leadId, offerId }: LeadOfferRouteParams,
    leadStage?: string
  ) => `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration/new-flat/${offerId}`,
  LEAD_CONFIGURATION_NEW_FLAT_DIRECT_FOR_HW: (
    { leadId, offerId }: LeadOfferRouteParams,
    leadStage?: string
  ) => `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/configuration/new-flat-direct/${offerId}`,
  LEAD_HARDWARE_NEW: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/hardware/new`,
  LEAD_IMPACT_ANALYSIS: ({ leadId, offerId }: LeadOfferRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/offer/${offerId}/impact-analysis`,
  SETUP_LEADS: (params?: LeadListRouteQueryParams) =>
    params ? ROUTES.SETUP_LEADS[0] + createQueryString(params) : ROUTES.SETUP_LEADS[0],
  SETUP_TOOL: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/setup-tool`,
  SETUP_TOOL_SETUP_DATA: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.SETUP_TOOL({ leadId }, leadStage)}/setup-data`,
  SETUP_TOOL_DSO_REGISTRATION: ({ leadId }: LeadRouteParams) =>
    `${PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)}/dso-registration`,
  SETUP_TOOL_DSO_COMMISSIONING: ({ leadId }: LeadRouteParams) =>
    `${PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)}/dso-commissioning`,
  SETUP_TOOL_PV_REGISTER: ({ leadId }: LeadRouteParams) =>
    `${PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)}/pv-register`,
  SETUP_TOOL_VPP_DOCUMENTATION: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.SETUP_TOOL({ leadId }, leadStage)}/vpp-documentation`,
  SETUP_TOOL_PRE_COMMISSIONING: ({ leadId }: LeadRouteParams, leadStage?: string) =>
    `${PATHS.SETUP_TOOL({ leadId }, leadStage)}/pre-commissioning`,
  LEAD_OFFER: ({ leadId, offerId }: LeadOfferRouteParams, leadStage?: string) =>
    offerId
      ? `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/offer/${offerId}`
      : `${PATHS.LEAD_OVERVIEW({ leadId }, leadStage)}/offer`,
  DASHBOARD: () => ROUTES.DASHBOARD[0],
  ACCOUNT: () => ROUTES.ACCOUNT[0],
  DRIVE: () => ROUTES.DRIVE[0],
  ACCOUNT_COMPANY: () => ROUTES.ACCOUNT_COMPANY[0],
  HELP: (section?: string) => `${ROUTES.HELP[0]}${section ? `#${section}` : ''}`,
  VIDEOS: () => ROUTES.VIDEOS[0],
  CONTACTS: (params?: ContactListRouteQueryParams) =>
    params ? ROUTES.CONTACTS[0] + createQueryString(params) : ROUTES.CONTACTS[0],
  NEWS_CHANNEL: () => PUBLIC_ROUTES.NEWS_CHANNEL[0],
  LOGOUT: () => ROUTES.LOGOUT[0],
  REGISTER: () => PUBLIC_ROUTES.REGISTER[0],
  REGISTER_SUCCESS: () => PUBLIC_ROUTES.REGISTER_SUCCESS[0],
  REGISTER_FAILURE: () => PUBLIC_ROUTES.REGISTER_FAILURE[0],
};

export const RESTRICTED_PATHS = flatten(Object.values(ROUTES));
