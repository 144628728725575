import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { sonnenDriveLink } from '+drive/containers/SonnenDrive.helper';
import { Button, ButtonSize } from '+shared/components/Button';

import './SonnenDriveMoreInfo.component.scss';

export const SonnenDriveMoreInfo: React.FC = () => (
  <div className={'c-sonnen-drive__cta'}>
    <p className={'c-sonnen-drive__cta-header'}>{I18n.t(T.drive.more.title)}</p>
    <div className={'c-sonnen-drive__cta-button'}>
      <Button
        label={I18n.t(T.drive.more.button)}
        size={ButtonSize.LARGE}
        onClick={() => window.open(sonnenDriveLink, '_blank')}
      />
    </div>
  </div>
);
