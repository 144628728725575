import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { Account } from '+app/+account';
import { CaseList } from '+app/+case';
import { Contact } from '+app/+contact';
import { Customer } from '+app/+customer';
import SitesPage from '+app/+customer/+listNew/SitesPage';
import Dashboard from '+app/+dashboard/Dashboard/Dashboard';
import { Drive } from '+app/+drive';
import { HelpPage } from '+app/+help';
import { Lead, SetupLead } from '+app/+lead';
import { NewsChannel } from '+app/+newsChannel';
import { NewsInbox } from '+app/+newsMain/+newsInbox';
import { Register, RegisterFailure, RegisterSuccess } from '+app/+register';
import { Download, Login, Logout, NotFound } from '+app/+static';
import { CountryFeatureName } from '+config/countryFlags';
import { CustomerList } from '+customer/+list';
import { Loader } from '+shared/basicComponents/Loader/Loader';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { FirebaseSubscribe } from '+shared/containers/FirebaseSubscribe';
import { isAuthenticated } from '+shared/store/auth/auth.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';

import { PUBLIC_ROUTES, ROUTES } from './routes';

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: isAuthenticated(state),
  userProfile: getUserProfile(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const AuthenticatedRouting: React.FC<Props> = ({ userProfile }) => {
  const { customerListNew, customerListOld, spanishMarket } = useFlags();

  if (!userProfile) {
    return <Loader />;
  }

  return (
    <>
      <FirebaseSubscribe isSpanishMarketEnabled={spanishMarket} />
      <Switch>
        <Route exact={true} path={ROUTES.ROOT}>
          <Redirect to={ROUTES.DASHBOARD[0]} />
        </Route>
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.NEWS_INBOX} component={NewsInbox} />
        <Route
          exact={true}
          path={ROUTES.CUSTOMERS}
          component={customerListNew ? SitesPage : CustomerList}
        />
        {/* 
          This route preserves the old customer list for a short while after go live.
          It uses the launch darkly feature flag which was previously used for the new list.
        */}
        {customerListOld && (
          <Route exact={true} path={ROUTES.CUSTOMERS_OLD} component={CustomerList} />
        )}
        <Route path={ROUTES.CUSTOMER} component={Customer} />
        <Route path={ROUTES.CASES} component={CaseList} />
        {isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.LEAD_PHASE) && (
          <Route path={[ROUTES.LEADS[0]]} component={Lead} />
        )}
        {isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE) && (
          <Route path={[ROUTES.SETUP_LEADS[0]]} component={SetupLead} />
        )}
        <Route path={ROUTES.HELP} component={HelpPage} />
        <Route path={ROUTES.ACCOUNT} component={Account} />
        <Route path={ROUTES.LOGOUT} component={Logout} />
        <Route path={ROUTES.CONTACTS} component={Contact} />
        {isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SONNEN_DRIVE) && (
          <Route path={ROUTES.DRIVE} component={Drive} />
        )}
        <Route path={'*'} component={NotFound} />
      </Switch>
    </>
  );
};

const PublicRouting: React.FC = () => (
  <Switch>
    <Route exact={true} path={PUBLIC_ROUTES.REGISTER} component={Register} />
    <Route path={PUBLIC_ROUTES.REGISTER_FAILURE} component={RegisterFailure} />
    <Route path={PUBLIC_ROUTES.REGISTER_SUCCESS} component={RegisterSuccess} />
    <Route path={PUBLIC_ROUTES.DOWNLOAD} component={Download} />
    <Route path={PUBLIC_ROUTES.LOGIN} component={Login} />
    <Route path={PUBLIC_ROUTES.NEWS_CHANNEL} component={NewsChannel} />
    <Route path={ROUTES.LOGOUT} component={Logout} />
    <Route component={NotFound} />
  </Switch>
);

const RoutingComponent: React.FC<Props> = (props) =>
  props.isAuthenticated ? <AuthenticatedRouting {...props} /> : <PublicRouting />;

export const Routing = connect(mapStateToProps)(RoutingComponent);
