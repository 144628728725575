import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isEmpty } from 'lodash';
import { isNil } from 'lodash/fp';

import {
  getAllFlatOffers,
  getFlatProduct,
  getHardwareProduct,
  isFlatOnlyOffer,
} from '+app/+lead/+offer/store/+offer.helper';
import { LeadConfigurationTileStatus } from '+app/+lead/components/LeadConfigurationTile';
import {
  hasFlatOfferAcceptedStatus,
  isLeadSonnenNowInterested,
} from '+app/+lead/store/+lead.helper';
import {
  Lead,
  LeadOffer,
  LeadProductAvailability,
  LeadProductType,
  OfferProductStatus,
  OfferStatus,
} from '+shared/store/lead/types';
import { LeadProductVersion } from '+shared/store/lead/types/leadProductVersion.interface';

import { isDsoBlackListed, isProductUnavailableButFound } from '../../store/+overview.helper';

export const MAX_ALLOWED_CONFIGURATION_COUNT = 3;

export const isMaxAmountOfOffersCreated = (count: number): boolean =>
  count < MAX_ALLOWED_CONFIGURATION_COUNT;

const isLastOffer = (offers: LeadOffer[], offerId: string) =>
  offers.length === 1 && offers[0].id === offerId;

const getSentFlatOffers = (offers: LeadOffer[]) => {
  const flatOffers = getAllFlatOffers(offers);
  if (isEmpty(flatOffers)) {
    return [];
  }

  return flatOffers.filter((offer) =>
    [OfferProductStatus.SENT].includes(getFlatProduct(offer)!.status)
  );
};

export const isLastFlatSentOffer = (offerId: string, offers: LeadOffer[]) => {
  const sentFlatOffers = getSentFlatOffers(offers);
  return isLastOffer(sentFlatOffers, offerId);
};

const getSentHardwareOnlyOffers = (offers: LeadOffer[]) => {
  const hwOnlyOffers = offers.filter((offer) => getHardwareProduct(offer));
  if (isEmpty(hwOnlyOffers)) {
    return [];
  }

  return hwOnlyOffers.filter((offer) =>
    [OfferProductStatus.SENT].includes(getHardwareProduct(offer)!.status)
  );
};

export const isLastHardwareOnlySentOffer = (offerId: string, offers: LeadOffer[]) => {
  const sentHwOnlyOffers = getSentHardwareOnlyOffers(offers);
  return isLastOffer(sentHwOnlyOffers, offerId);
};

export const isLastFlatOnlyOffer = (offerId: string, offers: LeadOffer[]): boolean => {
  const flatOnlyOffers = offers.filter((offer) => isFlatOnlyOffer(offer));

  return isLastOffer(flatOnlyOffers, offerId);
};

export const mapOfferStatus = (
  status: OfferProductStatus | undefined,
  isOfferInvalid?: boolean
): LeadConfigurationTileStatus => {
  if (isOfferInvalid) {
    return LeadConfigurationTileStatus.INVALID;
  }
  switch (status) {
    case OfferProductStatus.DRAFT:
      return LeadConfigurationTileStatus.PREVIEW;
    case OfferProductStatus.SENT:
      return LeadConfigurationTileStatus.SENT;
    case OfferProductStatus.ACCEPTED:
      return LeadConfigurationTileStatus.ACCEPTED;
    case OfferProductStatus.CONFIRMED:
      return LeadConfigurationTileStatus.CONFIRMED;
    case OfferProductStatus.DRAFT_EXPIRED:
    case OfferProductStatus.SENT_EXPIRED:
      return LeadConfigurationTileStatus.EXPIRED;
    default:
      return LeadConfigurationTileStatus.PREVIEW;
  }
};

export const isProductUnavailableButFoundForLead = (
  lead: Lead,
  availableDsos: LeadProductAvailability[],
  productType: LeadProductType,
  productVersion: LeadProductVersion
): boolean => {
  const dso: LeadProductAvailability | undefined = availableDsos.find(
    (dso) => dso.id === lead.dso?.id
  );
  return !!dso && isProductUnavailableButFound(dso, productType, productVersion);
};

export const isInvalidSonnenDirectOffer = (
  offer: LeadOffer,
  lead: Lead,
  productAvailability: LeadProductAvailability[]
): boolean => {
  const isFlatDirectProduct = offer.products.some(
    (product) => product.productType === LeadProductType.FLAT_DIRECT
  );
  if (!isFlatDirectProduct || isEmpty(productAvailability)) {
    return false;
  }

  return isProductUnavailableButFoundForLead(
    lead,
    productAvailability,
    LeadProductType.FLAT_DIRECT,
    LeadProductVersion.V_1_0
  );
};

export const configurationWarningMap = (
  lead: Lead | undefined,
  productAvailability: LeadProductAvailability[]
) => [
  {
    condition: !!lead && isLeadSonnenNowInterested(lead),
    msg: I18n.t(T.lead.configuration._salessolution_.sonnenNow.description),
  },
  {
    condition: !hasFlatOfferAcceptedStatus(lead) && isNil(lead?.dso),
    msg: I18n.t(T.lead.configuration._salessolution_.noDsoWarning),
  },
  {
    condition: isDsoBlackListed(productAvailability, lead?.dso),
    msg: I18n.t(T.lead.configuration._salessolution_.blacklistedDsoWarning),
  },
];

export const isOfferBlocked = (offer: LeadOffer) => offer.status === OfferStatus.BLOCKED;
