import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph, WizardContext } from '@sonnen/shared-web';

import { Form, Formik, FormikProps } from 'formik';

import { DsoRegistrationDocumentsTestIds as testIds } from '+config/testIds';
import { getLeadData } from '+lead/+overview/store/+overview.selectors';
import { DsoRegistrationFormContainer } from '+setupTool/+form/components';
import {
  DsoRegistrationDocumentsFiles,
  DsoRegistrationDocumentsInitial,
  DsoRegistrationDocumentsSchema,
} from '+setupTool/+form/store/schemas';
import { DsoRegistrationDocumentsInterface } from '+setupTool/+form/store/types';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import {
  SetupDataStepKey,
  SetupDataStepStatus,
  SubmissionStep,
} from '+setupTool/store/+setupTool.dictionary';
import { removeFile, sendFile } from '+setupTool/store/+setupTool.helpers';
import { getRegistrationSubject, getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { FormCheckbox, FormUploadInput, FormWizardStep } from '+shared/components';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux/mapActions.util';

import { DsoRegistrationFormActions } from '../../store/+form.actions';
import { FileCategoryName } from '../../store/+form.dictionary';
import { setAllFormFieldsTouched } from '../../store/+form.helpers';
import {
  getActiveStep,
  getAdditionalDocumentSubmitQueryStatus,
  getDocumentsDataForm,
  getSitePlanDocumentSubmitQueryStatus,
  getUploadedDocuments,
  getWiringDiagramDocumentSubmitQueryStatus,
} from '../../store/+form.selectors';

import './DsoRegistrationDocuments.component.scss';

interface OwnProps {
  stepStatus?: SetupDataStepStatus;
}

const mapStateToProps = (state: StoreState) => ({
  additionalDocumentSubmitQueryStatus: getAdditionalDocumentSubmitQueryStatus(state),
  sitePlanDocumentSubmitQueryStatus: getSitePlanDocumentSubmitQueryStatus(state),
  wiringDiagramDocumentSubmitQueryStatus: getWiringDiagramDocumentSubmitQueryStatus(state),
  documentsDataForm: getDocumentsDataForm(state),
  lead: getLeadData(state),
  submissionId: getSubmissionId(state),
  userId: getUserProfileId(state),
  uploadedDocuments: getUploadedDocuments(state),
  activeStep: getActiveStep(state),
  registrationSubject: getRegistrationSubject(state),
});

const mapDispatchToProps = mapActions({
  setDsoDocumentsFile: DsoRegistrationFormActions.setDsoDocumentsFile,
  sendDsoDocumentsFile: DsoRegistrationFormActions.sendDsoDocumentsFile,
  checkForm: DsoRegistrationFormActions.checkFormFulfillment,
  saveData: SetupToolActions.saveSubmission,
  removeDsoDocumentsFile: DsoRegistrationFormActions.removeDsoDocumentsFile,
  setActiveStep: DsoRegistrationFormActions.setActiveStep,
  setSetupDataStatuses: DsoRegistrationFormActions.setSetupDataStatuses,
  toggleModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const DsoRegistrationDocumentsComponent: React.FC<Props> = ({
  actions,
  documentsDataForm,
  additionalDocumentSubmitQueryStatus,
  sitePlanDocumentSubmitQueryStatus,
  wiringDiagramDocumentSubmitQueryStatus,
  lead,
  submissionId,
  userId,
  uploadedDocuments,
  activeStep,
  stepStatus,
  registrationSubject,
}) => {
  actions.toggleModal(true);
  const wizard = React.useContext(WizardContext);
  const formRef = React.useRef<FormikProps<DsoRegistrationDocumentsInterface>>({} as any);
  const validationSchema = DsoRegistrationDocumentsSchema();

  React.useEffect(() => {
    actions.setActiveStep(SubmissionStep.SETUP_DATA_UPLOAD_DOCUMENTS);
    activeStep = SubmissionStep.SETUP_DATA_UPLOAD_DOCUMENTS;

    return saveValues;
  }, []);

  React.useEffect(() => {
    const values = {
      ...formRef.current.values,
      ...DsoRegistrationDocumentsFiles.reduce((prev, category) => {
        const documents = uploadedDocuments[category];
        const documentsPreparedData = documents && [uploadedDocuments[category]];

        return { ...prev, [category]: documentsPreparedData };
      }, {}),
    };

    formRef.current.setValues(values);
  }, [uploadedDocuments]);

  React.useEffect(() => {
    let values = formRef.current.values;

    if (documentsDataForm) {
      values = documentsDataForm;
    }

    values = {
      ...values,
      ...DsoRegistrationDocumentsFiles.reduce((prev, category) => {
        const documents = uploadedDocuments[category];
        const documentsPreparedData = documents && [uploadedDocuments[category]];

        return { ...prev, [category]: documentsPreparedData };
      }, {}),
    };

    formRef.current.setValues(values);

    if (stepStatus === SetupDataStepStatus.STARTED) {
      setAllFormFieldsTouched(formRef.current, DsoRegistrationDocumentsFiles);
    }
  }, [documentsDataForm]);

  const onSubmit = () => {
    if (wizard.next) {
      wizard.next();
      document.body.classList.add('body--disabled');
    }
  };

  const saveValues = () => {
    const values = formRef.current.values;
    const atLeastOneFieldTouched = Object.keys(formRef.current.touched).length > 0;
    const invalidFieldKeys = Object.keys(formRef.current.errors);
    const newStepStatus =
      invalidFieldKeys.length === 0 ? SetupDataStepStatus.COMPLETED : SetupDataStepStatus.STARTED;

    if (
      atLeastOneFieldTouched &&
      documentsDataForm &&
      documentsDataForm.standardSolarCircuitDiagram !== values.standardSolarCircuitDiagram
    ) {
      actions.setSetupDataStatuses({ uploadDocuments: newStepStatus });
      actions.setDsoDocumentsFile(values);
      actions.checkForm(registrationSubject);
      actions.saveData(activeStep, { [SetupDataStepKey.UPLOAD_DOCUMENTS]: newStepStatus });
    } else if (
      atLeastOneFieldTouched &&
      (newStepStatus !== stepStatus || newStepStatus === SetupDataStepStatus.COMPLETED)
    ) {
      actions.setSetupDataStatuses({ uploadDocuments: newStepStatus });
      actions.setDsoDocumentsFile(values);
      actions.checkForm(registrationSubject);
      actions.saveData(activeStep, { [SetupDataStepKey.UPLOAD_DOCUMENTS]: newStepStatus });
    }
  };

  return (
    <Formik
      initialValues={DsoRegistrationDocumentsInitial}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      innerRef={formRef}
      render={(form) => (
        <Form className="c-form-wizard__form">
          <FormWizardStep
            onBeforeSubmit={() => {
              // Enable saving partially complete data
              onSubmit();
            }}
          >
            <DsoRegistrationFormContainer>
              <BoldParagraph className="c-setup-tool__upload-input-label">
                {I18n.t(T.setupTool.form.sitePlan)}
              </BoldParagraph>
              <FormUploadInput
                form={form}
                dataTestId={testIds.sitePlan}
                name={FileCategoryName.SITE_PLAN}
                accept={['application/pdf']}
                maxSize={20971520}
                maxSizeLabel="20 MB"
                label={I18n.t(T.setupTool.info.chooseFile)}
                placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                  extension: 'PDF',
                  maxFileSize: '20 MB',
                })}
                isImmediatelySentToBackend={true}
                showErrorOnlyIfTouched={true}
                onUpload={(file) => {
                  if (lead) {
                    formRef.current.setTouched({ [FileCategoryName.SITE_PLAN]: true });
                    formRef.current.setFieldError(FileCategoryName.SITE_PLAN, '');
                    sendFile(
                      file,
                      FileCategoryName.SITE_PLAN,
                      userId,
                      lead.id,
                      submissionId,
                      actions.sendDsoDocumentsFile
                    );
                  }
                }}
                isLoading={sitePlanDocumentSubmitQueryStatus.pending}
                onReset={(documentId) => {
                  if (documentId) {
                    formRef.current.setTouched({ [FileCategoryName.SITE_PLAN]: true });
                    removeFile(documentId, submissionId, actions.removeDsoDocumentsFile);
                  }
                }}
              />
              <BoldParagraph className="c-setup-tool__upload-input-label">
                {I18n.t(T.setupTool.form.wiringDiagram)}
              </BoldParagraph>
              <FormUploadInput
                className={`c-form-upload-input--with-checkbox ${
                  form.values.standardSolarCircuitDiagram
                    ? 'c-form-upload-input--collapsed'
                    : 'c-form-upload-input--expanded'
                }`}
                disabled={form.values.standardSolarCircuitDiagram}
                form={form}
                dataTestId={testIds.wiringDiagram}
                name={FileCategoryName.WIRING_DIAGRAM}
                accept={['application/pdf']}
                maxSize={20971520}
                maxSizeLabel="20 MB"
                label={I18n.t(T.setupTool.info.chooseFile)}
                placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                  extension: 'PDF',
                  maxFileSize: '20 MB',
                })}
                isImmediatelySentToBackend={true}
                showErrorOnlyIfTouched={true}
                onUpload={(file) => {
                  if (lead) {
                    formRef.current.setTouched({ [FileCategoryName.WIRING_DIAGRAM]: true });
                    formRef.current.setFieldError(FileCategoryName.WIRING_DIAGRAM, '');
                    sendFile(
                      file,
                      FileCategoryName.WIRING_DIAGRAM,
                      userId,
                      lead.id,
                      submissionId,
                      actions.sendDsoDocumentsFile
                    );
                  }
                }}
                isLoading={wiringDiagramDocumentSubmitQueryStatus.pending}
                onReset={(documentId) => {
                  if (document) {
                    formRef.current.setTouched({ [FileCategoryName.WIRING_DIAGRAM]: true });
                    removeFile(documentId, submissionId, actions.removeDsoDocumentsFile);
                  }
                }}
              />
              <div className="c-dso-registration-documents__checkbox">
                <FormCheckbox
                  form={form}
                  label={I18n.t(T.setupTool.form.uploadDocumentsCheckboxLabel)}
                  name={FileCategoryName.STANDARD_SOLAR_CIRCUIT_DIAGRAM}
                />
              </div>
              <BoldParagraph className="c-setup-tool__upload-input-label">
                {I18n.t(T.setupTool.form.additionalDocuments)} /" "
                <span>{I18n.t(T.setupTool.form.optional)}</span>
              </BoldParagraph>
              <FormUploadInput
                form={form}
                dataTestId={testIds.additionalDocuments}
                name={FileCategoryName.ADDITIONAL_DOCUMENTS}
                accept={['application/pdf']}
                maxSize={20971520}
                maxSizeLabel="20 MB"
                label={I18n.t(T.setupTool.info.chooseFile)}
                placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                  extension: 'PDF',
                  maxFileSize: '20 MB',
                })}
                isImmediatelySentToBackend={true}
                showErrorOnlyIfTouched={true}
                onUpload={(file) => {
                  if (lead) {
                    formRef.current.setTouched({ [FileCategoryName.ADDITIONAL_DOCUMENTS]: true });
                    formRef.current.setFieldError(FileCategoryName.ADDITIONAL_DOCUMENTS, '');
                    sendFile(
                      file,
                      FileCategoryName.ADDITIONAL_DOCUMENTS,
                      userId,
                      lead.id,
                      submissionId,
                      actions.sendDsoDocumentsFile
                    );
                  }
                }}
                isLoading={additionalDocumentSubmitQueryStatus.pending}
                onReset={(documentId) => {
                  if (documentId) {
                    formRef.current.setTouched({ [FileCategoryName.ADDITIONAL_DOCUMENTS]: true });
                    removeFile(documentId, submissionId, actions.removeDsoDocumentsFile);
                  }
                }}
              />
            </DsoRegistrationFormContainer>
          </FormWizardStep>
        </Form>
      )}
    />
  );
};

export const DsoRegistrationDocuments = connect(
  mapStateToProps,
  mapDispatchToProps
)(DsoRegistrationDocumentsComponent);
