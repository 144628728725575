import { isEmpty } from 'lodash';

import {
  LeadOffer,
  LeadOfferProduct,
  LeadProductType,
  OfferProductStatus,
} from '+shared/store/lead/types';

export const getFlatProduct = (offer: LeadOffer): LeadOfferProduct | undefined =>
  offer.products.find((product) =>
    [LeadProductType.FLAT_X, LeadProductType.FLAT_DIRECT].includes(product.productType)
  );

export const getHardwareProduct = (offer: LeadOffer): LeadOfferProduct | undefined =>
  offer.products.find((product) => product.productType === LeadProductType.BATTERY);

export const isProductSold = (product: LeadOfferProduct) =>
  [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(product.status);

export const getAllFlatOffers = (offers: LeadOffer[]): LeadOffer[] =>
  offers.filter((offer) => getFlatProduct(offer));

export const getProductForStatusChange = (offer: LeadOffer): LeadOfferProduct | undefined => {
  const flatProduct = getFlatProduct(offer);

  if (!flatProduct) {
    return getHardwareProduct(offer);
  }

  return flatProduct;
};

export const getOfferProductStatus = (
  offer: LeadOffer,
  productType: LeadProductType
): OfferProductStatus | undefined =>
  offer.products.find((product) => product.productType === productType)?.status;

export const isHardwareStatusConfirmed = (offer: LeadOffer): boolean =>
  getHardwareProduct(offer)?.status === OfferProductStatus.CONFIRMED;

export const isFlatOnlyOffer = (offer: LeadOffer): boolean =>
  !!getFlatProduct(offer) && !getHardwareProduct(offer);

export const isAnyOfferFlatOnly = (offers: LeadOffer[]): boolean =>
  !isEmpty(offers.filter(isFlatOnlyOffer));

export const isAnyFlatFromBundleSold = (offers: LeadOffer[]): boolean =>
  offers.some((offer) => {
    const flatProduct = getFlatProduct(offer);
    return getHardwareProduct(offer) && flatProduct && isProductSold(flatProduct);
  });

/**
 * @description beware that this helper returns the first offer it finds since
 * productId is NOT UNIQUE!
 * in cases of two offers with the same hardware additional checks might need to be performed
 * (e.g. whether the offer was accepted)
 */

export const getOfferIdFromProductId = (
  productId: string,
  offers: LeadOffer[]
): string | undefined =>
  offers.find((offer) => getHardwareProduct(offer)?.productId === productId)?.id;

export const isFlatOfferAccepted = (offer: LeadOffer): boolean =>
  getFlatProduct(offer)
    ? [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(
        getFlatProduct(offer)?.status as OfferProductStatus
      )
    : false;

export const isSentOrDraftOfferExpired = (offer: LeadOffer): boolean =>
  getFlatProduct(offer)
    ? [OfferProductStatus.DRAFT_EXPIRED, OfferProductStatus.SENT_EXPIRED].includes(
        getFlatProduct(offer)?.status as OfferProductStatus
      )
    : false;

export const isAnyFlatOfferAccepted = (offers: LeadOffer[]): boolean =>
  offers.some(isFlatOfferAccepted);
