import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Form, Formik } from 'formik';
import { noop } from 'lodash/fp';
import * as uuid from 'uuid';

import { ActionBoxColors } from '+app/+lead/store/types';
import {
  LeadConfigurationTile,
  LeadConfigurationTileProps,
  LeadConfigurationTileStatus,
} from '+lead/components';
import { LabelLarge, LabelMedium } from '+shared/basicComponents/Typography/Labels';
import { FormInputRadioGroup } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { LeadOffer, LeadProductType } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { useLocaleContext } from '+utils/react/locale.provider';

import { LeadConfigurationActionBox } from '../LeadConfigurationActionBox';
import { LeadConfigurationLine } from '../LeadConfigurationLine';
import {
  mapConfigurationTileStatusForHardwareOffer,
  renderStatus,
} from '../LeadConfigurationTile/LeadConfigurationTile.helper';
import { factorizeBatteryDetails } from './LeadHardwareConfigurationTile.helper';
import {
  defaultValues,
  SonnenTariffForm,
  SonnenTariffFormKeys,
} from './LeadHardwareConfigurationTile.type';

import './LeadHardwareConfigurationTile.component.scss';

interface OwnProps {
  battery: LeadProductBattery;
  isTileActionDisabled: boolean;
  isStatusUpdateDisabled?: boolean;
  addSonnenFlatXTariff: () => void;
  addSonnenFlatDirectTariff: () => void;
  isAddFlatDisabled: boolean;
  onHardwareStatusModalOpen?: (event: React.MouseEvent) => void;
  offer: LeadOffer;
}

type Props = Omit<
  LeadConfigurationTileProps,
  'isOfferCreationDisabled' | 'creationDate' | 'offerButtonLabel' | 'productName'
> &
  OwnProps;

export const LeadHardwareConfigurationTile: React.FC<Props> = ({
  battery,
  isTileActionDisabled,
  status = LeadConfigurationTileStatus.PREVIEW,
  isStatusUpdateDisabled = false,
  isAddFlatDisabled,
  onHardwareStatusModalOpen,
  addSonnenFlatXTariff,
  addSonnenFlatDirectTariff,
  offer,
  ...restTileProps
}) => {
  const { createdAt, id: offerId } = offer;
  const { locale } = useLocaleContext();
  const sonnenBatteryDetails = factorizeBatteryDetails(locale)(battery);
  const mappedHardwareStatus = mapConfigurationTileStatusForHardwareOffer(status);

  return (
    <>
      <LeadConfigurationTile
        creationDate={createdAt}
        isTileActionDisabled={isTileActionDisabled}
        productName={I18n.t(T.lead._salessolution_.configurations.product.hardwareWithoutContract)}
        status={mappedHardwareStatus} // @Future: change place of displaying status
        {...restTileProps}
        offerId={offerId}
      >
        <LeadConfigurationActionBox
          mainColor={ActionBoxColors.BLUE}
          hoverable={false}
          hasLeftBorder={true}
          disabled={isAddFlatDisabled}
        >
          <Formik initialValues={defaultValues} onSubmit={noop}>
            {(form) => (
              <Form>
                <p className={'c-lead-hardware-configuration-tile__header-headline'}>
                  {I18n.t(T.lead.hardware._salessolution_.configurationTile.addTariff.headline)}
                </p>
                <p className={'c-lead-hardware-configuration-tile__header-description'}>
                  {I18n.t(T.lead.hardware._salessolution_.configurationTile.addTariff.select)}
                </p>
                <div className={'c-lead-hardware-configuration-tile__radio'}>
                  <FormInputRadioGroup<Pick<SonnenTariffForm, 'productType'>>
                    className={'c-battery-operating-mode-operation__radio-group'}
                    name={SonnenTariffFormKeys.PRODUCT_TYPE}
                    form={form}
                    collection={[
                      {
                        label: I18n.t(
                          T.lead.hardware._salessolution_.configurationTile.addTariff.selectFlatX
                        ),
                        value: LeadProductType.FLAT_X,
                        id: uuid(),
                      },
                      {
                        label: I18n.t(
                          T.lead.hardware._salessolution_.configurationTile.addTariff
                            .selectFlatDirect
                        ),
                        value: LeadProductType.FLAT_DIRECT,
                        id: uuid(),
                      },
                    ]}
                    disabled={isAddFlatDisabled}
                  />
                </div>
                <div className={'c-lead-hardware-configuration-tile__tariff-button'}>
                  <Button
                    className={`c-inbox-lead-list-table-item__cta`}
                    size={ButtonSize.MEDIUM}
                    label={I18n.t(
                      T.lead.hardware._salessolution_.configurationTile.addTariff.button
                    )}
                    type={ButtonType.SECONDARY}
                    disabled={isAddFlatDisabled}
                    status={isAddFlatDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
                    onClick={
                      form.values.productType === LeadProductType.FLAT_X
                        ? addSonnenFlatXTariff
                        : addSonnenFlatDirectTariff
                    }
                    dataTestId="leads-inbox-accept-lead-btn"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </LeadConfigurationActionBox>

        <div className={'c-lead-hardware-configuration-tile'}>
          <div className={'c-lead-hardware-configuration-tile__header'}>
            <div className={'c-lead-hardware-configuration-tile__header-status-wrapper'}>
              <LabelLarge
                text={I18n.t(T.lead.hardware._salessolution_.configurationTile.headline.hardware)}
              />
              {renderStatus(mappedHardwareStatus)}
            </div>
          </div>
          <div className={'c-lead-hardware-configuration-tile__details'}>
            <LabelMedium
              text={I18n.t(
                T.lead.hardware._salessolution_.configurationTile.subheadline.sonnenBatterie
              )}
            />
            {sonnenBatteryDetails.map(({ title, value, type }, index) => (
              <LeadConfigurationLine
                title={title}
                value={value}
                type={type}
                key={`detail-${index}`}
              />
            ))}
          </div>
          <div className={'c-lead-hardware-configuration-tile__status-button-wrapper'}>
            <Button
              label={I18n.t(T.lead.hardware._salessolution_.form.statusChange.button.updateStatus)}
              onClick={onHardwareStatusModalOpen}
              size={ButtonSize.SMALL}
              type={ButtonType.SECONDARY}
              disabled={isStatusUpdateDisabled}
              status={isStatusUpdateDisabled ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
            />
          </div>
        </div>
      </LeadConfigurationTile>
    </>
  );
};
