import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';

export interface CarouselSlide {
  icon: IconType;
  text: string;
  arrow?: boolean;
}

export const getSlides = (): CarouselSlide[] => [
  {
    icon: 'CarMinivan',
    text: I18n.t(T.drive.process.car),
    arrow: true,
  },
  {
    icon: 'Key',
    text: I18n.t(T.drive.process.key),
    arrow: true,
  },
  {
    icon: 'SonnenDrive',
    text: I18n.t(T.drive.process.drive),
  },
];
