import { LeadProductType } from '+shared/store/lead/types';

export interface SonnenTariffForm {
  productType: LeadProductType.FLAT_DIRECT | LeadProductType.FLAT_X;
}

export const defaultValues: SonnenTariffForm = {
  productType: LeadProductType.FLAT_X,
};

export enum SonnenTariffFormKeys {
  PRODUCT_TYPE = 'productType',
}
