import { VideoYoutube } from '+app/shared/store/youtube/types/youtube.interface';
import { Market } from '+utils/market.util';

export interface Video extends VideoYoutube {
  id: string;
  youtubeId: string;
  categories: CategoryName[];
  markets: Market[];
}

export interface VideoFirebase {
  id: string;
  youtubeId: string;
  categories: CategoryName[];
  markets: Market[];
}

export interface SelectCategory {
  label: string;
  hash: string;
}

export enum CategoryName {
  ALL_VIDEOS = '',
  PORTAL = 'portal',
  OTHER = 'other',
  INSTALLATION = 'installation',
  SONNEN_BATTERY = 'sonnen-battery',
  SONNEN_FLAT = 'sonnen-flat',
  SONNEN_CHARGER = 'sonnen-charger',
  SONNEN_DRIVE = 'sonnen-drive',
  ENERGY_PRODUCTS = 'energy-products',
  PARTNER_APP = 'partner-app',
}
