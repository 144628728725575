import { flow } from 'lodash';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { concat, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import {
  DELETE_EXPIRED_OFFER,
  GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY,
} from '+lead/+overview/store';
import { LeadActions } from '+shared/store/lead';
import { dataGuard } from '+utils/guards.util';
import { ofType } from '+utils/operators/ofType.operator';
import { makeQuery, processQuery } from '+utils/operators/processQuery.operator';

import { LayoutActions } from '../layout';
import { mapLeadCustomerType } from './lead.helpers';
import { LeadRepository } from './lead.repository';

type Action$ = ActionsObservable<LeadActions>;

const getLead$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.getLead),
    mergeMap(({ leadId, queryKey }) =>
      makeQuery(queryKey)({
        call: () => LeadRepository.getLead(leadId),
        onSuccess: (res) =>
          concat(
            flow(mapLeadCustomerType, dataGuard(LeadActions.setLead))(res.element),
            dataGuard(LeadActions.setUserPermissions)(res.meta)
          ),
      })
    )
  );

const postLead$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postLead),
    mergeMap(({ lead, queryKey }) =>
      of({}).pipe(
        processQuery(queryKey, () => LeadRepository.postLead(lead), {
          onSuccess: (res) => dataGuard(LeadActions.postLeadSuccess)(res!.element),
        })
      )
    )
  );

const postLeadConfiguration$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postConfiguration),
    mergeMap(({ id, config, queryKey }) =>
      of({}).pipe(
        processQuery(queryKey, () => LeadRepository.postLeadConfiguration(id, config), {
          onSuccess: (res) => dataGuard(LeadActions.postConfigurationSuccess)(res!.element),
        })
      )
    )
  );

const deleteLeadConfiguration$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.deleteConfiguration),
    mergeMap(({ leadId, configurationId, queryKey }) =>
      of({}).pipe(
        processQuery(
          queryKey,
          () => LeadRepository.deleteLeadConfiguration(leadId, configurationId),
          { onSuccess: () => dataGuard(LeadActions.deleteConfigurationSuccess)(configurationId) }
        )
      )
    )
  );

const patchOfferToRecalculate$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.patchOfferToRecalculate),
    mergeMap(({ leadId, offerId }) =>
      makeQuery(GET_RECALCULATED_OF_EXPIRED_OFFER_QUERY)({
        call: () => LeadRepository.patchForRecalculateExpiredOffer(leadId, offerId),
        onSuccess: () => concat(of(LeadOverviewPageActions.getOfferList(leadId))),
      })
    )
  );

const deleteExpiredOfferProduct$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.deleteExpiredOffer),
    mergeMap(({ leadId, offerId, productId }) =>
      makeQuery(DELETE_EXPIRED_OFFER)({
        call: () => LeadRepository.deleteProductFromExpiredOffer(leadId, offerId, productId),
        onSuccess: () => of(LeadOverviewPageActions.getOfferList(leadId)),
      })
    )
  );

const postLeadConfigurationRecommendation$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postConfigurationRecommendation),
    mergeMap(({ id, form, queryKey }) =>
      of({}).pipe(
        processQuery(queryKey, () => LeadRepository.postLeadConfigurationRecommendation(id, form), {
          onSuccess: (res) =>
            dataGuard(LeadActions.postConfigurationRecommendationSuccess)(res!.meta),
        })
      )
    )
  );

const postLeadConfigurationOptionalRecommendation$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.postConfigurationOptionalRecommendation),
    mergeMap(({ id, form, queryKey }) =>
      makeQuery(queryKey)({
        call: () => LeadRepository.postLeadConfigurationRecommendation(id, form),
        onSuccess: (res) =>
          dataGuard(LeadActions.postConfigurationOptionalRecommendationSuccess)(res!.meta),
      })
    )
  );

const updateAutarky$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.updateAutarky),
    mergeMap(({ id, configId, queryKey, autarky }) =>
      of({}).pipe(
        processQuery(queryKey, () => LeadRepository.updateAutarky(id, configId, autarky), {
          onSuccess: (res) => dataGuard(LeadActions.updateAutarkySuccess)(res!.element),
        })
      )
    )
  );

const patchLead$ = (action$: Action$) =>
  action$.pipe(
    ofType(LeadActions.patchLead),
    mergeMap(({ leadData, queryKey, leadId }) =>
      of({}).pipe(
        processQuery(queryKey, () => LeadRepository.patchLead(leadData, leadId), {
          onSuccess: (res) =>
            concat(
              flow(mapLeadCustomerType, dataGuard(LeadActions.setLead))(res!.element),
              dataGuard(LeadOverviewPageActions.setUpdatedLeadSection)(leadData),
              of(LayoutActions.toggleModal(false))
            ),
        })
      )
    )
  );

export const epics = combineEpics(
  getLead$,
  postLead$,
  patchLead$,
  postLeadConfiguration$,
  deleteLeadConfiguration$,
  postLeadConfigurationRecommendation$,
  postLeadConfigurationOptionalRecommendation$,
  updateAutarky$,
  patchOfferToRecalculate$,
  deleteExpiredOfferProduct$
);
