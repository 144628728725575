import { pick } from 'lodash';

import {
  LeadDso,
  LeadOffer,
  LeadProductAvailability,
  LeadProductType,
  Product,
  ProductAvailability,
} from '+shared/store/lead/types';
import { LeadProductVersion } from '+shared/store/lead/types/leadProductVersion.interface';

const getLeadsWithDsoValidationPostponed = (): string[] => {
  const storageDsoValidation = localStorage && localStorage.getItem('dsoValidationPostponed');

  return storageDsoValidation ? JSON.parse(storageDsoValidation) : [];
};

export const isDsoValidationPostponed = (leadId: string): boolean => {
  const leadsArray = getLeadsWithDsoValidationPostponed();

  return leadsArray.includes(leadId);
};

export const setDsoValidationPostponed = (newLeadId: string) => {
  const leadsArray = getLeadsWithDsoValidationPostponed();
  if (!leadsArray.find((lead) => lead === newLeadId)) {
    leadsArray.push(newLeadId);
  }
  localStorage.setItem('dsoValidationPostponed', JSON.stringify(leadsArray));
};

export const removeDsoValidationPostponed = (leadId: string) => {
  const leadsArray = getLeadsWithDsoValidationPostponed();
  const filteredArray = leadsArray.filter((lead) => lead !== leadId);
  localStorage.setItem('dsoValidationPostponed', JSON.stringify(filteredArray));
};

export const isProductUnavailableButFound = (
  dso: LeadProductAvailability,
  productName: LeadProductType,
  productVersion: LeadProductVersion
): boolean => {
  if (!dso.tsos) {
    return false;
  }

  const products: Product[] = [];

  for (const tso of dso.tsos) {
    const product = tso.products.find(
      (product) => product.name === productName && product.version === productVersion
    );
    if (product) {
      products.push(product);
    }
  }

  return products.some((product) => product.available === ProductAvailability.UNAVAILABLE);
};

// @Note: Flat X or FlatDirect product unavailable
export const isDsoBlackListed = (
  productAvailability: LeadProductAvailability[],
  assignedDso?: LeadDso | null
): boolean => {
  const dso = assignedDso && productAvailability.find((dso) => dso.id === assignedDso.id);
  if (!dso) {
    return false;
  }

  const isFlatxProductUnavailable = isProductUnavailableButFound(
    dso,
    LeadProductType.FLAT_X,
    LeadProductVersion.V_1_0
  );
  const isFlatDirectUnavailable = isProductUnavailableButFound(
    dso,
    LeadProductType.FLAT_DIRECT,
    LeadProductVersion.V_1_0
  );

  const isBlacklisted = isFlatxProductUnavailable && isFlatDirectUnavailable;
  return isBlacklisted;
};

const hasBatteryProduct = (offer: LeadOffer) =>
  offer.products.some((product) => product.productType === LeadProductType.BATTERY);
export const hasFlatXProduct = (offer: LeadOffer) =>
  offer.products.some((product) => product.productType === LeadProductType.FLAT_X);
export const hasFlatDirectProduct = (offer: LeadOffer) =>
  offer.products.some((product) => product.productType === LeadProductType.FLAT_DIRECT);

export const getFlatOffers = (offers: LeadOffer[]) =>
  offers.filter((offer) => hasFlatXProduct(offer) || hasFlatDirectProduct(offer));

export const isHardwareOnlyOffer = (offer: LeadOffer) => {
  if (!hasBatteryProduct(offer)) {
    return false;
  }

  return !hasFlatXProduct(offer) && !hasFlatDirectProduct(offer);
};

export const isFlatProduct = (productType: LeadProductType) =>
  [LeadProductType.FLAT_X, LeadProductType.FLAT_DIRECT].includes(productType);

export const getDsoFromProductAvailability = (
  productAvailability: LeadProductAvailability
): LeadDso => pick(productAvailability, ['id', 'name']);

export const mapProductAvailabilityToDsoList = (
  productAvailability: LeadProductAvailability[]
): LeadDso[] => productAvailability.map(getDsoFromProductAvailability);
