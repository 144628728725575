import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader, SideInfo } from '@sonnen/shared-web';

import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { includes, isEqual } from 'lodash';

import { getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { PATHS } from '+app/router';
import { filterByValue, searchByKey } from '+app/utils';
import { DsoRegistrationInstallerTestIds as testIds } from '+config/testIds';
import {
  DsoRegistrationFormBreakLine,
  DsoRegistrationFormContainer,
  DsoRegistrationInstallers,
  DsoRegistrationLoginFields,
} from '+setupTool/+form/components';
import {
  DsoRegistrationInstallerDataInitial,
  DsoRegistrationInstallerDataSchema,
  DsoRegistrationInstallerDataViewModes as modes,
} from '+setupTool/+form/store/schemas';
import { DsoRegistrationInstallerDataInterface } from '+setupTool/+form/store/types';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import {
  SetupDataStepKey,
  SetupDataStepStatus,
  SubmissionStep,
} from '+setupTool/store/+setupTool.dictionary';
import { getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import {
  FormBanner,
  FormInput,
  FormInputSelect,
  FormSectionParagraph,
  FormWizardStep,
} from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';

import { DsoRegistrationFormActions } from '../../store/+form.actions';
import {
  mapInstallerFormValuesToInstallerEndpoint,
  setAllFormFieldsTouched,
} from '../../store/+form.helpers';
import {
  getActiveStep,
  getCustomerDataForm,
  getDsoList,
  getGetDsoListQueryStatus,
  getGetInstallersQueryStatus,
  getInstallerDataForm,
  getInstallers,
  getIsOnlinePortal,
  getRemoveInstallersQueryStatus,
  getSubmitInstallerQueryStatus,
} from '../../store/+form.selectors';

import './DsoRegistrationInstallerData.component.scss';

interface OwnProps {
  leadStage?: string;
  stepStatus?: SetupDataStepStatus;
}

const mapStateToProps = (state: StoreState) => ({
  installerDataForm: getInstallerDataForm(state),
  customerDataForm: getCustomerDataForm(state),
  isOnlinePortal: getIsOnlinePortal(state),
  dsoList: getDsoList(state),
  installersList: getInstallers(state),
  leadId: getLeadId(state),
  activeStep: getActiveStep(state),
  registrationSubject: getRegistrationSubject(state),
  getInstallersQueryStatus: getGetInstallersQueryStatus(state),
  submitInstallerQueryStatus: getSubmitInstallerQueryStatus(state),
  removeInstallersQueryStatus: getRemoveInstallersQueryStatus(state),
  getDsoListQueryStatus: getGetDsoListQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  setDsoInstallerData: DsoRegistrationFormActions.setDsoInstallerData,
  goToSetupDashboard: (leadId: string, leadStage?: string) =>
    push(PATHS.SETUP_TOOL({ leadId }, leadStage)),
  checkForm: DsoRegistrationFormActions.checkFormFulfillment,
  saveData: SetupToolActions.saveSubmission,
  setActiveStep: DsoRegistrationFormActions.setActiveStep,
  setSetupDataStatuses: DsoRegistrationFormActions.setSetupDataStatuses,
  submitInstaller: DsoRegistrationFormActions.submitInstaller,
  setSubmitInstallerQueryStatus: DsoRegistrationFormActions.setSubmitInstallerQueryStatus,
  setRemoveInstallersQueryStatus: DsoRegistrationFormActions.setRemoveInstallersQueryStatus,
  removeInstallers: DsoRegistrationFormActions.removeInstallers,
  toggleModal: LayoutActions.toggleModal,
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const DsoRegistrationInstallerDataComponent: React.FC<Props> = ({
  actions,
  installerDataForm,
  isOnlinePortal,
  dsoList,
  installersList,
  leadId,
  leadStage,
  activeStep,
  stepStatus,
  getInstallersQueryStatus,
  submitInstallerQueryStatus,
  removeInstallersQueryStatus,
  getDsoListQueryStatus,
  registrationSubject,
}) => {
  actions.toggleModal(true);
  const formRef = useRef<FormikProps<DsoRegistrationInstallerDataInterface>>({} as any);
  const listOfDsoIDs = dsoList.map((item) => item.id);
  const listOfInstallerIDs = installersList.map((item) => item.id);
  const validationSchema = DsoRegistrationInstallerDataSchema();
  const shouldClearInstallerSelection =
    installersList && formRef.current && formRef.current.values
      ? installersList.every((item) => item.id !== formRef.current.values.installer_id)
      : true;

  const [installersIdsToRemove, setInstallersIdsToRemove] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState(modes.SELECTING);
  const [touchedOnSelectingMode, setTouchedOnSelectingMode] = useState(
    (stepStatus === SetupDataStepStatus.STARTED || stepStatus === SetupDataStepStatus.COMPLETED) &&
      viewMode === modes.SELECTING &&
      formRef.current.values
      ? setAllFormFieldsTouched(formRef.current)
      : formRef.current
      ? formRef.current.touched
      : {}
  );

  const {
    addInstaller,
    removeInstaller,
    removeInstallerButton,
    removeInstallerHeader,
    responsibleCompany,
    bannerInfo,
    header,
    installerSelection,
    responsibleInstaller,
  } = T.setupTool.installerData;

  useEffect(() => {
    actions.setActiveStep(SubmissionStep.SETUP_DATA_INSTALLER_DATA);
    activeStep = SubmissionStep.SETUP_DATA_INSTALLER_DATA;

    return () => {
      if (viewMode === modes.SELECTING) {
        saveValues();
      }
    };
  }, [viewMode]);

  useEffect(() => {
    if (installerDataForm && viewMode === modes.SELECTING) {
      formRef.current.setValues(installerDataForm);

      if (shouldClearInstallerSelection) {
        clearInstallerSelection();
      }
    }
  }, [installerDataForm]);

  const bannerVisibilityTime = 3500;

  useEffect(() => {
    if (removeInstallersQueryStatus.success) {
      setViewMode(modes.SELECTING);

      setTimeout(() => {
        actions.setRemoveInstallersQueryStatus('');
      }, bannerVisibilityTime);
    }
  }, [removeInstallersQueryStatus]);

  useEffect(() => {
    if (submitInstallerQueryStatus.success) {
      setViewMode(modes.SELECTING);

      setTimeout(() => {
        actions.setSubmitInstallerQueryStatus('');
      }, bannerVisibilityTime);
    }
  }, [submitInstallerQueryStatus]);

  useEffect(() => {
    if (viewMode === modes.NEW || viewMode === modes.REMOVING) {
      setTouchedOnSelectingMode(formRef.current.touched);
      formRef.current.resetForm();
    }
    if (viewMode === modes.SELECTING && installerDataForm) {
      formRef.current.setValues(installerDataForm);
      if (touchedOnSelectingMode) {
        formRef.current.setTouched(touchedOnSelectingMode);
      }
      if (shouldClearInstallerSelection) {
        clearInstallerSelection();
      }
    }
    if (
      (viewMode === modes.SELECTING || viewMode === modes.REMOVING) &&
      submitInstallerQueryStatus.error
    ) {
      actions.setSubmitInstallerQueryStatus('');
    }
  }, [viewMode]);

  useEffect(() => {
    setInstallersIdsToRemove((idsToRemove) =>
      idsToRemove.filter((id) => includes(listOfInstallerIDs, id))
    );
  }, [installersList]);

  const onSubmit = () => {
    actions.goToSetupDashboard(leadId!, leadStage);
  };

  const clearInstallerSelection = () => {
    if (formRef.current.values.installer_id) {
      formRef.current.setFieldValue('installer_id', '');
    }
  };

  const onSelectChange = (installerId: string) => {
    const installerData = installersList.filter((item) => item.id === installerId)[0];

    formRef.current.setValues({
      installer_id: installerId,
      installer_company: installerData.company,
      installer_first_name: installerData.firstName,
      installer_last_name: installerData.lastName,
      installer_company_street: installerData.companyStreet,
      installer_company_street_number: installerData.companyStreetNumber,
      installer_zip_code: installerData.zipCode,
      installer_city: installerData.city,
      installer_email: installerData.email,
      installer_phone: installerData.phone,
      installer_certification_number: installerData.certificationNumber,
      installer_certification_authority: installerData.certificationAuthority,
    });
  };

  const saveValues = () => {
    const values = formRef.current.values;
    const invalidFieldKeys = Object.keys(formRef.current.errors);
    const atLeastOneFieldTouched =
      formRef.current && formRef.current.touched && Object.keys(formRef.current.touched).length > 0;
    const newStepStatus =
      invalidFieldKeys.length === 0 ? SetupDataStepStatus.COMPLETED : SetupDataStepStatus.STARTED;
    const invalidOrEmptyValues = (invalidFieldKeys || []).reduce(
      (prev, fieldKey) => ({ ...prev, [fieldKey]: DsoRegistrationInstallerDataInitial[fieldKey] }),
      {}
    );

    if (atLeastOneFieldTouched && installerDataForm && !isEqual(installerDataForm, values)) {
      actions.setSetupDataStatuses({ installerData: newStepStatus });
      actions.setDsoInstallerData({ ...values, ...invalidOrEmptyValues });
      actions.checkForm(registrationSubject);
      actions.saveData(activeStep, { [SetupDataStepKey.INSTALLER_DATA]: newStepStatus });
    } else if (atLeastOneFieldTouched && newStepStatus !== stepStatus) {
      actions.setSetupDataStatuses({ installerData: newStepStatus });
      actions.saveData(activeStep, { [SetupDataStepKey.INSTALLER_DATA]: newStepStatus });
    }
  };

  const submitButtonLabel = (viewMode: string) => {
    const labels = {
      [modes.NEW]: addInstaller,
      [modes.REMOVING]: removeInstallerButton,
      [modes.SELECTING]: T.setupTool.step.saveAndComplete,
    };
    return I18n.t(labels[viewMode] || T.setupTool.step.saveAndComplete);
  };

  return (
    <Formik
      initialValues={DsoRegistrationInstallerDataInitial}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {(form: FormikProps<DsoRegistrationInstallerDataInterface>) => (
        <Form className="c-form-wizard__form">
          <FormWizardStep
            submitButtonLabel={submitButtonLabel(viewMode)}
            onBeforeSubmit={(e) => {
              e.preventDefault();

              if (viewMode === modes.NEW) {
                const atLeastOneFieldTouched = Object.keys(formRef.current.touched).length > 0;
                const validationPassed = Object.keys(formRef.current.errors).length === 0;
                if (atLeastOneFieldTouched && validationPassed) {
                  actions.submitInstaller(
                    mapInstallerFormValuesToInstallerEndpoint(formRef.current.values)
                  );
                } else {
                  setAllFormFieldsTouched(formRef.current);
                }
              }

              if (viewMode === modes.REMOVING) {
                actions.removeInstallers(installersIdsToRemove);
              }

              if (viewMode === modes.SELECTING) {
                onSubmit();
              }
            }}
            showCancelButton={viewMode === modes.NEW || viewMode === modes.REMOVING}
            showPreviousNavButton={viewMode === modes.SELECTING}
            cancelButtonHandler={() => setViewMode(modes.SELECTING)}
            isSubmitButtonDisabled={
              viewMode === modes.REMOVING && installersIdsToRemove.length === 0
            }
            isPending={
              (viewMode === modes.NEW && submitInstallerQueryStatus.pending) ||
              (viewMode === modes.REMOVING && removeInstallersQueryStatus.pending)
            }
          >
            <FormBanner
              isVisible={viewMode === modes.SELECTING && submitInstallerQueryStatus.success}
              type="success"
              message={I18n.t(bannerInfo.installerHasBeenAddedMessage)}
              icon={
                <Icon.DoneCircle
                  className="c-dso-registration-installer-options__form-banner-icon"
                  secondaryColor="#fff"
                />
              }
              bannerVisibilityTime={bannerVisibilityTime}
              id="installerAddedBanner"
              parentElementToScroll="c-overlay-setup"
            />
            <FormBanner
              isVisible={removeInstallersQueryStatus.success}
              type="success"
              message={I18n.t(bannerInfo.installersHaveBeenRemovedMessage)}
              icon={
                <Icon.DoneCircle
                  className="c-dso-registration-installer-options__form-banner-icon"
                  secondaryColor="#fff"
                />
              }
              bannerVisibilityTime={bannerVisibilityTime}
              id="installersRemovedBanner"
              parentElementToScroll="c-overlay-setup"
            />

            {viewMode === modes.SELECTING && (
              <DsoRegistrationFormContainer className="c-dso-registration-container--complete-data">
                <SideInfo className="c-help__side-info c-help__side-info--complete-box">
                  <div className={`c-help__side-info-icon`}>
                    <Icon.Info />
                  </div>
                  <div>
                    <h3>{I18n.t(header.title)}</h3>
                    <p>{I18n.t(header.description)}</p>
                  </div>
                </SideInfo>

                <FormInputSelect
                  className="c-dso-registration-installer-data__item"
                  placeholder={I18n.t(T.setupTool.selectPlaceholder)}
                  form={form}
                  label={I18n.t(installerSelection)}
                  id="setup-tool-installer-data__installer_name"
                  name="installer_id"
                  collection={listOfInstallerIDs}
                  mapper={(key) => searchByKey('id', key, installersList, 'fullName')}
                  search={(val) =>
                    filterByValue(installersList, 'fullName')(val).map((item) => item.id)
                  }
                  onSelect={onSelectChange}
                  resetValueIfNoItemSelected={true}
                  noResultsComponent={getInstallersQueryStatus.pending ? <Loader /> : null}
                  suppressValuesInHotjar={true}
                  dataTestId={testIds.installerSelection}
                  dropDownOptionsDataTestId={testIds.installerSelectionOptions}
                />

                <div
                  className={
                    'c-dso-registration-installer-data__item ' +
                    'c-dso-registration-installer-data__item--options'
                  }
                >
                  <a
                    className={classNames('c-dso-registration-installer-options__item', {
                      'c-dso-registration-installer-options__item--empty-list':
                        installersList.length === 0,
                    })}
                    onClick={() => setViewMode(modes.NEW)}
                  >
                    <Icon.PlusCircle className="c-dso-registration-installer-options__icon" />
                    {I18n.t(addInstaller)}
                  </a>
                  {installersList.length > 0 && (
                    <a
                      className="c-dso-registration-installer-options__item"
                      onClick={() => setViewMode(modes.REMOVING)}
                    >
                      <Icon.Trash className="c-dso-registration-installer-options__icon" />
                      {I18n.t(removeInstaller)}
                    </a>
                  )}
                </div>
              </DsoRegistrationFormContainer>
            )}

            {viewMode === modes.NEW && (
              <p className="c-dso-registration-installer__form-header">{I18n.t(addInstaller)}</p>
            )}

            {viewMode === modes.NEW || viewMode === modes.SELECTING ? (
              <DsoRegistrationFormContainer>
                {isOnlinePortal && (
                  <DsoRegistrationLoginFields form={form} isOnlinePortal={isOnlinePortal} />
                )}

                <FormSectionParagraph>{I18n.t(responsibleCompany)}</FormSectionParagraph>
                <FormFieldObserver<DsoRegistrationInstallerDataInterface>
                  onChange={clearInstallerSelection}
                >
                  <DsoRegistrationFormInput
                    form={form}
                    field="companyName"
                    name="installer_company"
                  />

                  <DsoRegistrationFormBreakLine />

                  <DsoRegistrationFormInput
                    form={form}
                    field="street"
                    name="installer_company_street"
                  />
                  <DsoRegistrationFormInput
                    form={form}
                    field="streetNumber"
                    dataTestId="houseNumber"
                    name="installer_company_street_number"
                  />
                  <DsoRegistrationFormInput form={form} field="zipCode" name="installer_zip_code" />
                  <DsoRegistrationFormInput form={form} name="installer_city" field="city" />

                  <FormSectionParagraph>{I18n.t(responsibleInstaller)}</FormSectionParagraph>

                  <DsoRegistrationFormInput
                    form={form}
                    field="firstName"
                    name="installer_first_name"
                  />
                  <DsoRegistrationFormInput
                    form={form}
                    field="lastName"
                    name="installer_last_name"
                  />
                  <DsoRegistrationFormInput form={form} name="installer_email" field="email" />
                  <DsoRegistrationFormInput
                    form={form}
                    field="phoneNumber"
                    name="installer_phone"
                  />

                  <FormInputSelect
                    className="c-dso-registration-installer-data__item"
                    placeholder={I18n.t(T.setupTool.selectPlaceholder)}
                    form={form}
                    label={I18n.t(T.setupTool.form.installerCertificationAuthority)}
                    id="setup-tool-installer-data__installer_certification_authority"
                    name="installer_certification_authority"
                    collection={listOfDsoIDs}
                    mapper={(key) => searchByKey('id', key, dsoList, 'name')}
                    search={(val) => filterByValue(dsoList, 'name')(val).map((item) => item.id)}
                    resetValueIfNoItemSelected={true}
                    noResultsComponent={getDsoListQueryStatus.pending ? <Loader /> : null}
                    dataTestId={testIds.installerCertificationAuthority}
                    dropDownOptionsDataTestId={testIds.installerCertificationAuthorityOptions}
                  />

                  <DsoRegistrationFormInput
                    form={form}
                    field="certificationNumber"
                    name="installer_certification_number"
                  />
                </FormFieldObserver>
                <FormBanner
                  isVisible={viewMode === modes.NEW && submitInstallerQueryStatus.error}
                  type="error"
                  id="installerErrorBanner"
                  message={I18n.t(bannerInfo.installerAlreadyExistMessage)}
                  description={I18n.t(bannerInfo.installerAlreadyExistDescription)}
                  icon={
                    <Icon.CloseCircle
                      className={'c-dso-registration-installer-options__form-banner-icon'}
                    />
                  }
                />
              </DsoRegistrationFormContainer>
            ) : null}

            {viewMode === modes.REMOVING ? (
              <>
                <p className="c-dso-registration-installer__form-header">
                  {I18n.t(removeInstallerHeader)}
                </p>
                <DsoRegistrationInstallers
                  form={form}
                  items={installersList}
                  installersIdsToRemove={installersIdsToRemove}
                  setInstallersIdsToRemove={setInstallersIdsToRemove}
                />
              </>
            ) : null}
          </FormWizardStep>
        </Form>
      )}
    </Formik>
  );
};

type DsoRegistrationFormInputProps = {
  name: string;
  field: string;
  form: FormikProps<DsoRegistrationInstallerDataInterface>;
  dataTestId?: string;
};

const DsoRegistrationFormInput = ({
  name,
  field,
  form,
  dataTestId,
}: DsoRegistrationFormInputProps) => {
  const label = I18n.t(T.setupTool.installerData[field]);

  return (
    <FormInput
      className="c-dso-registration-installer-data__item"
      form={form}
      label={label}
      placeholder={label}
      name={name}
      dataTestId={testIds[dataTestId || field]}
    />
  );
};

export const DsoRegistrationInstallerData = connect(
  mapStateToProps,
  mapDispatchToProps
)(DsoRegistrationInstallerDataComponent);
