import { ConfigurationForm } from '+lead-configuration/store/types';
import { ActionsUnion } from '+utils/redux/actionsUnion.util';
import { createAction } from '+utils/redux/createAction.util';

import {
  Lead,
  LeadConfigurationAttributes,
  LeadConfigurationResponse,
  LeadConfigurationStatus,
  LeadCreateDto,
  LeadMetaData,
  LeadPatchDto,
} from './types';
import { UpdateStatusDto } from './types/leadStatus.interface';

export enum LEAD_ACTIONS {
  // leads
  POST_LEAD = '[Lead] POST_LEAD',
  GET_LEAD = '[Lead] GET_LEAD',
  SET_LEAD = '[Lead] SET_LEAD',
  SET_USER_PERMISSIONS = '[Lead] SET_USER_PERMISSIONS',
  POST_LEAD_SUCCESS = '[Lead] POST_LEAD_SUCCESS',
  PATCH_LEAD = '[Lead] PATCH_LEAD',
  POST_LEAD_STATUS = '[Lead] POST_LEAD_STATUS',

  // configurations
  SET_CONFIGURATION = '[Lead] SET_CONFIGURATION',
  POST_CONFIGURATION = '[Lead] POST_CONFIGURATION',
  DELETE_CONFIGURATION = '[Lead] DELETE_CONFIGURATION',
  DELETE_CONFIGURATION_SUCCESS = '[Lead] DELETE_CONFIGURATION_SUCCESS',
  POST_CONFIGURATION_SUCCESS = '[Lead] POST_CONFIGURATION_SUCCESS',
  PATCH_CONFIGURATION_STATUS = '[Lead] PATCH_CONFIGURATION_STATUS',
  PATCH_CONFIGURATION_STATUS_SUCCESS = '[Lead] PATCH_CONFIGURATION_STATUS_SUCCESS',
  POST_CONFIGURATION_RECOMMENDATION = '[Lead] POST_CONFIGURATION_RECOMMENDATION',
  POST_CONFIGURATION_OPTIONAL_RECOMMENDATION = '[Lead] POST_CONFIGURATION_OPTIONAL_RECOMMENDATION',
  POST_CONFIGURATION_RECOMMENDATION_SUCCESS = '[Lead] POST_CONFIGURATION_RECOMMENDATION_SUCCESS',
  // eslint-disable-next-line max-len
  POST_CONFIGURATION_OPTIONAL_RECOMMENDATION_SUCCESS = '[Lead] POST_CONFIGURATION_OPTIONAL_RECOMMENDATION_SUCCESS',
  RECALCULATE_CONFIGURATION = '[Lead] RECALCULATE_CONFIGURATION',
  UPDATE_AUTARKY = '[Lead] UPDATE_AUTARKY',
  UPDATE_AUTARKY_SUCCESS = '[Lead] UPDATE_AUTARKY_SUCCESS',

  // offers
  PATCH_OFFER_TO_RECALCULATE = '[Lead] PATCH_OFFER_TO_RECALCULATE',
  DELETE_EXPIRED_OFFER = '[Lead] DELETE_EXPIRED_OFFER',
}

export const LeadActions = {
  // leads
  getLead: createAction(LEAD_ACTIONS.GET_LEAD, (leadId: string, queryKey: string) => ({
    leadId,
    queryKey,
  })),
  setLead: createAction(LEAD_ACTIONS.SET_LEAD, (lead: Lead) => ({ lead })),
  setUserPermissions: createAction(
    LEAD_ACTIONS.SET_USER_PERMISSIONS,
    ({ permissions }: LeadMetaData) => ({ permissions })
  ),
  postLead: createAction(
    LEAD_ACTIONS.POST_LEAD,
    (opts: { lead: LeadCreateDto; queryKey: string }) => ({ ...opts })
  ),
  postLeadSuccess: createAction(LEAD_ACTIONS.POST_LEAD_SUCCESS, (lead: Lead) => ({ lead })),
  patchLead: createAction(
    LEAD_ACTIONS.PATCH_LEAD,
    (opts: { leadData: Partial<LeadPatchDto>; queryKey: string; leadId: string }) => ({ ...opts })
  ),
  postLeadStatus: createAction(
    LEAD_ACTIONS.POST_LEAD_STATUS,
    (opts: { statusUpdate: UpdateStatusDto; queryKey: string; leadId: string }) => ({ ...opts })
  ),

  // configurations
  setConfiguration: createAction(
    LEAD_ACTIONS.SET_CONFIGURATION,
    (configuration: LeadConfigurationResponse) => ({ configuration })
  ),
  postConfiguration: createAction(
    LEAD_ACTIONS.POST_CONFIGURATION,
    (opts: { id: string; config: LeadConfigurationAttributes; queryKey: string }) => ({ ...opts })
  ),
  postConfigurationSuccess: createAction(
    LEAD_ACTIONS.POST_CONFIGURATION_SUCCESS,
    (config: any /* @TODO */) => ({ config })
  ),
  deleteConfiguration: createAction(
    LEAD_ACTIONS.DELETE_CONFIGURATION,
    (opts: { leadId: string; configurationId: string; queryKey: string }) => ({ ...opts })
  ),
  deleteConfigurationSuccess: createAction(
    LEAD_ACTIONS.DELETE_CONFIGURATION_SUCCESS,
    (deletedConfigurationId: string) => ({ deletedConfigurationId })
  ),
  patchConfigurationStatus: createAction(
    LEAD_ACTIONS.PATCH_CONFIGURATION_STATUS,
    (opts: {
      leadId: string;
      configurationId: string;
      status: LeadConfigurationStatus;
      queryKey: string;
    }) => ({ ...opts })
  ),
  patchConfigurationStatusSuccess: createAction(
    LEAD_ACTIONS.PATCH_CONFIGURATION_STATUS_SUCCESS,
    (configuration: LeadConfigurationResponse) => ({ configuration })
  ),
  recalculateConfiguration: createAction(
    LEAD_ACTIONS.RECALCULATE_CONFIGURATION,
    (opts: {
      leadId: string;
      configurationId: string;
      queryKey: string;
      mixedCalculation: boolean;
      semiIndirect: boolean;
      generationPlants: boolean;
    }) => ({ ...opts })
  ),
  postConfigurationRecommendation: createAction(
    LEAD_ACTIONS.POST_CONFIGURATION_RECOMMENDATION,
    (opts: { id: string; form: ConfigurationForm; queryKey: string }) => ({ ...opts })
  ),
  postConfigurationOptionalRecommendation: createAction(
    LEAD_ACTIONS.POST_CONFIGURATION_OPTIONAL_RECOMMENDATION,
    (opts: { id: string; form: ConfigurationForm; queryKey: string }) => ({ ...opts })
  ),
  postConfigurationRecommendationSuccess: createAction(
    LEAD_ACTIONS.POST_CONFIGURATION_RECOMMENDATION_SUCCESS,
    (config: any) => ({ config })
  ),
  postConfigurationOptionalRecommendationSuccess: createAction(
    LEAD_ACTIONS.POST_CONFIGURATION_OPTIONAL_RECOMMENDATION_SUCCESS,
    (config: any) => ({ config })
  ),
  updateAutarky: createAction(
    LEAD_ACTIONS.UPDATE_AUTARKY,
    (opts: { id: string; configId: string; queryKey: string; autarky: number }) => ({ ...opts })
  ),
  updateAutarkySuccess: createAction(
    LEAD_ACTIONS.UPDATE_AUTARKY_SUCCESS,
    (config: any /* @TODO */) => ({ config })
  ),
  // Move these offer actions and epics to +app/lead/offer/store
  patchOfferToRecalculate: createAction(
    LEAD_ACTIONS.PATCH_OFFER_TO_RECALCULATE,
    (leadId: string, offerId: string) => ({ leadId, offerId })
  ),
  deleteExpiredOffer: createAction(
    LEAD_ACTIONS.DELETE_EXPIRED_OFFER,
    (leadId: string, offerId: string, productId: string) => ({ leadId, offerId, productId })
  ),
};

export type LeadActions = ActionsUnion<typeof LeadActions>;
