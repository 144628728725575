import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { DefaultParagraph } from '@sonnen/shared-web';

import { Button, ButtonSize } from '+shared/components/Button';

import { sonnenDriveLink } from '../../containers/SonnenDrive.helper';

import './SonnenDriveCarsFeaturedSlide.component.scss';

interface Props {
  featured?: boolean;
  image: string;
  name: string;
  price: string;
  priceDescription: string;
  amount: string;
  button?: string;
}

export const SonnenDriveCarsFeaturedSlide: React.FC<Props> = ({
  image,
  name,
  price,
  priceDescription,
  amount,
}) => (
  <div className={'c-sonnen-drive-cars-featured-slide'}>
    <div className={'c-sonnen-drive-cars-featured-slide__inner'}>
      <DefaultParagraph className={'c-sonnen-drive-cars-featured-slide__name'}>
        {name}
      </DefaultParagraph>
      <div className={'c-sonnen-drive-cars-featured-slide__image'}>
        <img src={image} className={'c-sonnen-drive-cars-featured-slide__image'} />
      </div>
      <div className={'c-sonnen-drive-cars-featured-slide__pricing'}>
        <div className={'c-sonnen-drive-cars-featured-slide__price-container'}>
          <span className={'c-sonnen-drive-cars-featured-slide__price--pre'}>ab</span>
          <del className={'c-sonnen-drive-cars-featured-slide__price'}>{price}</del>
          <span className={'c-sonnen-drive-cars-featured-slide__amount'}>{amount}</span>
        </div>
        <DefaultParagraph className={'c-sonnen-drive-cars-featured-slide__price-description'}>
          {priceDescription}
        </DefaultParagraph>
        <div className={'c-sonnen-drive-cars-feature-slide__button'}>
          <Button
            label={I18n.t(T.drive.cars.electromobility.button)}
            size={ButtonSize.SMALL}
            onClick={() => window.open(sonnenDriveLink, '_blank')}
          />
        </div>
      </div>
    </div>
  </div>
);
