import {
  FlatDocumentType,
  LeadAddress,
  LeadPatchDto,
  LeadProductAvailability,
  LeadStatus,
} from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { ActionsUnion } from '+utils/redux/actionsUnion.util';
import { createAction } from '+utils/redux/createAction.util';

import {
  LeadOffer,
  OfferProductStatus,
} from '../../../shared/store/lead/types/leadOffer.interface';

export enum LEAD_OVERVIEW_PAGE_ACTIONS {
  COMPOSE_OFFER = '[+LeadOverview] COMPOSE_OFFER',
  GET_OFFER_LIST = '[+LeadOverview] GET_OFFER_LIST',
  GET_OFFER_LIST_SUCCESS = '[+LeadOverview] GET_OFFER_LIST_SUCCESS',
  DELETE_OFFER = '[+LeadOverview] DELETE_OFFER',
  DELETE_OFFER_SUCCESS = '[+LeadOverview] DELETE_OFFER_SUCCESS',
  CLEAR_OFFERS = '[+LeadOverview] CLEAR_OFFERS',
  SET_UPDATED_LEAD_SECTION = '[+LeadOverview] SET_UPDATED_LEAD_SECTION',
  GET_OFFER_DOCUMENT = '[+LeadOverview] GET_OFFER_DOCUMENT',
  OPEN_OFFER_DOCUMENT = '[+LeadOverview] OPEN_OFFER_DOCUMENT',
  CREATE_DOCUMENT = '[+LeadOverview] CREATE_DOCUMENT',
  DOCUMENT_OPENED = '[+LeadOverview] DOCUMENT_OPENED',
  DOCUMENT_FAILED = '[+LeadOverview] DOCUMENT_FAILED',
  CLOSE_DOCUMENT_FAILED = '[+LeadOverview] CLOSE_DOCUMENT_FAILED',
  POST_PARTNER_NOTE = '[+LeadOverview] POST_PARTNER_NOTE',
  GET_PARTNER_NOTE = '[+LeadOverview] GET_PARTNER_NOTE',
  GET_PARTNER_NOTE_SUCCESS = '[+LeadOverview] GET_PARTNER_NOTE_SUCCESS',
  CLEAR_PARTNER_NOTE = '[+LeadOverview] CLEAR_PARTNER_NOTE',
  SET_UPDATING_OFFER = '[+LeadOverview] SET_UPDATING_OFFER',
  CLEAR_UPDATING_OFFER = '[+LeadOverview] CLEAR_UPDATING_OFFER',
  RECALCULATE_CONFIGURATION = '[+LeadOverview] RECALCULATE_CONFIGURATION',
  CLOSE_LEAD = '[+LeadOverview] CLOSE_LEAD',
  SEND_CG_INVITATION = '[+LeadOverview] SEND_CG_INVITATION',
  SET_DSO_CHOICE_POSTPONED = '[+LeadOverview] SET_DSO_CHOICE_POSTPONED',
  SET_NEW_LEAD_ADDRESS = '[+LeadOverview] SET_LEAD_NEW_ADDRESS',
  CLEAR_NEW_LEAD_ADDRESS = '[+LeadOverview] CLEAR_LEAD_NEW_ADDRESS',
  SET_NEW_ADDRESS_ERROR_ACTIVE = '[+LeadOverview] SET_NEW_ADDRESS_ERROR_ACTIVE',
  GET_PRODUCT_AVAILABILITY = '[+LeadOverview] GET_PRODUCT_AVAILABILITY',
  SET_PRODUCT_AVAILABILITY = '[+LeadOverview] SET_PRODUCT_AVAILABILITY',
  CLEAR_PRODUCT_AVAILABILITY = '[+LeadOverview] CLEAR_PRODUCT_AVAILABILITY',
  GET_PRODUCT_AVAILABILITY_FOR_ADDRESS = '[+LeadOverview] GET_PRODUCT_AVAILABILITY_FOR_ADDRESS',
  SET_PRODUCT_AVAILABILITY_FOR_ADDRESS = '[+LeadOverview] SET_PRODUCT_AVAILABILITY_FOR_ADDRESS',
  CLEAR_PRODUCT_AVAILABILITY_FOR_ADDRESS = '[+LeadOverview] CLEAR_PRODUCT_AVAILABILITY_FOR_ADDRESS',
  SAVE_ASYNC_OFFER_SENT_ID = '[+LeadOverview] SAVE_ASYNC_OFFER_SENT_ID',
  SET_ASYNC_FLAT_OFFER_SENT_STATUS = '[+LeadOverview] SET_ASYNC_FLAT_OFFER_SENT_STATUS',
  CLEAR_ASYNC_OFFER_SENT_ID = '[+LeadOverview] CLEAR_ASYNC_OFFER_SENT_ID',
  SET_PRODUCT_BATTERY_LIST = '[+LeadOverview] SET_PRODUCT_BATTERY_LIST',
  SET_HARDWARE_OFFER_STATUS = '[+LeadOverview] SET_HARDWARE_OFFER_STATUS',
  // eslint-disable-next-line max-len
  SET_BLOCKED_STATUS_ON_DIFFERENT_HW_OFFERS = '[+LeadOverview] SET_BLOCKED_STATUS_ON_DIFFERENT_HW_OFFERS',
  DISPLAY_MAX_OFFERS_WARNING = '[+LeadOverview] DISPLAY_MAX_OFFERS_WARNING',
  CLEAR_MAX_OFFERS_WARNING = '[+LeadOverview] CLEAR_MAX_OFFERS_WARNING',
}

export const LeadOverviewPageActions = {
  getOfferList: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.GET_OFFER_LIST, (leadId: string) => ({
    leadId,
  })),
  getOfferListSuccess: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.GET_OFFER_LIST_SUCCESS,
    (leadId: string, offers: LeadOffer[]) => ({ leadId, offers })
  ),
  composeOffer: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.COMPOSE_OFFER,
    (leadId: string, configurationId: string) => ({ leadId, configurationId })
  ),
  clearOffers: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_OFFERS),
  deleteOffer: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.DELETE_OFFER,
    (opts: {
      leadId: string;
      offerId: string;
      isLastFlatOfferSent: boolean;
      isLastHardwareOnlyOfferSent: boolean;
      isLastFlatOnlyOffer: boolean;
    }) => ({ ...opts })
  ),
  deleteOfferSuccess: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.DELETE_OFFER_SUCCESS,
    (offerId: string) => ({ offerId })
  ),
  setUpdatedLeadSection: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_UPDATED_LEAD_SECTION,
    (updatedSection: Partial<LeadPatchDto>) => ({ updatedSection })
  ),
  getOfferDocument: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.GET_OFFER_DOCUMENT,
    (opts: { leadId: string; offerId: string; documentType: FlatDocumentType }) => ({ ...opts })
  ),
  openOfferDocument: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.OPEN_OFFER_DOCUMENT,
    (url: string) => ({ url })
  ),
  documentOpened: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.DOCUMENT_OPENED),
  documentFailed: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.DOCUMENT_FAILED,
    (resourceId: string, resourceType: string, documentType: string) => ({
      resourceId,
      resourceType,
      documentType,
    })
  ),
  closeDocumentFailed: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLOSE_DOCUMENT_FAILED),
  createDocument: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CREATE_DOCUMENT, (leadId: string) => ({
    leadId,
  })),
  postPartnerNote: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.POST_PARTNER_NOTE,
    (partnerNotes: string) => ({ partnerNotes })
  ),
  getPartnerNote: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.GET_PARTNER_NOTE, (noteId: string) => ({
    noteId,
  })),
  getPartnerNoteSuccess: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.GET_PARTNER_NOTE_SUCCESS,
    (note: string) => ({ note })
  ),
  clearPartnerNotes: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_PARTNER_NOTE),
  setUpdatingOffer: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_UPDATING_OFFER,
    (offer: LeadOffer) => ({ offer })
  ),
  clearUpdatingOffer: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_UPDATING_OFFER),
  recalculateConfiguration: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.RECALCULATE_CONFIGURATION,
    (
      configurationId: string,
      opts: {
        semiIndirect: boolean;
        generationPlants: boolean;
        productionMeteringMandatory?: boolean;
      }
    ) => ({ configurationId, ...opts })
  ),
  closeLead: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.CLOSE_LEAD,
    (leadId: string, reason: string, status: LeadStatus) => ({ leadId, reason, status })
  ),
  sendCGInvitation: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SEND_CG_INVITATION,
    (leadId: string) => ({ leadId })
  ),

  setDsoChoicePostponed: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_DSO_CHOICE_POSTPONED,
    (leadId: string) => ({ leadId })
  ),
  setNewLeadAddress: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_NEW_LEAD_ADDRESS,
    (newAddress: LeadAddress) => ({ newAddress })
  ),
  clearNewLeadAddress: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_NEW_LEAD_ADDRESS),
  setNewAddressErrorActive: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_NEW_ADDRESS_ERROR_ACTIVE,
    (isActive: boolean) => ({ isActive })
  ),
  getProductAvailability: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.GET_PRODUCT_AVAILABILITY,
    (dsoId?: string, tsoName?: string) => ({ dsoId, tsoName })
  ),
  setProductAvailability: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_PRODUCT_AVAILABILITY,
    (productAvailability: LeadProductAvailability[]) => ({ productAvailability })
  ),
  clearProductAvailability: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_PRODUCT_AVAILABILITY),
  // ProductAvailability for address needs to be a separate endpoint since it's called
  // only when changing the address it should not be saved in the same field in store since
  //  the address edition can be aborted by the user
  // for example when having multiple DSOs and closing the popup to pick one
  // but productAvailability in store would be overwritten already and might cause data mismatch
  getProductAvailabilityForAddress: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.GET_PRODUCT_AVAILABILITY_FOR_ADDRESS,
    (address: LeadAddress) => ({ address })
  ),
  setProductAvailabilityForAddress: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_PRODUCT_AVAILABILITY_FOR_ADDRESS,
    (productAvailability: LeadProductAvailability[]) => ({ productAvailability })
  ),
  clearProductAvailabilityForAddress: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_PRODUCT_AVAILABILITY_FOR_ADDRESS
  ),
  // saves offer id, which status will be changed asynchronously
  saveAsyncOfferSentId: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SAVE_ASYNC_OFFER_SENT_ID,
    (offerId: string) => ({ offerId })
  ),
  // sets the offer sent status to a specific offer
  setAsyncFlatOfferSentStatus: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_ASYNC_FLAT_OFFER_SENT_STATUS,
    (offerId: string) => ({ offerId })
  ),
  clearAsyncOfferSentId: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_ASYNC_OFFER_SENT_ID),
  setProductBatteryList: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_PRODUCT_BATTERY_LIST,
    (productBatteryList: LeadProductBattery[]) => ({ productBatteryList })
  ),
  setHardwareOfferStatus: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_HARDWARE_OFFER_STATUS,
    (status: OfferProductStatus, offerId: string) => ({ status, offerId })
  ),
  setBlockedStatusOnDifferentHwOffers: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.SET_BLOCKED_STATUS_ON_DIFFERENT_HW_OFFERS,
    (productId: string) => ({ productId })
  ),
  displayMaxOffersWarning: createAction(
    LEAD_OVERVIEW_PAGE_ACTIONS.DISPLAY_MAX_OFFERS_WARNING,
    (leadId: string) => ({ leadId })
  ),
  clearMaxOffersWarning: createAction(LEAD_OVERVIEW_PAGE_ACTIONS.CLEAR_MAX_OFFERS_WARNING),
};

export type LeadOverviewPageActions = ActionsUnion<typeof LeadOverviewPageActions>;
