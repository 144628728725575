import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { Arrow, MeasurementConceptGraph } from '+shared/basicComponents/Icons';
import { BodyLarge, BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';

import './MeasurementConcept.scss';

export enum MeasurementConcepts {
  CONSUMPTION = '0',
  DIFFERENTIAL = '2',
  GRID = '3',
}

export type MeasurementConceptsType = MeasurementConcepts | null;

const measurementConceptDictionary = {
  '0': 'consumption',
  '2': 'differential',
  '3': 'grid',
};

type Props = {
  measurementConcept: MeasurementConcepts;
};

const MeasurementConcept: React.FC<Props> = ({ measurementConcept }) => {
  const [dropdownContentIsOpen, setDropdownContentIsOpen] = useState(false);

  const { showInstructionsButton } = T.customerSingle.pvSystemsAndMeters.measurementConcepts;
  const {
    conceptTitle,
    productionHeadline,
    productionText,
    consumptionHeadline,
    consumptionText,
    paragraphOne,
    paragraphTwo,
  } =
    T.customerSingle.pvSystemsAndMeters.measurementConcepts[
      measurementConceptDictionary[measurementConcept]
    ];

  return (
    <div className="measurement-concept">
      <div>
        <BodyLarge text={I18n.t(conceptTitle)} className="measurement-concept__title" />
        <MeasurementConceptGraph
          /*
           * TODO(SON-36206): this cast to string is (temporarily) necessary because
           * the data for the measurement concept that comes from IoT is irregular.
           * According to the swagger documentation, the measurement concept can be integer or null.
           * However, depending on the battery, it may come as a string or as a number (or null).
           * IoT has opened the above ticket to fix this issue and cast the data on their side.
           */
          conceptType={measurementConcept.toString() as MeasurementConcepts}
        />
      </div>

      <button
        onClick={() => setDropdownContentIsOpen((prev) => !prev)}
        className="measurement-concept__dropdown-button"
      >
        <LabelMedium
          text={I18n.t(showInstructionsButton)}
          className="measurement-concept__dropdown-button--label"
        />
        <div className={`arrow-button ${dropdownContentIsOpen ? 'is-rotated' : ''}`}>
          <Arrow className="arrow-svg" />
        </div>
      </button>

      {dropdownContentIsOpen && (
        <div className="measurement-concept__dropdown-content">
          <LabelMedium text={I18n.t(productionHeadline)} />
          <BodyMedium text={I18n.t(productionText)} className="body-spacing" />
          <LabelMedium text={I18n.t(consumptionHeadline)} />
          <BodyMedium text={I18n.t(consumptionText)} className="body-spacing" />
          <BodyMedium text={I18n.t(paragraphOne)} className="body-spacing" />
          <BodyMedium text={I18n.t(paragraphTwo)} className="body-spacing" />
        </div>
      )}
    </div>
  );
};

export default MeasurementConcept;
