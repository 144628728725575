import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { PageHeadline, useFeature } from '@sonnen/shared-web';

import { FeatureName } from '+config/featureFlags';
import { Container } from '+shared/components';

import {
  SonnenDriveBenefits,
  SonnenDriveCars,
  SonnenDriveMoreInfo,
  SonnenDriveProcess,
  SonnenDrivePromo,
  SonnenDriveTestimonials,
} from '../components';

const isCarsFeatureEnabled = useFeature(FeatureName.SONNEN_DRIVE_CARS).isEnabled;

export const Drive: React.SFC = () => (
  <Container>
    <PageHeadline>{I18n.t(T.drive.pageTitle)}</PageHeadline>
    <SonnenDrivePromo />
    <SonnenDriveBenefits />
    {isCarsFeatureEnabled && <SonnenDriveCars />}
    <SonnenDriveProcess />
    <SonnenDriveMoreInfo />
    <SonnenDriveTestimonials />
  </Container>
);
