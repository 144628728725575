import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { formFields } from '../SetupLeadEditStatusModal/SetupLeadEditStatusModal.helper';

export const getSetupTooltipContentTranslations = () => ({
  [formFields.DSO_REGISTRATION_COMPLETED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.dsoRegistrationCompleted.title1),
    description1: I18n.t(
      T.setupTool.statusManagement.tooltip.dsoRegistrationCompleted.description1
    ),
    title2: I18n.t(T.setupTool.statusManagement.tooltip.dsoRegistrationCompleted.title2),
    description2: I18n.t(
      T.setupTool.statusManagement.tooltip.dsoRegistrationCompleted.description2
    ),
  },
  [formFields.DSO_COMMISSIONING_COMPLETED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.dsoCommissioningCompleted.title1),
    description1: I18n.t(
      T.setupTool.statusManagement.tooltip.dsoCommissioningCompleted.description1
    ),
    title2: I18n.t(T.setupTool.statusManagement.tooltip.dsoCommissioningCompleted.title2),
    description2: I18n.t(
      T.setupTool.statusManagement.tooltip.dsoCommissioningCompleted.description2
    ),
  },
  [formFields.BNETZA_REGISTRATION_COMPLETED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.bNetzARegistrationCompleted.title1),
    description1: (
      <Translate
        value={T.setupTool.statusManagement.tooltip.bNetzARegistrationCompleted.description1}
        dangerousHTML={true}
      />
    ),
    title2: I18n.t(T.setupTool.statusManagement.tooltip.bNetzARegistrationCompleted.title2),
    description2: I18n.t(
      T.setupTool.statusManagement.tooltip.bNetzARegistrationCompleted.description2
    ),
  },
  [formFields.TAX_OFFICE_REGISTRATION_COMPLETED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.taxOfficeRegistrationCompleted.title1),
    description1: I18n.t(
      T.setupTool.statusManagement.tooltip.taxOfficeRegistrationCompleted.description1
    ),
  },
  [formFields.HW_COMMISSIONING_COMPLETED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.hardwareCommissioningCompleted.title1),
    description1: I18n.t(
      T.setupTool.statusManagement.tooltip.hardwareCommissioningCompleted.description1
    ),
  },
  [formFields.DSO_METER_INSTALLED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.dsoMeterInstalled.title1),
    description1: I18n.t(T.setupTool.statusManagement.tooltip.dsoMeterInstalled.description1),
  },
  [formFields.FLAT_OFFER_SENT]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.flatOfferSent.title1),
    description1: I18n.t(T.setupTool.statusManagement.tooltip.flatOfferSent.description1),
  },
  [formFields.FLAT_OFFER_ACCEPTED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.flatOfferAccepted.title1),
    description1: I18n.t(T.setupTool.statusManagement.tooltip.flatOfferAccepted.description1),
  },
  [formFields.FLAT_ORDER_CONFIRMED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.flatOrderConfirmed.title1),
    description1: I18n.t(T.setupTool.statusManagement.tooltip.flatOrderConfirmed.description1),
  },
  [formFields.START_OF_DELIVERY_FIXED]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.startOfDeliveryFixed.title1),
    description1: I18n.t(T.setupTool.statusManagement.tooltip.startOfDeliveryFixed.description1),
  },
  [formFields.VPP_PREPARATION_DONE]: {
    title1: I18n.t(T.setupTool.statusManagement.tooltip.vppPreparationDone.title1),
    description1: I18n.t(T.setupTool.statusManagement.tooltip.vppPreparationDone.description1),
  },
});
