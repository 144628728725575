import { Omit } from 'react-redux';

import { AnyData } from '@coolio/json-api';
import { isEmpty } from 'lodash';
import { defer, from } from 'rxjs';

import { ImpactAnalysisStatus } from '+app/+lead/+impactAnalysis/store/+impactAnalysis.interface';
import { LeadListRouteQueryParams } from '+app/router';
import { computeOffset, xorDecrypt } from '+app/utils';
import { Config } from '+config/config';
import { ConfigurationForm } from '+lead-configuration/store/types';

import { httpClient, jsonApiClient } from '../../network/network.client';
import { ContactData } from '../contact/types';
import {
  mapAutarkyToDto,
  mapConfigurationToDto,
  mapConfigurationToRecommendationDto,
  mapLeadAttributesToDto,
} from './lead.helpers';
import {
  EnergyProvidersData,
  FlatDocumentType,
  LeadAddress,
  LeadAddressValidationResponse,
  LeadConfigurationAttributes,
  LeadConfigurationResponseData,
  LeadConfigurationStatus,
  LeadCreateDto,
  LeadData,
  LeadImpactAnalysisAttributes,
  LeadImpactAnalysisResponseData,
  LeadMetaData,
  LeadPartnerNotesData,
  LeadPatchDto,
  LeadStatusName,
  UpdateStatusDto,
} from './types';
import {
  LeadAddressDetailsData,
  LeadAutosuggestionData,
} from './types/leadAutosuggestion.interface';
import { LeadDeliveryAddressSuggestionsData } from './types/leadDeliveryAddress.interface';
import { LeadImpactAnalysisRecommendationResponseData } from './types/leadImpactAnalysisRecommendationResponse.interface';
import {
  LeadOfferData,
  LeadOfferTaxRegulation,
  OfferProductStatus,
} from './types/leadOffer.interface';
import { LeadProductAvailabilityData } from './types/leadProductAvailability.interface';
import { LeadProductBatteryData } from './types/leadProductBattery.interface';
import { UtilityDataData } from './types/leadUtilityData.interface';

const BASE_URL = `${Config.SALES_API_URL}/leads`;
const ENERGY_PROVIDERS_URL = `${Config.SALES_API_URL}/energy-providers`;
const SETUP_BASE_URL = `${Config.DSO_REGISTRATION_API_URL}/leads/v1`;

// leads
const getLead = (leadId: string) =>
  from(jsonApiClient.get<LeadData, LeadMetaData>(`${BASE_URL}/${leadId}`).expectOne().send());

const getLeadList = (
  { search, page }: LeadListRouteQueryParams = {},
  userProfileCustomerNumber: string,
  statusList?: LeadStatusName[]
) => {
  const leadList = jsonApiClient.get<LeadData>(BASE_URL);

  if (statusList) {
    statusList.forEach((status: string, index: number) =>
      leadList.filter(['status', `${index}`], status)
    );
  }

  return from(
    leadList
      .filter('stage', 'open')
      .filter('query', search && xorDecrypt(search, userProfileCustomerNumber))
      .pageOffset(computeOffset(page))
      .expectMany()
      .send()
  );
};

const getInboxLeadList = ({ page }: LeadListRouteQueryParams = {}) =>
  from(
    jsonApiClient
      .get<LeadData>(BASE_URL)
      .pageOffset(computeOffset(page))
      .filter('stage', 'inbox')
      .expectMany()
      .send()
  );

const getSetupLeadList = (
  { search, page }: LeadListRouteQueryParams = {},
  userProfileCustomerNumber: string,
  statusList?: LeadStatusName[]
) => {
  const setupLeadList = jsonApiClient.get<LeadData>(SETUP_BASE_URL);

  if (statusList) {
    statusList.forEach((status: string, index: number) =>
      setupLeadList.filter(['status', `${index}`], status)
    );
  }

  return from(
    setupLeadList
      .filter('stage', 'inSetup')
      .filter('query', search && xorDecrypt(search, userProfileCustomerNumber))
      .pageOffset(computeOffset(page))
      .expectMany()
      .send()
  );
};

const postLead = (leadAttributes: LeadCreateDto) =>
  from(
    jsonApiClient
      .post<LeadData>(`${BASE_URL}`)
      .ofType('leads')
      .withAttributes(mapLeadAttributesToDto(leadAttributes))
      .expectOne()
      .send()
  );

const patchLead = (leadAttributes: Partial<LeadPatchDto>, leadId: string) =>
  from(
    jsonApiClient
      .patch<LeadData>(`${BASE_URL}/${leadId}`)
      .ofType('leads')
      .withAttributes(leadAttributes)
      .expectOne()
      .send()
  );

const patchLeadAsSeen = (leadId: string) =>
  from(
    jsonApiClient
      .patch<LeadData>(`${BASE_URL}/${leadId}`)
      .ofType('leads')
      .withAttributes({
        is_newly_assigned: false,
      })
      .expectOne()
      .send()
  );

const postLeadStatus = (statusAttributes: UpdateStatusDto, leadId: string) =>
  from(
    jsonApiClient
      .post(`${BASE_URL}/${leadId}/status`)
      .ofType('status')
      .withAttributes(statusAttributes) // @TODO: Map Date to ISO string
      .expectOne()
      .send()
  );

// configurations
const getLeadConfiguration = (leadId: string, configurationId: string) =>
  from(
    jsonApiClient
      .get<LeadConfigurationResponseData>(`${BASE_URL}/${leadId}/configurations/${configurationId}`)
      .expectOne()
      .send()
  );

const postLeadConfiguration = (
  leadId: string,
  configurationAttributes: LeadConfigurationAttributes
) =>
  from(
    jsonApiClient
      .post<LeadConfigurationResponseData>(`${BASE_URL}/${leadId}/configurations`)
      .ofType('configurations')
      .withAttributes(mapConfigurationToDto(configurationAttributes))
      .expectOne()
      .send()
  );

const deleteLeadConfiguration = (leadId: string, configurationId: string) =>
  from(
    jsonApiClient
      .delete(`${BASE_URL}/${leadId}/configurations/${configurationId}`)
      .expectOne()
      .send()
  );

const deleteProductFromExpiredOffer = (leadId: string, offerId: string, productId: string) =>
  defer(() =>
    jsonApiClient
      .delete(`${BASE_URL}/${leadId}/offers/${offerId}/products/${productId}`)
      .expectOne()
      .send()
  );

const patchConfigurationStatus = (
  leadId: string,
  configurationId: string,
  status: LeadConfigurationStatus
) =>
  from(
    jsonApiClient
      .patch(`${Config.SALES_API_URL}/leads/${leadId}/configurations/${configurationId}`)
      .ofType('configurations')
      .withAttributes({ status })
      .expectOne()
      .send()
  );

const patchRecalculateConfiguration = (
  leadId: string,
  configurationId: string,
  semiIndirect: boolean,
  generationPlants: boolean,
  productionMeteringMandatory?: boolean
) =>
  from(
    jsonApiClient
      .patch(`${Config.SALES_API_URL}/leads/${leadId}/configurations/${configurationId}`)
      .ofType('configurations')
      .withAttributes({
        power_plant: {
          transformer_metering_mandatory: semiIndirect,
          non_photovoltaic_energy_producing_systems_installed: generationPlants,
          production_metering_mandatory: productionMeteringMandatory,
        },
      })
      .expectOne()
      .send()
  );

const postLeadConfigurationRecommendation = (
  leadId: string,
  configurationForm: ConfigurationForm
) =>
  from(
    jsonApiClient
      .post<AnyData, LeadConfigurationResponseData>(
        `${BASE_URL}/${leadId}/configurations/recommendation`
      )
      .ofType('recommendation')
      .withAttributes(mapConfigurationToRecommendationDto(configurationForm))
      .expectOne()
      .send()
  );

const updateAutarky = (leadId: string, configurationId: string, autarky: number) =>
  from(
    jsonApiClient
      .patch(`${Config.SALES_API_URL}/leads/${leadId}/configurations/${configurationId}`)
      .ofType('configurations')
      .withAttributes(mapAutarkyToDto(autarky))
      .expectOne()
      .send()
  );

// offers
const getLeadOffer = (leadId: string, offerId: string) =>
  from(
    jsonApiClient
      .get<LeadOfferData>(`${Config.SALES_API_URL}/leads/${leadId}/offers/${offerId}`)
      .expectOne()
      .resolveIncluded()
      .send()
  );

const getLeadOfferList = (leadId: string) =>
  from(
    jsonApiClient
      .get<LeadOfferData>(`${Config.SALES_API_URL}/leads/${leadId}/offers`)
      .pageLimit(0)
      .expectMany()
      .resolveIncluded()
      .send()
  );

const patchForRecalculateExpiredOffer = (offerId: string, configurationId: string) =>
  defer(() =>
    jsonApiClient
      .patch<LeadConfigurationResponseData>(
        `${Config.SALES_API_URL}/leads/${offerId}/configurations/${configurationId}`
      )
      .ofType('configurations')
      .withAttributes({
        full_recalculation: true,
      })
      .expectOne()
      .send()
  );

// `Flat + hardware` Or `Flat Only` offer
const postLeadOffer = (
  leadId: string,
  configurationId: string,
  batteryId?: string,
  taxRegulation?: LeadOfferTaxRegulation
) => {
  const request = jsonApiClient
    .post<LeadOfferData>(`${Config.SALES_API_URL}/leads/${leadId}/offers`)
    .withAttributes({
      tax_regulation: taxRegulation,
    })
    .ofType('offers');

  return batteryId
    ? defer(() =>
        request
          .withRelationship({
            uuid: configurationId,
            name: 'configuration',
            type: 'configurations',
          })
          .withRelationship({
            uuid: batteryId,
            name: 'battery',
            type: 'batteries',
          })
          .expectOne()
          .send()
      )
    : defer(() =>
        request
          .withRelationship({
            uuid: configurationId,
            name: 'configuration',
            type: 'configurations',
          })
          .expectOne()
          .send()
      );
};

// `Hardware Only` offer
const postLeadHardwareOffer = (leadId: string, batteryId: string) =>
  from(
    jsonApiClient
      .post<LeadOfferData>(`${Config.SALES_API_URL}/leads/${leadId}/offers`)
      .ofType('offers')
      .withRelationship({
        uuid: batteryId,
        name: 'battery',
        type: 'batteries',
      })
      .expectOne()
      .send()
  );

// adding flat tariff to an existing offer with HW only
const patchLeadOfferToAddTariff = (
  leadId: string,
  configurationId: string,
  offerId: string,
  taxRegulation: LeadOfferTaxRegulation
) =>
  defer(() =>
    jsonApiClient
      .post<LeadOfferData>(`${Config.SALES_API_URL}/leads/${leadId}/offers/${offerId}/products`)
      .ofType('products')
      .withId(configurationId)
      .withAttributes({
        tax_regulation: taxRegulation,
      })
      .expectOne()
      .send()
  );

const patchLeadOfferProduct = (
  leadId: string,
  offerId: string,
  productId: string,
  status: OfferProductStatus
) =>
  from(
    jsonApiClient
      .patch<LeadOfferData>(
        `${Config.SALES_API_URL}/leads/${leadId}/offers/${offerId}/products/${productId}`
      )
      .ofType('products')
      .withAttributes({ status })
      .expectOne()
      .send()
  );

const deleteLeadOffer = (leadId: string, offerId: string) =>
  from(jsonApiClient.delete(`${BASE_URL}/${leadId}/offers/${offerId}`).expectOne().send());

const getLeadOfferDocumentFile = (
  leadId: string,
  offerId: string,
  documentType: FlatDocumentType,
  documentId: string
) =>
  from(
    httpClient
      .get<string>(
        `${BASE_URL}/${leadId}/offers/${offerId}/documents/${documentType}/${documentId}/file`,
        {}
      )
      .then((body) => body.parsedBody())
  );

const postLeadOfferDocument = (leadId: string, offerId: string, documentType: FlatDocumentType) =>
  from(
    jsonApiClient
      .post(`${BASE_URL}/${leadId}/offers/${offerId}/documents/${documentType}`)
      .ofType('documents')
      .expectOne()
      .send()
  );

const getLeadImpactAnalysis = (leadId: string, impactAnalysisId: string) =>
  from(
    jsonApiClient
      .get<LeadImpactAnalysisResponseData>(
        `${Config.SALES_API_URL}/leads/${leadId}/impact-analysis/${impactAnalysisId}`
      )
      .expectOne()
      .send()
  );

const postLeadImpactAnalysis = (
  leadId: string,
  offerId: string,
  impactAnalysisAttributes: LeadImpactAnalysisAttributes
) =>
  from(
    jsonApiClient
      .post<LeadImpactAnalysisResponseData>(`${BASE_URL}/${leadId}/impact-analysis`)
      .ofType('impact-analysis')
      .withAttributes(impactAnalysisAttributes)
      .withRelationship({
        uuid: offerId,
        name: 'offer',
        type: 'offers',
      })
      .expectOne()
      .send()
  );

const postLeadImpactAnalysisRecommendation = (
  leadId: string,
  offerId: string,
  impactAnalysisAttributes: Omit<LeadImpactAnalysisAttributes, 'selectedPages'>
) =>
  from(
    jsonApiClient
      .post<LeadImpactAnalysisRecommendationResponseData>(
        `${BASE_URL}/${leadId}/impact-analysis/recommendation`
      )
      .ofType('recommendation')
      .withAttributes(impactAnalysisAttributes)
      .withRelationship({
        uuid: offerId,
        name: 'offer',
        type: 'offers',
      })
      .expectOne()
      .send()
  );

const postLeadImpactAnalysisDocument = (leadId: string, impactAnalysisId: string) =>
  from(
    jsonApiClient
      .post(`${BASE_URL}/${leadId}/impact-analysis/${impactAnalysisId}/documents`)
      .ofType('documents')
      .expectOne()
      .send()
  );

const patchLeadImpactAnalysis = (leadId: string, offerId: string, impactAnalysisId: string) =>
  from(
    jsonApiClient
      .patch<LeadImpactAnalysisResponseData>(
        `${Config.SALES_API_URL}/leads/${leadId}/impact-analysis/${impactAnalysisId}`
      )
      .ofType('impact-analysis')
      .withAttributes({ status: ImpactAnalysisStatus.SENT })
      .withRelationship({
        uuid: offerId,
        name: 'offer',
        type: 'offers',
      })
      .expectOne()
      .send()
  );

const getLeadImpactAnalysisList = (leadId: string) =>
  from(
    jsonApiClient
      .get(`${Config.SALES_API_URL}/leads/${leadId}/impact-analysis`)
      .pageLimit(0)
      .expectMany()
      .send()
  );

const deleteLeadImpactAnalysis = (leadId: string, impactAnalysisId: string) =>
  from(
    jsonApiClient
      .delete(`${BASE_URL}/${leadId}/impact-analysis/${impactAnalysisId}`)
      .expectOne()
      .send()
  );

const getLeadImpactAnalysisDocumentFile = (
  leadId: string,
  impactAnalysisId: string,
  documentId: string
) =>
  from(
    httpClient
      .get<string>(
        `${BASE_URL}/${leadId}/impact-analysis/${impactAnalysisId}/documents/${documentId}/file`,
        {}
      )
      .then((body) => body.parsedBody())
  );

const getPartnerEmployees = () =>
  from(
    jsonApiClient
      .get<ContactData>(`${Config.API_URL}/contacts?include=roles&page[size]=1000`)
      .filter('roles.name', 'sales_agent,company_coordinator')
      .expectMany()
      .resolveIncluded()
      .send()
  );

const patchAssignLeadsPartner = (leadId: string, employeeId: string) =>
  from(
    jsonApiClient
      .patch<LeadData>(`${BASE_URL}/${leadId}/partner`)
      .ofType('partner')
      .withAttributes({
        status: 'accepted',
        employee: {
          id: employeeId,
        },
      })
      .expectOne()
      .send()
  );

const patchReassignLeadsPartner = (leadId: string, employeeId: string) =>
  from(
    jsonApiClient
      .patch<LeadData>(`${BASE_URL}/${leadId}/partner`)
      .ofType('partner')
      .withAttributes({
        employee: {
          id: employeeId,
        },
      })
      .expectOne()
      .send()
  );

const deleteDeclineLead = (leadId: string, reason: string) =>
  from(
    jsonApiClient
      .delete<LeadData>(`${BASE_URL}/${leadId}/partner`)
      .expectOne()
      .send({
        headers: {
          'x-decline-reason': reason,
        },
      })
  );

const postPartnerNote = (leadId: string, partnerNotes: string) =>
  from(
    jsonApiClient
      .post<LeadPartnerNotesData>(`${BASE_URL}/${leadId}/notes`)
      .ofType('notes')
      .withAttributes({
        content: partnerNotes,
      })
      .expectOne()
      .send()
  );

const getPartnerNote = (leadId: string, noteId: string) =>
  from(
    jsonApiClient
      .get<LeadPartnerNotesData>(`${BASE_URL}/${leadId}/notes/${noteId}`)
      .expectOne()
      .send()
  );

const getEnergyProviders = (search: string) =>
  from(
    jsonApiClient
      .get<EnergyProvidersData>(ENERGY_PROVIDERS_URL)
      .filter('name', search)
      .expectMany()
      .send()
  );

const getUtilityData = (leadId: string) =>
  from(
    jsonApiClient.get<UtilityDataData>(`${BASE_URL}/${leadId}/utility-change`).expectOne().send()
  );

const patchUtilityChange = ({
  oldProvider,
  meterId,
  leadId,
}: {
  oldProvider: string;
  meterId: string;
  leadId: string;
}) => {
  const req = jsonApiClient
    .patch<LeadData>(`${BASE_URL}/${leadId}/utility-change`)
    .ofType('utility-change');

  if (!isEmpty(meterId)) {
    req.withAttributes({
      meter_id: meterId,
    });
  }

  return isEmpty(oldProvider)
    ? from(req.expectOne().send())
    : from(
        req
          .withRelationship({
            type: 'energy-providers',
            name: 'energy_provider',
            uuid: oldProvider,
          })
          .expectOne()
          .send()
      );
};

const getAddressAutosuggestions = (query: string, country: string, sessionId: string) =>
  from(
    jsonApiClient
      .get<LeadAutosuggestionData>(`${Config.SALES_API_URL}/addresses/autocomplete`)
      .parameter('query', query)
      .parameter('country', country)
      .parameter('language', country)
      .parameter('sessionId', sessionId)
      .pageLimit(0)
      .expectMany()
      .send()
  );

const getAddressDetails = (placeId: string, country: string, sessionId: string, query: string) =>
  from(
    jsonApiClient
      .get<LeadAddressDetailsData>(`${Config.SALES_API_URL}/addresses/details/${placeId}`)
      .parameter('language', country)
      .parameter('country', country)
      .parameter('sessionId', sessionId)
      .parameter('query', query)
      .expectOne()
      .send()
  );

const getDeliveryAddress = (postcode: string, city?: string, street?: string) =>
  defer(() =>
    jsonApiClient
      .get<LeadDeliveryAddressSuggestionsData>(`${Config.SALES_API_URL}/addresses/delivery-address`)
      .parameter('postcode', postcode)
      .parameter('city', city)
      .parameter('street', street)
      .expectOne()
      .send()
  );

const getAddressValidation = (country: string, city: string, postalCode: string, street: string) =>
  from(
    jsonApiClient
      .get<any, LeadAddressValidationResponse>(`${Config.SALES_API_URL}/addresses/validation/`)
      .parameter('country', country)
      .parameter('city', city)
      .parameter('postal_code', postalCode)
      .parameter('street', street)
      .expectOne()
      .send()
  );

const getProductAvailability = (leadId: string, dsoId?: string, tsoName?: string) => {
  return from(
    jsonApiClient
      .get<LeadProductAvailabilityData>(
        `${Config.SALES_API_URL}/leads/${leadId}/product-availability`
      )
      .parameter('tso[name]', tsoName)
      .parameter('id', dsoId)
      .expectMany()
      .send()
  );
};

const getProductAvailabilityForAddress = (address: LeadAddress) => {
  return from(
    jsonApiClient
      .get<LeadProductAvailabilityData>(`${Config.SALES_API_URL}/product-availability`)
      .parameter('street', address.street)
      .parameter('zipCode', address.zipCode)
      .parameter('city', address.city)
      .parameter('country', address.country)
      .expectMany()
      .send()
  );
};

const getBatteryList = () => {
  return from(
    jsonApiClient
      .get<LeadProductBatteryData>(`${Config.SALES_API_URL}/products/batteries`)
      .expectMany()
      .resolveIncluded()
      .send()
  );
};

export const LeadRepository = {
  // leads
  getLead,
  getLeadList,
  getSetupLeadList,
  getInboxLeadList,
  postLead,
  patchLead,
  patchLeadAsSeen,
  postLeadStatus,
  deleteProductFromExpiredOffer,

  // configurations
  getLeadConfiguration,
  postLeadConfiguration,
  postLeadConfigurationRecommendation,
  deleteLeadConfiguration,
  patchRecalculateConfiguration,
  patchConfigurationStatus,
  updateAutarky,

  // offers
  getLeadOffer,
  getLeadOfferList,
  postLeadOffer,
  postLeadHardwareOffer,
  deleteLeadOffer,
  patchForRecalculateExpiredOffer,

  // offer products
  patchLeadOfferToAddTariff,
  patchLeadOfferProduct,

  // offer documents
  getLeadOfferDocumentFile,
  postLeadOfferDocument,

  // impactAnalysis
  postLeadImpactAnalysis,
  postLeadImpactAnalysisRecommendation,
  postLeadImpactAnalysisDocument,
  patchLeadImpactAnalysis,
  deleteLeadImpactAnalysis,
  getLeadImpactAnalysis,
  getLeadImpactAnalysisList,
  getLeadImpactAnalysisDocumentFile,

  // partner
  getPartnerEmployees,
  patchAssignLeadsPartner,
  patchReassignLeadsPartner,
  deleteDeclineLead,

  // notes
  postPartnerNote,
  getPartnerNote,

  // utility data
  getEnergyProviders,
  getUtilityData,
  patchUtilityChange,

  getAddressValidation,

  // autosuggestion
  getAddressAutosuggestions,
  // delivery address (Valid addresses by MyContract)
  getDeliveryAddress,
  getAddressDetails,

  // product availability
  getProductAvailability,
  getProductAvailabilityForAddress,

  // product batteries
  getBatteryList,
};
