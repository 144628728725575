import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';

export interface CarouselSlide {
  icon: IconType;
  name: string;
  text: string;
}

export const getSlides = (): CarouselSlide[] => [
  {
    icon: 'Euro',
    name: I18n.t(T.drive.benefits.rate.title),
    text: I18n.t(T.drive.benefits.rate.text),
  },
  {
    icon: 'CalendarWithGrid',
    name: I18n.t(T.drive.benefits.duration.title),
    text: I18n.t(T.drive.benefits.duration.text),
  },
  {
    icon: 'PaperProcess',
    name: I18n.t(T.drive.benefits.process.title),
    text: I18n.t(T.drive.benefits.process.text),
  },
];
